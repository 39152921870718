import { Combobox } from '@headlessui/react'
import { useState, useMemo } from 'react'
import { debounce } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import { EstablishmentList } from '../../../services/API/Entities/establishmentList'
import { Search } from 'iconoir-react'

const EstablishmentListDropdown = ({
    onChange,
    label,
    icon,
    placeholder,
    isEstablishment,
}) => {
    const [query, setQuery] = useState('')

    const debouncedQuery = useMemo(
        () =>
            debounce((searchTerm) => {
                setQuery(searchTerm)
            }, 600),
        []
    )

    const { data: options = [], isLoading } = useQuery({
        queryKey: ['establishments', query],
        queryFn: () =>
            EstablishmentList.fetchAllForDebounceSearch(query, isEstablishment),
        keepPreviousData: true,
        retry: 0,
        cacheTime: 0,
        enabled: query.trim() !== '',
    })

    const handleInputChange = (event) => {
        const searchTerm = event.target.value
        debouncedQuery(searchTerm)
    }

    return (
        <div className={'w-full'}>
            <div className="flex items-center space-x-2 pb-2">
                {icon && (
                    <i
                        className={`fa ml-2 ${icon} min-w-icon lg:fa-lg text-base`}
                    />
                )}
                {label && (
                    <label className="block text-lg font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                        {label}
                    </label>
                )}
            </div>
            <Combobox value="" onChange={onChange}>
                <div className="relative w-full">
                    <div className="relative">
                        <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
                        <Combobox.Input
                            onChange={handleInputChange}
                            placeholder={placeholder}
                            className="w-full appearance-none rounded-lg border border-gray-200 bg-white px-10 py-3 text-sm shadow-sm placeholder:text-gray-400 focus:border-secondary-color focus:outline-none focus:ring-2 focus:ring-secondary-color/20"
                        />
                    </div>
                    <Combobox.Options className="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-sm bg-white shadow-lg ring-1 ring-black/5">
                        {isLoading ? (
                            <div className="flex justify-center p-4">
                                <div className="h-6 w-6 animate-spin rounded-full border-2 border-secondary-color border-t-transparent"></div>
                            </div>
                        ) : options.length > 0 ? (
                            options.map((option) => (
                                <Combobox.Option
                                    key={option.value}
                                    value={option}
                                    className={({ active }) =>
                                        `cursor-pointer select-none px-4 py-3 text-sm transition-colors ${
                                            active
                                                ? 'bg-primary-color/10 text-primary-color'
                                                : 'text-gray-700 hover:bg-gray-50'
                                        }`
                                    }
                                >
                                    {option.label}
                                </Combobox.Option>
                            ))
                        ) : (
                            <div className="px-4 py-3 text-sm text-gray-500">
                                Aucun établissement trouvé
                            </div>
                        )}
                    </Combobox.Options>
                </div>
            </Combobox>
        </div>
    )
}

export default EstablishmentListDropdown
