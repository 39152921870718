import { FreeInvoicePaymentMethods } from '../PaymentMethods'
import { formatDateLocal } from '../dateUtils'

export const FREE_INVOICE_ACTIONS = {
    SHOW_ESTABLISHMENT_MODAL: 'SHOW_ESTABLISHMENT_MODAL',
    FETCH_FREE_INVOICE: 'FETCH_FREE_INVOICE',
    SHOW_PATIENT_MODAL: 'SHOW_PATIENT_MODAL',
    SET_PATIENT: 'SET_PATIENT',
    SET_PAID_AT: 'setPaidAt',
    SET_ESTABLISHMENT: 'SET_ESTABLISHMENT',
    SET_DATE: 'setDate',
    SET_TITLE: 'setTitle',
    SET_COMMENTS: 'setComments',
    SET_NOTE: 'setNote',
    SET_SOCIETY_INFO: 'setSocietyInfo',
    SET_ITEMS: 'setItems',
    REMOVE_ITEM: 'removeItem',
    IS_INVOICE: 'isInvoice',
    FETCH_FREE_INVOICE_COPY: 'FETCH_FREE_INVOICE_COPY',
    SET_STATUS: 'setStatus',
    FETCH_QUOTE_TO_INVOICE: 'FETCH_QUOTE_TO_INVOICE',
    SET_PAYMENT_METHOD: 'setPaymentMethod',
    SET_INVOICER: 'setInvoicer',
    SET_PRIVATE_NOTE: 'setPrivateNote',
}

export const FreeInvoiceReducer = (state, action) => {
    switch (action.type) {
        case FREE_INVOICE_ACTIONS.SHOW_ESTABLISHMENT_MODAL:
            return {
                ...state,
                showEstablishmentModal: action.payload,
            }
        case FREE_INVOICE_ACTIONS.SET_PRIVATE_NOTE:
            return {
                ...state,
                privateNote: action.payload,
            }

        case FREE_INVOICE_ACTIONS.SET_PAID_AT:
            const input = action.payload
            const numbersOnly = input.replace(/[^\d]/g, '')
            let formattedDate = numbersOnly

            if (numbersOnly.length > 2) {
                formattedDate = `${numbersOnly.slice(0, 2)}/${numbersOnly.slice(
                    2
                )}`
            }
            if (numbersOnly.length > 4) {
                formattedDate = `${numbersOnly.slice(0, 2)}/${numbersOnly.slice(
                    2,
                    4
                )}/${numbersOnly.slice(4, 8)}`
            }
            return {
                ...state,
                paidAt: formattedDate,
            }
        case FREE_INVOICE_ACTIONS.FETCH_FREE_INVOICE:
            console.log('FETCH_FREE_INVOICE', action.payload)
            return {
                ...state,
                ...action.payload,
                paymentMethod: FreeInvoicePaymentMethods.find(
                    (method) => method.value === action.payload.paymentMethod
                ),
                paidAt: action.payload.paidAt
                    ? formatDateLocal(action.payload.paidAt)
                    : null,
                invoicer: action.payload?.establishment
                    ? 'entreprise'
                    : 'particulier',
            }
        case FREE_INVOICE_ACTIONS.SET_STATUS:
            return {
                ...state,
                status: action.payload,
            }

        case FREE_INVOICE_ACTIONS.FETCH_FREE_INVOICE_COPY:
            const freeInvoiceLines = action.payload.freeInvoiceLines.map(
                (line) => ({
                    ...line,
                    id: null,
                    ['@id']: null,
                    unitPrice: parseFloat(line.unitPrice),
                    quantity: parseFloat(line.quantity),
                    vat: parseFloat(line.vat),
                    discount: parseFloat(line.discount),
                    totalPrice: parseFloat(line.totalPrice),
                })
            )
            return {
                ...state,
                ...action.payload,
                number: null,
                paidAt: null,
                invoicer: action.payload?.establishment
                    ? 'entreprise'
                    : 'particulier',
                paymentMethod: FreeInvoicePaymentMethods.find(
                    (method) => method.value === action.payload.paymentMethod
                ),
                freeInvoiceLines: freeInvoiceLines,
                id: null,
                archived: false,
            }
        case FREE_INVOICE_ACTIONS.SET_SOCIETY_INFO:
            return {
                ...state,
                society: action.payload,
            }
        case FREE_INVOICE_ACTIONS.SHOW_PATIENT_MODAL:
            return {
                ...state,
                showPatientModal: action.payload,
            }
        case FREE_INVOICE_ACTIONS.SET_DATE:
            return {
                ...state,
                date: action.payload,
            }
        case FREE_INVOICE_ACTIONS.SET_PATIENT:
            return {
                ...state,
                patient: action.payload,
                showPatientModal: false,
            }
        case FREE_INVOICE_ACTIONS.SET_ESTABLISHMENT:
            return {
                ...state,
                establishment: action.payload,
                showEstablishmentModal: false,
            }
        case FREE_INVOICE_ACTIONS.SET_TITLE:
            return {
                ...state,
                title: action.payload,
            }
        case FREE_INVOICE_ACTIONS.SET_COMMENTS:
            return {
                ...state,
                comments: action.payload,
            }
        case FREE_INVOICE_ACTIONS.SET_NOTE:
            return {
                ...state,
                note: action.payload,
            }
        case FREE_INVOICE_ACTIONS.SET_ITEMS:
            return {
                ...state,
                freeInvoiceLines: action.payload,
            }
        case FREE_INVOICE_ACTIONS.REMOVE_ITEM:
            return {
                ...state,
                freeInvoiceLines: action.payload,
            }

        case FREE_INVOICE_ACTIONS.SET_INVOICER:
            return {
                ...state,
                invoicer: action.payload,
            }
        case FREE_INVOICE_ACTIONS.SET_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.payload,
            }
        case FREE_INVOICE_ACTIONS.FETCH_QUOTE_TO_INVOICE:
            return {
                ...state,
                ...action.payload,
                id: null,
                status: 'Créée',
                note: null,
                isInvoice: true,
                invoicer: action.payload?.establishment
                    ? 'entreprise'
                    : 'particulier',
                number: null,
                paymentMethod: FreeInvoicePaymentMethods[0],
                freeInvoiceLines: action.payload.freeInvoiceLines.map(
                    (line) => ({
                        ...line,
                        id: null,
                        ['@id']: null,
                        unitPrice: parseFloat(line.unitPrice),
                        quantity: parseFloat(line.quantity),
                        vat: parseFloat(line.vat),
                        discount: parseFloat(line.discount),
                        totalPrice: parseFloat(line.totalPrice),
                    })
                ),
            }

        default:
            return state
    }
}

export const getFreeInvoiceInitialState = ({ isQuote }) => {
    return {
        showEstablishmentModal: false,
        showPatientModal: false,
        patient: null,
        invoicer: 'particulier',
        establishment: null,
        date: new Date(),
        society: null,
        paymentMethod: FreeInvoicePaymentMethods[0],
        status: 'Créée',
        title: null,
        comments: null,
        note: null,
        paidAt: null,
        privateNote: null,
        isInvoice: !isQuote,
        freeInvoiceLines: [
            {
                quantity: 1,
                label: '',
                unitPrice: 0,
                vat: 10,
                discount: 0,
                totalPrice: 0,
            },
        ],
    }
}
