import React, { useState } from 'react'
import NoData from '../App/NoData'
import { useNavigate, useLocation } from 'react-router-dom'
import Loader from '../Commons/Loaders/Loader'
import { DriverLegendModal } from '../Modal/DriverLegendModal'
import { ChatLines, Golf, Play } from 'iconoir-react'
import PrimaryButton from '../Commons/Buttons/PrimaryButton'
import { toast } from 'react-toastify'
import { Driver } from '../../services/API/Entities/driver'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import ActionModalWithLoader from '../Modal/ActionModalWithLoader'
import dayjs from 'dayjs'

const ScheduleRunsList = ({
    onlyUserRuns,
    showUnassignedRun,
    rows,
    userColor,
    loading,
    uniqueMasterRuns,
    isHidedPlanning,
}) => {
    const queryClient = useQueryClient()
    const location = useLocation()
    let history = useNavigate()
    const [showLegendModal, setShowLegendModal] = useState(false)
    const [showStartJourneyActionModal, setShowStartJourneyActionModal] =
        useState(false)
    const [showEndJourneyActionModal, setShowEndJourneyActionModal] =
        useState(false)

    const masterRunId = onlyUserRuns ? rows[0]?.masterRunId : null

    const {
        data: masterRun,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['driverSchedule', masterRunId],
        queryFn: () => Driver.fetchMasterRun(masterRunId),
        enabled: !!masterRunId,
    })

    const historyPlanningPush = (id) => {
        history(location.pathname + '/run/' + id)
    }

    const isToday = (date) => {
        const today = new Date()
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        )
    }

    const startDayMutation = useMutation({
        mutationFn: () => Driver.startDay(masterRunId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['driverSchedule'] })
            toast.success('Journée commencée')
            setShowStartJourneyActionModal(false)
        },
        onError: () => {
            toast.error('Erreur lors du démarrage de la journée')
            setShowStartJourneyActionModal(false)
        },
    })

    const endDayMutation = useMutation({
        mutationFn: () => Driver.endDay(masterRunId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['driverSchedule'] })
            toast.success('Journée terminée')
            setShowEndJourneyActionModal(false)
        },
        onError: () => {
            toast.error('Erreur lors de la fin de la journée')
            setShowEndJourneyActionModal(false)
        },
    })

    const formatDateTime = (dateString) => dayjs(dateString).format('HH:mm')

    return (
        <div className="flex flex-col">
            {loading || isLoading ? (
                <Loader />
            ) : (
                <div className="p-2 pb-10 text-xl text-gray-600">
                    <div className="flex w-full items-center justify-between px-2 text-lg font-semibold">
                        <button
                            className="flex items-center"
                            onClick={() => setShowLegendModal(true)}
                        >
                            <p className="pr-2 font-bold underline underline-offset-4">
                                Légende
                            </p>
                            <i className="far fa-question-circle fa-lg cursor-pointer pr-2" />
                        </button>
                        <h3>
                            Validé(s) :
                            {` ${
                                rows.filter(
                                    (r) => r.status && r.status.id === 'done'
                                ).length
                            } / ${rows.length}`}
                        </h3>
                    </div>

                    {isHidedPlanning() ? (
                        <NoData message="Vous n'avez pas encore accès à ce planning" />
                    ) : rows.length > 0 ? (
                        <div className="flex w-full flex-col py-4">
                            <div
                                className={`flex w-full ${
                                    !masterRun?.startAt ? 'justify-center' : ''
                                } `}
                            >
                                {isToday(new Date()) &&
                                !masterRun?.startAt &&
                                !masterRun?.finishAt &&
                                onlyUserRuns ? (
                                    <PrimaryButton
                                        fullWidth={true}
                                        label="Commencer ma journée"
                                        action={() =>
                                            setShowStartJourneyActionModal(true)
                                        }
                                    >
                                        Commencer ma journée
                                    </PrimaryButton>
                                ) : null}
                                {masterRun?.startAt &&
                                !masterRun?.finishAt &&
                                onlyUserRuns ? (
                                    <div className="flex w-full justify-between">
                                        <p className="flex items-center font-semibold">
                                            <Play className="mr-2" />
                                            {formatDateTime(masterRun.startAt)}
                                        </p>
                                        <PrimaryButton
                                            label="Finir ma journée"
                                            action={() =>
                                                setShowEndJourneyActionModal(
                                                    true
                                                )
                                            }
                                        ></PrimaryButton>
                                    </div>
                                ) : null}
                                {masterRun?.finishAt && onlyUserRuns ? (
                                    <div className="flex w-full justify-between">
                                        <p className="flex items-center font-semibold">
                                            <Play className="mr-2" />{' '}
                                            {formatDateTime(masterRun.startAt)}
                                        </p>
                                        <p className="flex items-center  font-semibold">
                                            <Golf className={'mr-2'} />
                                            {formatDateTime(masterRun.finishAt)}
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                            {rows.map((row) => (
                                <div
                                    key={row.id}
                                    onClick={() =>
                                        !row.isGiven
                                            ? historyPlanningPush(row.id)
                                            : null
                                    }
                                    className={`relative flex h-28 min-h-full w-full cursor-pointer items-center overflow-hidden rounded border-l-8 p-2 shadow-mobile hover:bg-gray-100
                                   ${
                                       row.isUserRun && row.status.id === 'done'
                                           ? 'border-green-500'
                                           : row.isUserRun &&
                                             row.status.id === 'charged'
                                           ? 'border-purple-500'
                                           : row.isUserRun
                                           ? 'border-blue-500'
                                           : 'border-gray-500'
                                   }
                                    ${
                                        !onlyUserRuns &&
                                        (row.isUserRun
                                            ? 'border-green-500'
                                            : row.isNotAssignedRun
                                            ? 'border-gray-500'
                                            : 'border-blue-400')
                                    }
                                    ${
                                        !row.departureOrder
                                            ? 'mt-4'
                                            : row.departureOrder === 1
                                            ? 'mt-4'
                                            : ''
                                    }
                                    ${
                                        row.status.id === 'canceled'
                                            ? 'stripeBg_canceled'
                                            : 'bg-white'
                                    }
                                    ${row.isGiven ? 'stripeBg' : 'bg-white'}
                               `}
                                    style={{
                                        borderColor:
                                            (!onlyUserRuns &&
                                                row.isNotAssignedRun) ||
                                            !row.masterRun
                                                ? 'border-gray-500'
                                                : row.masterRun.vehicle.color,
                                    }}
                                >
                                    <div
                                        className={`flex w-2/12 flex-col items-center space-y-1 font-semibold lg:text-lg ${
                                            row.approximateTime &&
                                            row.isReturnPath &&
                                            'italic'
                                        } `}
                                    >
                                        <span className="px-2 tracking-wide ">
                                            {row.startHour}
                                        </span>
                                        {(!onlyUserRuns &&
                                            row.isNotAssignedRun) ||
                                        !row.masterRun ? (
                                            <span>-</span>
                                        ) : (
                                            <span
                                                className={
                                                    'rounded-full p-1 text-sm uppercase text-white'
                                                }
                                                style={{
                                                    backgroundColor:
                                                        (!onlyUserRuns &&
                                                            row.isNotAssignedRun) ||
                                                        !row.masterRun
                                                            ? 'border-gray-500'
                                                            : row.masterRun
                                                                  .vehicle
                                                                  .color,
                                                }}
                                            >
                                                {row.firstUserInitial}
                                            </span>
                                        )}
                                        <span className="px-2 tracking-wide">
                                            {row.endHour}
                                        </span>
                                    </div>
                                    <div className="flex w-8/12 flex-col space-y-1 pl-2">
                                        <p className="truncate  font-semibold">
                                            {row.partner && !row.isGiven ? (
                                                <i className="fas fa-hands-helping pr-2" />
                                            ) : null}
                                            {row.fullname}
                                        </p>
                                        {row.pickUpLocation.label &&
                                        row.pickUpLocation.label !==
                                            row.pickUpLocation.city ? (
                                            <p className="truncate">
                                                {row.pickUpLocation.label}
                                            </p>
                                        ) : (
                                            <p className="truncate">
                                                {row.pickUpLocation.street
                                                    ? row.pickUpLocation
                                                          .street +
                                                      (row.pickUpLocation
                                                          .secondaryStreet
                                                          ? ', ' +
                                                            row.pickUpLocation
                                                                .secondaryStreet
                                                          : '') +
                                                      ' - ' +
                                                      row.pickUpLocation.city
                                                    : row.pickUpLocation.city}
                                            </p>
                                        )}
                                        {row.depositLocation.label &&
                                        row.depositLocation.label !==
                                            row.depositLocation.city ? (
                                            <p className="truncate">
                                                {row.depositLocation.label}
                                            </p>
                                        ) : (
                                            <p className="truncate">
                                                {row.depositLocation.street
                                                    ? row.depositLocation
                                                          .street +
                                                      (row.depositLocation
                                                          .secondaryStreet
                                                          ? ', ' +
                                                            row.depositLocation
                                                                .secondaryStreet
                                                          : '') +
                                                      ' - ' +
                                                      row.depositLocation.city
                                                    : row.depositLocation.city}
                                            </p>
                                        )}
                                    </div>
                                    <div className="flex w-1/12 items-center justify-center">
                                        {row.departureOrder && (
                                            <span className=" inline-block text-center font-bold text-gray-800 ">
                                                {row.departureOrder}
                                            </span>
                                        )}
                                    </div>
                                    <div className="flex w-1/12 items-center justify-center">
                                        {row.status &&
                                            row.status.id === 'done' && (
                                                <span className="inline-block h-6 w-6 rounded bg-green-500 text-center font-bold text-white">
                                                    <i className="fa fa-check text-white " />
                                                </span>
                                            )}
                                    </div>
                                    <span className="absolute right-0 top-0 flex flex-col space-y-2 p-2">
                                        {row.approximateTime &&
                                            row.isReturnPath && (
                                                <i className="far fa-question-circle pr-2" />
                                            )}
                                        {row.isReturnPath && (
                                            <i className="far fa-registered" />
                                        )}
                                        {row.comments && (
                                            <ChatLines className="text-sm" />
                                        )}
                                    </span>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <NoData message="Aucune course disponible" />
                    )}
                </div>
            )}

            {/* Modals */}
            {showLegendModal && (
                <DriverLegendModal
                    setShowLegendModal={setShowLegendModal}
                    userColor={userColor}
                    uniqueMasterRuns={uniqueMasterRuns}
                    onlyUserRuns={onlyUserRuns}
                />
            )}

            {showStartJourneyActionModal && (
                <ActionModalWithLoader
                    labelAction="Commencer la journée"
                    label="Êtes-vous sûr de vouloir commencer votre journée ?"
                    action={() => startDayMutation.mutate(masterRunId)}
                    closeModal={() => setShowStartJourneyActionModal(false)}
                    isLoading={startDayMutation.isPending}
                />
            )}

            {showEndJourneyActionModal && (
                <ActionModalWithLoader
                    labelAction="Finir la journée"
                    label="Êtes-vous sûr de vouloir terminer votre journée ?"
                    action={() => endDayMutation.mutate(masterRunId)}
                    closeModal={() => setShowEndJourneyActionModal(false)}
                    isLoading={endDayMutation.isPending}
                />
            )}
        </div>
    )
}

export default ScheduleRunsList
