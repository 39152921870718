import React, { useState } from 'react'
import imageCompression from 'browser-image-compression'
import { Driver } from '../../../services/API/Entities/driver'
import { RUNINFO_ACTIONS } from '../../../services/Reducers/RunInformationReducer'
import { Refresh } from 'iconoir-react'

const UploadDocument = ({ docType, dispatch, runId }) => {
    const [uploading, setUploading] = useState(false)
    const [error, setError] = useState('')

    const handleFileSelect = async (file) => {
        if (!file) return

        if (!file.type.startsWith('image/')) {
            setError('Veuillez sélectionner une image')
            return
        }

        setUploading(true)
        setError('')

        try {
            const compressedFile = await imageCompression(file, {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 1024,
                useWebWorker: true,
                fileType: 'image/webp',
            })

            const data = {
                file: compressedFile,
                docType,
                runId,
            }

            await Driver.uploadToS3(data)

            const actionType =
                docType === 'invoice'
                    ? RUNINFO_ACTIONS.SET_INVOICE_URL
                    : RUNINFO_ACTIONS.SET_PRESCRIPTION_URL

            dispatch({
                type: actionType,
                payload: URL.createObjectURL(compressedFile),
            })
        } catch (error) {
            setError('Échec du téléchargement')
            console.error('Upload error:', error)
        } finally {
            setUploading(false)
        }
    }

    return (
        <div className="relative">
            <button
                onClick={() => {
                    const input = document.createElement('input')
                    input.type = 'file'
                    input.accept = 'image/*'
                    input.onchange = (e) => handleFileSelect(e.target.files[0])
                    input.click()
                }}
                disabled={uploading}
                className="relative flex h-24 w-full flex-col items-center justify-center gap-2 rounded-lg border-2 border-dashed border-teal-500 bg-teal-50/50 transition-all hover:bg-teal-50 active:bg-teal-100 disabled:opacity-50"
            >
                {uploading ? (
                    <>
                        <Refresh className="h-6 w-6 animate-spin text-teal-600" />
                        <span className="text-sm font-medium text-teal-700">
                            Chargement...
                        </span>
                    </>
                ) : (
                    <>
                        <i className="fas fa-cloud-upload-alt text-2xl text-teal-600" />
                        <span className="text-sm font-medium text-teal-700">
                            Importer
                        </span>
                    </>
                )}
            </button>
            {error && (
                <p className="mt-2 text-center text-sm text-red-500">{error}</p>
            )}
        </div>
    )
}

export default UploadDocument
