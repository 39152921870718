import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { formatDateLocal } from '../../../../services/dateUtils'
import { getTotalHT, getTotalTTC, tvaGroups } from './FreeInvoiceWrapper'

export const generateFreeInvoicePDF = (freeInvoiceForm) => {
    // Validation et nettoyage des données
    const safeText = (text) => text?.toString() || ''
    const safeNumber = (num) => Number(num) || 0

    const {
        freeInvoiceLines = [],
        patient,
        establishment,
        society,
        title,
        date,
        comments,
        note,
        isInvoice,
        number,
    } = freeInvoiceForm

    const { VATNumber, IBAN, BIC, accountHolderName, siret } = society || {}
    const paymentMethod = freeInvoiceForm.paymentMethod
        ? freeInvoiceForm.paymentMethod.label || freeInvoiceForm.paymentMethod
        : null

    // Create PDF document
    const doc = new jsPDF()
    const pageWidth = doc.internal.pageSize.width
    const pageHeight = doc.internal.pageSize.height
    const margin = 15

    // État pour suivre la position Y courante
    let currentY = 0
    let currentPage = 1

    // Helper functions
    const addBoldText = (text, x, y, size = 12) => {
        if (!text) return // Skip if text is empty
        doc.setFont(undefined, 'bold')
        doc.setFontSize(size)
        doc.text(safeText(text), x, y)
        doc.setFont(undefined, 'normal')
    }

    const checkAndAddNewPage = (requiredSpace = 20) => {
        if (currentY + requiredSpace > pageHeight - margin) {
            doc.addPage()
            currentPage++
            currentY = margin
            return true
        }
        return false
    }

    const addWrappedText = (text, x, y, maxWidth) => {
        if (!text) return 0
        doc.setFontSize(11)
        const lines = doc.splitTextToSize(safeText(text), maxWidth)
        const totalHeight = lines.length * 7

        if (checkAndAddNewPage(totalHeight)) {
            y = currentY
        }

        doc.text(lines, x, y)
        currentY = y + totalHeight
        return lines.length
    }

    const addPageFooter = (pageNumber, totalPages) => {
        doc.setFontSize(10)
        const footerText = `${isInvoice ? 'Facture' : 'Devis'} n° ${safeText(
            number
        )} - Page ${pageNumber} / ${totalPages}`
        // Positionnez le texte à 10 mm du bas de la page
        doc.text(footerText, margin, pageHeight - 10)
    }

    // Header section
    currentY = margin
    doc.setFillColor(247, 247, 247)
    doc.rect(0, 0, pageWidth, 30, 'F')

    // Document type
    doc.setFontSize(24)
    doc.setTextColor(44, 62, 80)
    addBoldText(isInvoice ? 'FACTURE' : 'DEVIS', margin, 15, 24)

    // Document number and date
    doc.setFontSize(12)
    doc.setTextColor(127, 140, 141)
    const docNumber = safeText(number)
    const docDate = formatDateLocal(date)
    doc.text(`N° ${docNumber}`, margin, 23)
    doc.text(`Date : ${docDate}`, pageWidth - margin - 60, 23)

    // Company information
    currentY = 45
    if (society) {
        doc.setTextColor(44, 62, 80)
        addBoldText(society.label, margin, currentY, 14)

        const companyInfo = [
            safeText(society.address?.street),
            `${safeText(society.address?.zipCode)} ${safeText(
                society.address?.city
            )}`,
            `Tél : ${safeText(society.phoneNumber)}`,
            safeText(society.mail),
        ].filter(Boolean) // Remove empty lines

        doc.setFontSize(11)
        companyInfo.forEach((line) => {
            currentY += 7
            doc.text(line, margin, currentY)
        })
    }

    // Client information
    const clientX = pageWidth / 2 + 10
    currentY = 45
    doc.setFontSize(12)
    addBoldText('ADRESSÉ À', clientX, currentY)
    currentY += 10

    if (establishment) {
        addBoldText(safeText(establishment.label), clientX, currentY)
        if (establishment.label2) {
            addBoldText(safeText(establishment.label2), clientX, currentY + 7)
            currentY += 7
        }
        doc.setFontSize(11)
        const clientInfo = [
            safeText(establishment.address?.street),
            safeText(establishment.address?.secondaryStreet),
            `${safeText(establishment.address?.zipCode)} ${safeText(
                establishment.address?.city
            )}`,
        ].filter(Boolean)

        clientInfo.forEach((line) => {
            currentY += 7
            doc.text(line, clientX, currentY)
        })
        if (establishment.site) {
            currentY += 7
            doc.text(safeText(establishment.site), clientX, currentY)
        }
    } else if (patient) {
        const defaultAddress =
            patient.patientsAddresses?.find((pa) => pa.defaultAddress)
                ?.address || patient.defaultDepositAddress

        const patientName = `${safeText(patient.firstname)} ${safeText(
            patient.lastname
        )}`.trim()
        if (patientName) {
            addBoldText(patientName, clientX, currentY)
        }

        if (defaultAddress) {
            doc.setFontSize(11)
            const clientInfo = [
                safeText(defaultAddress.street),
                `${safeText(defaultAddress.zipCode)} ${safeText(
                    defaultAddress.city
                )}`,
            ].filter(Boolean)

            clientInfo.forEach((line) => {
                currentY += 7
                doc.text(line, clientX, currentY)
            })
        }
    }

    // Title and comments
    currentY = 90
    if (title) {
        currentY +=
            addWrappedText(title, margin, currentY, pageWidth - 2 * margin) * 7
    }

    if (comments) {
        checkAndAddNewPage(20)
        currentY +=
            addWrappedText(comments, margin, currentY, pageWidth - 2 * margin) *
            7
    }

    // Items table
    checkAndAddNewPage(40)
    const tableData = freeInvoiceLines.map((item) => [
        safeNumber(item.quantity),
        safeText(item.label),
        safeNumber(item.vat),
        safeNumber(item.discount),
        `${safeNumber(item.unitPrice).toFixed(2)} €`,
        `${(safeNumber(item.quantity) * safeNumber(item.unitPrice)).toFixed(
            2
        )} €`,
    ])

    doc.autoTable({
        head: [
            [
                'Qté',
                'Désignation',
                'TVA (%)',
                'Remise (%)',
                'Prix unit.',
                'Total HT',
            ],
        ],
        body: tableData,
        startY: currentY,
        theme: 'grid',
        styles: {
            fontSize: 10,
        },
        headStyles: {
            fillColor: [247, 247, 247],
            textColor: [44, 62, 80],
            fontStyle: 'bold',
            fontSize: 8,
            halign: 'center',
        },
        columnStyles: {
            0: { cellWidth: 15, halign: 'center' },
            1: { cellWidth: 85 },
            2: { cellWidth: 25, halign: 'center' },
            3: { cellWidth: 20, halign: 'center' },
            4: { cellWidth: 20, halign: 'right' },
            5: { cellWidth: 20, halign: 'right' },
        },
    })

    // Update currentY after table
    currentY = doc.lastAutoTable.finalY + 10
    // Calculate totals
    const totalHT = getTotalHT(freeInvoiceLines)

    const totalTTC = getTotalTTC(freeInvoiceLines)

    // Totals section
    checkAndAddNewPage(50)
    const summaryX = pageWidth - margin - 40

    doc.setFontSize(11)
    doc.text('Total HT', summaryX, currentY)
    doc.text(`${totalHT.toFixed(2)} €`, summaryX + 45, currentY, {
        align: 'right',
    })
    currentY += 7

    // TVA details
    Object.entries(tvaGroups(freeInvoiceLines))
        .filter(([rate, amount]) => rate > 0) // Skip 0% TVA
        .forEach(([rate, amount]) => {
            checkAndAddNewPage(10)
            doc.text(`TVA ${rate}%`, summaryX, currentY)
            doc.text(`${amount.toFixed(2)} €`, summaryX + 45, currentY, {
                align: 'right',
            })
            currentY += 7
        })

    // Total TTC
    checkAndAddNewPage(15)
    addBoldText('Total TTC', summaryX, currentY)
    doc.setFont(undefined, 'bold')
    doc.text(`${totalTTC.toFixed(2)} €`, summaryX + 45, currentY, {
        align: 'right',
    })
    doc.setFont(undefined, 'normal')
    // // Legal note and conditions
    if (note) {
        doc.setFontSize(10)
        const splitNote = doc.splitTextToSize(note, pageWidth / 2 + 30) // Reduced width
        doc.text(splitNote, 15, currentY - 15) // Position it below the totals
        //   get the size of the text
        const noteSize = doc.getTextDimensions(splitNote, { fontSize: 10 })
        currentY += noteSize.h - 15
    }

    currentY += 10

    if (isInvoice && paymentMethod) {
        if (paymentMethod !== 'Non spécifiée') {
            //   ajout de la méthode de paiement
            checkAndAddNewPage(10)
            const method = safeText(paymentMethod)
            doc.text(`Méthode de paiement : ${method}`, margin, currentY)
        }
    }

    currentY += 10

    // Quote specific information
    if (!isInvoice) {
        checkAndAddNewPage(60)
        const quoteText = [
            'Validité du devis : 3 mois',
            'Conditions de règlement : 40% à la commande, le solde à la livraison',
            'Si ce devis vous convient, veuillez nous le retourner signé précédé de la mention :',
            '« Bon pour accord et exécution de la prestation »',
            '',
            'Date :                                                           Signature :',
        ]

        quoteText.forEach((line) => {
            checkAndAddNewPage(10)
            doc.text(safeText(line), margin, currentY)
            currentY += 7
        })
    }
    // Footer information
    if (isInvoice) {
        checkAndAddNewPage(30)
        doc.setFillColor(247, 247, 247)
        doc.rect(0, currentY, pageWidth, 20, 'F')

        doc.setFontSize(8)
        const footerInfo = [
            accountHolderName && `Titulaire : ${safeText(accountHolderName)}`,
            siret && `SIRET : ${safeText(siret)}`,
            VATNumber && `N° TVA : ${safeText(VATNumber)}`,
            IBAN && `IBAN : ${safeText(IBAN)}`,
            BIC && `BIC : ${safeText(BIC)}`,
        ].filter(Boolean)

        currentY += 3

        // Organize footer in two columns
        const leftCol = footerInfo.slice(0, Math.ceil(footerInfo.length / 2))
        const rightCol = footerInfo.slice(Math.ceil(footerInfo.length / 2))

        leftCol.forEach((line, index) => {
            doc.text(line, margin, currentY + 3 + index * 5)
        })

        rightCol.forEach((line, index) => {
            doc.text(line, margin + 80, currentY + 3 + index * 5)
        })
    }

    // Après avoir généré tout le contenu, ajoutez les en-têtes sur chaque page
    const pageCount = doc.getNumberOfPages()

    for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        addPageFooter(i, pageCount)
    }

    // Save the PDF
    doc.save(`${isInvoice ? 'facture' : 'devis'}_${safeText(number)}.pdf`)
}
