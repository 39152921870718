import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CommentNotification } from './CommentNotification'
import { StatusNotification } from './StatusNotification'
import { MultipleNotification } from './MultipleNotification'
import API from '../../services/api'
import { TransportShareNotification } from './TransportShareNotification'
import { NoemieNotification } from './NoemieNotification'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Eye } from 'iconoir-react'
import { Notifications } from '../../services/API/Entities/notification'
import { RemovedTransportSharedNotifiation } from './RemovedTransportSharedNotifiation'

const NotifDropDown = ({
    notifMenuRef,
    notifications,
    loading,
    setOpenNotifDropDown,
    openNotifDropDown,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [selectedType, setSelectedType] = useState('all')
    const queryClient = useQueryClient()

    const filteredNotifications = useMemo(() => {
        switch (selectedType) {
            case 'noemie':
                return notifications.filter(
                    (notif) => notif.type === 'noemie_return'
                )
            case 'share':
                return notifications.filter((notif) =>
                    notif.type.includes('shared_run')
                )
            default:
                return notifications
        }
    }, [selectedType, notifications])

    const handleClickOnNotification = (notif) => {
        if (!notif.seen) {
            markAsReadMutation.mutate(notif.id, {
                onSuccess: () => navigateToNotificationDestination(notif),
            })
        } else {
            navigateToNotificationDestination(notif)
        }
    }

    const navigateToNotificationDestination = (notif) => {
        if (notif.type === 'noemie_return') {
            navigateToNoemieReturn(notif)
        } else {
            navigateToTransport(notif)
        }
        setOpenNotifDropDown(false)
    }

    const navigateToNoemieReturn = (notif) => {
        const path = `/facturation/noemie/${notif.b2File.id}`
        console.log(location.pathname, path)
        if (location.pathname === path) {
            navigate(0)
        } else {
            navigate(path)
        }
    }

    const markAsReadMutation = useMutation({
        mutationFn: (notifId) => API.Notifications.put(notifId, { seen: true }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notifications'] })
        },
        retry: false,
    })

    const markAllAsReadMutation = useMutation({
        mutationFn: () => Notifications.putAllSeen(),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notifications'] })
        },
        retry: false,
    })

    const navigateToTransport = (notif) => {
        const path = `/transport/${notif.run.id}?tab=Options`
        if (location.pathname === path) {
            navigate(0)
        } else {
            navigate(path)
        }
    }

    const markAllAsRead = async () => {
        await markAllAsReadMutation.mutate()
    }

    const renderNotification = (notif) => {
        switch (notif.type) {
            case 'both_ongoing_and_add_comment':
            case 'both_done_and_add_comment':
                return <MultipleNotification multiNotif={notif} />
            case 'status_ongoing':
            case 'status_done':
                return <StatusNotification statusNotification={notif} />
            case 'add_comment':
            case 'edit_comment':
                return <CommentNotification commentNotification={notif} />
            case 'noemie_return':
                return <NoemieNotification noemieNotification={notif} />
            case 'shared_run':
                return <TransportShareNotification shareNotification={notif} />
            case 'removed_shared_run':
                return (
                    <RemovedTransportSharedNotifiation
                        shareNotification={notif}
                    />
                )
            default:
                return null
        }
    }

    return (
        <div
            className={`modal absolute mx-2 mt-12 flex w-[21rem] flex-col rounded-md border bg-white ${
                openNotifDropDown ? 'animate-slideIn' : 'animate-slideOut'
            }`}
            ref={notifMenuRef}
        >
            <div className="max-height-90 flex flex-col overflow-x-auto px-2 text-gray-700">
                <div className="flex w-full items-center justify-between  px-2 py-2">
                    <p className="text-xl font-semibold">Notifications</p>
                    <div className="flex items-center">
                        <p className="mr-2  border-t-2">
                            {filteredNotifications?.length}
                        </p>
                        <Eye
                            onClick={markAllAsRead}
                            className="cursor-pointer rounded  text-secondary-color transition-colors hover:bg-secondary-color hover:text-white"
                            disabled={markAllAsReadMutation.isPending}
                        />
                    </div>
                </div>
                <div className="flex justify-between px-2 py-2">
                    <button
                        className={`rounded px-2 py-1 transition-colors ${
                            selectedType === 'all'
                                ? 'bg-secondary-color text-white hover:bg-primary-color'
                                : 'bg-gray-200 hover:bg-gray-300'
                        }`}
                        onClick={() => setSelectedType('all')}
                    >
                        Toutes
                    </button>
                    <button
                        className={`rounded px-2 py-1 transition-colors ${
                            selectedType === 'noemie'
                                ? 'bg-secondary-color text-white hover:bg-primary-color'
                                : 'bg-gray-200 hover:bg-gray-300'
                        }`}
                        onClick={() => setSelectedType('noemie')}
                    >
                        Retours NOEMIE
                    </button>
                    <button
                        className={`rounded px-2 py-1 transition-colors ${
                            selectedType === 'share'
                                ? 'bg-secondary-color text-white hover:bg-primary-color'
                                : 'bg-gray-200 hover:bg-gray-300'
                        }`}
                        onClick={() => setSelectedType('share')}
                    >
                        Partages
                    </button>
                </div>
                {!loading && filteredNotifications.length > 0 ? (
                    <div>
                        {filteredNotifications.map((notif, i) => (
                            <button
                                key={i}
                                className={`w-full cursor-pointer rounded-lg px-2 py-2 text-left hover:bg-gray-200 ${
                                    notif.important && !notif.seen
                                        ? 'border-l-4 border-purple-500 bg-purple-50'
                                        : ''
                                }`}
                                onClick={() => handleClickOnNotification(notif)}
                            >
                                <div className={`flex items-center `}>
                                    {renderNotification(notif)}
                                </div>
                            </button>
                        ))}
                    </div>
                ) : loading ? (
                    <div>
                        {Array.from(Array(8), (e, i) => (
                            <div
                                key={i}
                                className="mx-auto w-full max-w-sm rounded-md p-4"
                            >
                                <div className="flex animate-pulse items-center space-x-4">
                                    <div className="h-10 w-10 rounded-full bg-gray-400"></div>
                                    <div className="flex-1 space-y-4 py-1">
                                        <div className="h-2 rounded bg-gray-400"></div>
                                        <div className="space-y-3">
                                            <div className="grid grid-cols-3 gap-4">
                                                <div className="col-span-2 h-2 rounded bg-gray-400"></div>
                                                <div className="col-span-1 h-2 rounded bg-gray-400"></div>
                                            </div>
                                            <div className="h-2 rounded bg-gray-400"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className={'px-2 py-4 text-left text-sm'}>
                        Aucune notification pour le moment
                    </p>
                )}
            </div>
        </div>
    )
}

export default NotifDropDown
