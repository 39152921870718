import { RUNINFO_ACTIONS } from '../../services/Reducers/RunInformationReducer'
import SimpleSelectDropDownList from '../Commons/SimpleSelectDropDownList'
import { Documents } from '../../services/Documents'
import { PaymentMethods } from '../../services/PaymentMethods'
import { NumberInput } from '../Commons/NumberInput'
import TextAreaForms from '../Commons/FormTextArea'
import FormSwitch from '../Commons/Buttons/FormSwitch'
import dayjs from 'dayjs'
import TimeField from 'react-simple-timefield'
import { formatOptions, formatPrice } from '../../tools/Utility'
import classNames from 'classnames'
import React from 'react'
import PrimaryButton from '../Commons/Buttons/PrimaryButton'
import SecondaryButton from '../Commons/Buttons/SecondaryButton'
import { EditPencil } from 'iconoir-react'
import { formatTimeInput } from '../../services/dateUtils'

export const ValidationModal = ({
    runInfo,
    dispatch,
    unlimitedSms,
    handleForm,
    handleChange,
    isEffectiveArrivingTimeBeforeEffectiveDepartureTime,
    allowedToChangeHours,
}) => (
    <div
        className={`modal fixed bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-500 bg-opacity-50 text-gray-700 md:px-6`}
    >
        <div className="shadow-modal invisible-scrollbar min-h-modal-mobile m-2 flex w-full flex-col overflow-y-auto rounded-lg bg-white p-4 ">
            <div className="flex flex-col space-y-10">
                <div className="flex w-full items-center border-b  pb-4">
                    <div className="flex space-x-4 text-center font-semibold text-gray-600 ">
                        Confirmation des informations
                    </div>
                    <svg
                        className="ml-auto h-10 w-10 cursor-pointer fill-current text-gray-600"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18 18"
                        onClick={() =>
                            dispatch({
                                type: RUNINFO_ACTIONS.CLOSE_MODAL,
                                payload: false,
                            })
                        }
                    >
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                    </svg>
                </div>
                {allowedToChangeHours && (
                    <div className="flex w-full">
                        <div className="flex flex-col items-center pr-4">
                            <p className="text-lg font-bold text-gray-600">
                                Départ effectif
                            </p>
                            <TimeField
                                value={formatTimeInput(
                                    runInfo.effectiveDepartureTime
                                )}
                                onChange={(e) =>
                                    dispatch({
                                        type: RUNINFO_ACTIONS.SET_EFFECTIVE_DEPARTURE_TIME,
                                        payload: e.target.value,
                                    })
                                }
                                input={
                                    <input
                                        className={`h-10 w-full border-b-2 text-center leading-tight hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none
                                               `}
                                    />
                                }
                                colon=":"
                            />
                        </div>

                        <div className="ml-auto flex flex-col items-center">
                            <p className="text-lg font-bold text-gray-600">
                                Arrivée effective
                            </p>
                            <TimeField
                                value={formatTimeInput(
                                    runInfo.effectiveArrivingTime
                                )}
                                onChange={(e) =>
                                    dispatch({
                                        type: RUNINFO_ACTIONS.SET_EFFECTIVE_ARRIVING_TIME,
                                        payload: e.target.value,
                                    })
                                }
                                input={
                                    <input
                                        className={classNames(
                                            'h-10 w-full border-b-2 text-center leading-tight',
                                            {
                                                'border-red-500 focus:outline-none ':
                                                    isEffectiveArrivingTimeBeforeEffectiveDepartureTime(),
                                                'hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none':
                                                    !isEffectiveArrivingTimeBeforeEffectiveDepartureTime(),
                                            }
                                        )}
                                    />
                                }
                                colon=":"
                            />
                        </div>
                    </div>
                )}

                <div className="flex flex-col">
                    <p className="pb-1 font-bold text-gray-600">Documents</p>
                    <SimpleSelectDropDownList
                        options={Documents}
                        value={runInfo.documents}
                        handleOptionChange={(e) => {
                            dispatch({
                                type: RUNINFO_ACTIONS.SET_DOCUMENTS,
                                payload: e,
                            })
                        }}
                        isSearchable={false}
                    />
                </div>
                {runInfo.runObject['@id'].includes('Course') && (
                    <div className="flex flex-col ">
                        <p className="pb-1 font-bold text-gray-600">
                            Méthode de paiement
                        </p>
                        <SimpleSelectDropDownList
                            options={PaymentMethods}
                            value={runInfo.paymentMethod}
                            handleOptionChange={(e) => {
                                dispatch({
                                    type: RUNINFO_ACTIONS.SET_PAYMENT_METHOD,
                                    payload: e,
                                })
                            }}
                            isSearchable={false}
                        />
                        <p className="pb-1 pt-6 font-bold text-gray-600">
                            Montant
                        </p>
                        <div className="flex  items-center  pl-4">
                            <NumberInput
                                value={runInfo.amount}
                                placeholder={'00.00'}
                                handleOnChange={(e) => {
                                    dispatch({
                                        type: RUNINFO_ACTIONS.SET_AMOUNT,
                                        payload: formatPrice(
                                            e.target.value,
                                            runInfo.amount
                                        ),
                                    })
                                }}
                            />
                            <span>€</span>
                        </div>
                    </div>
                )}
                {runInfo.signature && (
                    <div className="mt-4">
                        <p className="mb-2 font-bold text-gray-600">
                            Signature du patient
                        </p>
                        <div className="flex flex-col items-center rounded border border-gray-300">
                            <img
                                src={runInfo.signature}
                                alt="Signature du patient"
                                className="h-40 max-w-full "
                            />
                            <p className="mt-2 text-lg text-gray-600">
                                Signé le {runInfo.signatureDate} à{' '}
                                {runInfo.signatureLocation}
                            </p>
                        </div>
                    </div>
                )}
                <SecondaryButton
                    label={`${
                        runInfo.signature ? 'Modifier la ' : 'Apposer'
                    } signature`}
                    action={() =>
                        dispatch({
                            type: RUNINFO_ACTIONS.SHOW_SIGNATURE_FORM,
                            payload: true,
                        })
                    }
                    fullWidth={true}
                    icon={<EditPencil />}
                />
                <div className="flex flex-col">
                    <p className="pb-1 font-bold text-gray-600">Commentaire</p>
                    <TextAreaForms
                        value={runInfo.userComments}
                        s
                        placeholder={'Un autre rendez-vous à noter ?'}
                        onChange={(e) => {
                            dispatch({
                                type: RUNINFO_ACTIONS.SET_USER_COMMENTS,
                                payload: e.target.value,
                            })
                        }}
                    />
                </div>

                {unlimitedSms && runInfo.patient.patientsContacts && (
                    <div className="flex flex-col space-y-10">
                        <div className="flex flex-col justify-center ">
                            <FormSwitch
                                label="Qui contacter ?"
                                className={'text-gray-600'}
                                id="patient"
                                valueLeft={!runInfo.contactAll}
                                valueRight={runInfo.contactAll}
                                leftLabel={'Choisir'}
                                rightLabel={'Tous'}
                                onClickLeft={() =>
                                    dispatch({
                                        type: RUNINFO_ACTIONS.CONTACT_ALL,
                                        payload: !runInfo.contactAll,
                                    })
                                }
                                onClickRight={() =>
                                    dispatch({
                                        type: RUNINFO_ACTIONS.CONTACT_ALL,
                                        payload: !runInfo.contactAll,
                                    })
                                }
                            />
                        </div>
                        {runInfo.patient.patientsContacts.map((contact) => (
                            <div className="flex flex-col space-y-1">
                                <p className="font-bold text-gray-600">
                                    {contact.label}
                                </p>
                                {contact.phoneNumber && (
                                    <div className="flex items-center justify-between">
                                        <a href={`tel:${contact.phoneNumber}`}>
                                            {contact.phoneNumber}
                                        </a>
                                        <i className="fa fa-phone" />
                                    </div>
                                )}
                            </div>
                        ))}
                        {!runInfo.contactAll ? (
                            <div className="flex flex-col">
                                <p className="pb-1 font-bold text-gray-600">
                                    Choisir les contacts
                                </p>
                                <SimpleSelectDropDownList
                                    options={formatOptions(
                                        runInfo.patient.patientsContacts
                                    )}
                                    placeholder="contact"
                                    value={runInfo.personToContact}
                                    handleOptionChange={(e) => {
                                        dispatch({
                                            type: RUNINFO_ACTIONS.SET_PERSON_TO_CONTACT,
                                            payload: e,
                                        })
                                    }}
                                    isMulti={true}
                                />
                            </div>
                        ) : (
                            <div className="flex flex-col space-y-1"></div>
                        )}
                    </div>
                )}
            </div>
            <div className="mt-auto flex flex-col space-y-2 pt-10">
                {unlimitedSms && (
                    <div className="flex w-full flex-col">
                        <button
                            onClick={() => handleForm('done', false)}
                            className="left-0 ml-2 rounded-md border border-dark-green bg-transparent py-4 text-lg  font-bold text-dark-green shadow-md hover:border-transparent hover:bg-light-blue-green hover:text-white "
                        >
                            Confirmer sans notifier
                        </button>
                        <p className="text-center"> Ou</p>
                    </div>
                )}
                <PrimaryButton
                    label="Finaliser le transport"
                    title="Finaliser le transport"
                    action={() => handleForm('done')}
                    mobileSize={true}
                />
            </div>
        </div>
    </div>
)
