import React from 'react'
import dayjs from 'dayjs'
import { formatTimeLocal } from '../../../../../services/dateUtils'
import { isArtisan } from '../../../../../services/fetchRunsInformations'

const InvoiceRuns = ({ invoiceForm, handleOpenRunModal }) => {
    const sortedRuns = [...invoiceForm.runs].sort((a, b) =>
        dayjs(a.departureTime).isAfter(dayjs(b.departureTime)) ? 1 : -1
    )

    return (
        <div className="flex flex-col space-y-4">
            {sortedRuns.map((run) => (
                <div className={'flex flex-col space-y-2 '}>
                    <div
                        onClick={() => handleOpenRunModal(run)}
                        className={
                            'flex w-full cursor-pointer select-none justify-between space-x-4 rounded-lg border border-secondary-color bg-white p-2 shadow-mobile hover:scale-[1.02]'
                        }
                    >
                        <div>{dayjs(run.date).format('DD/MM/YYYY')}</div>
                        <div>
                            {formatTimeLocal(run.departureTime)} -{' '}
                            {formatTimeLocal(run.arrivingTime)}
                        </div>
                        <div>
                            {run.directReturn ? 'Direct ' : null}
                            {run.isReturnPath ? 'Retour' : 'Aller'}{' '}
                        </div>
                        <div>{run.amount}€</div>
                        <div>{run.kilometer} km</div>
                        <div>
                            {run.pickUpLocation.city} -{' '}
                            {run.depositLocation.city}
                        </div>
                    </div>
                    <div
                        className="mb-4 ml-2 flex flex-col  border-l-2 bg-white p-2 "
                        key={run.id}
                    >
                        {!run.masterRun && !isArtisan ? (
                            <p className="font-bold text-red-500">
                                Veuillez affecter une équipe
                            </p>
                        ) : null}
                        {!run.kilometer && (
                            <p className="font-bold text-red-500">
                                Veuillez renseigner un kilométrage
                            </p>
                        )}
                        {run.status.id !== 'done' && (
                            <p className="font-bold text-red-500">
                                Le statut du transport n'est pas marqué comme
                                terminé.
                            </p>
                        )}
                        {!run.pickUpLocation.city && (
                            <p className="font-bold text-red-500">
                                Veuillez renseigner la ville de prise en charge.
                            </p>
                        )}
                        {!run.depositLocation.city && (
                            <p className="font-bold text-red-500">
                                Veuillez renseigner la ville de dépose.
                            </p>
                        )}
                        {new Date() <= new Date(run.arrivingTime) && (
                            <p className="font-bold text-red-500">
                                L'heure d'arrivée ne peut pas être dans le
                                futur.
                            </p>
                        )}
                        {run.runObject.label.startsWith('Course payante') && (
                            <p className="font-bold text-red-500">
                                Le type de course ne peut pas être "Course
                                payante".
                            </p>
                        )}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default InvoiceRuns
