import React from 'react'
import { useQuery } from '@tanstack/react-query'
import ScheduleRunsFilter from './ScheduleRunsFilter'
import ScheduleRunsList from './ScheduleRunsList'
import useUserAccessStore from '../../stores/UserAccess'
import { useStickyDateState } from '../../hooks/LocalStorageDatesHooks'
import * as dayjs from 'dayjs'
import API from '../../services/api'
import { SkeletonLoaderCards } from '../Commons/Loaders/SkeletonLoaderCards'
import { formatDriverRuns } from '../../services/run'
import { Society } from '../../services/API/Entities/society'
import { Driver } from '../../services/API/Entities/driver'

const useUserParameters = () => {
    return useQuery({
        queryKey: [
            'parameters',
            localStorage.getItem('id'),
            localStorage.getItem('society'),
        ],
        queryFn: async () => {
            const userResponse = await API.Users.get(localStorage.getItem('id'))
            const userData = await userResponse.json()
            const parametersData = await Society.fetchParameters()

            const authorizedShowAllRuns = userData.hasScheduleConfiguration
                ? userData.canViewAllRuns
                : parametersData.usersShowAllRuns

            const authorizedShowUnassignedRuns =
                userData.hasScheduleConfiguration
                    ? userData.canViewUnassignedRuns
                    : parametersData.usersShowUnassignedRuns

            const hideFuturPlanningDaysForUsers =
                userData.hideFuturPlanningDaysForUser
                    ? userData.hideFuturPlanningDaysForUser
                    : parametersData.hideFuturPlanningDaysForUsers

            return {
                authorizedShowAllRuns,
                authorizedShowUnassignedRuns,
                hideFuturPlanningDaysForUsers,
            }
        },
    })
}

const useDriverRuns = (
    date,
    onlyUserRuns,
    showUnassignedRuns,
    authorizedShowAllRuns,
    hideFuturPlanningDaysForUsers
) => {
    return useQuery({
        queryKey: [
            'DriverRuns',
            date,
            onlyUserRuns,
            showUnassignedRuns,
            authorizedShowAllRuns,
            hideFuturPlanningDaysForUsers,
        ],
        queryFn: async () => {
            if (onlyUserRuns) {
                const runs = await Driver.fetchAllRuns(date)
                if (runs.length > 0) {
                    if (!authorizedShowAllRuns && showUnassignedRuns) {
                        const notAssignedResponse = await API.Runs.list(
                            null,
                            date,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            false,
                            false,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            false
                        )
                        const unassigned = notAssignedResponse['hydra:member']
                        console.log('ici')
                        return {
                            rows: [
                                ...formatDriverRuns(runs, true),
                                ...formatDriverRuns(unassigned),
                            ],
                            uniqueMasterRuns: [],
                            userColor: '#4299E1',
                        }
                    }

                    return {
                        rows: formatDriverRuns(runs, true),
                        uniqueMasterRuns: [],
                        userColor: '#4299E1',
                    }
                }
                return { rows: [], uniqueMasterRuns: [], userColor: '#4299E1' }
            }

            console.log('ici')

            if (!authorizedShowAllRuns && showUnassignedRuns) {
                const notAssignedResponse = await API.Runs.list(
                    null,
                    date,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    false,
                    false,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    false
                )
                console.log('cestla kesssaepasse')
                const runs = notAssignedResponse['hydra:member']
                return {
                    rows: formatDriverRuns(runs),
                    uniqueMasterRuns: [],
                    userColor: '#4299E1',
                }
            }

            const assignedResponse = await API.Runs.list(
                null,
                date,
                null,
                null,
                null,
                null,
                null,
                null,
                true,
                false,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                false
            )
            const assignedRuns = assignedResponse['hydra:member']

            const notAssignedResponse = await API.Runs.list(
                null,
                date,
                null,
                null,
                null,
                null,
                null,
                null,
                false,
                false,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
            )

            const uniqueMasterRuns = Object.values(
                assignedRuns.reduce((acc, run) => {
                    const { id } = run.masterRun
                    if (!acc[id]) {
                        acc[id] = run.masterRun
                    }
                    return acc
                }, {})
            )

            const allRuns = [
                ...assignedRuns,
                ...notAssignedResponse['hydra:member'],
            ]
            const formattedRuns = formatDriverRuns(allRuns)

            let userColor = '#4299E1'
            const userRun = formattedRuns.find((run) =>
                run.masterRun?.users.find(
                    (user) => user.id === parseInt(localStorage.getItem('id'))
                )
            )
            if (userRun) {
                userColor = userRun.masterRun.vehicle.color
            }

            console.log('formattedRuns', formattedRuns)

            return {
                rows: formattedRuns,
                uniqueMasterRuns,
                userColor,
            }
        },

        enabled: !isHidedPlanning(date, hideFuturPlanningDaysForUsers),
    })
}

const isHidedPlanning = (date, hideFuturPlanningDaysForUsers) => {
    console.log(hideFuturPlanningDaysForUsers)
    if (!hideFuturPlanningDaysForUsers) {
        return false
    }
    if (!date) return false

    const dateNow = dayjs(new Date())
    const diff = dayjs(date).diff(dateNow, 'day', true)

    if (diff > 1) return true
    if (diff > 0 && diff < 1 && dayjs(dateNow).hour() < 18) return true
    return false
}

const DriverRunsContainer = () => {
    const setOnlyUserRuns = useUserAccessStore((state) => state.setOnlyUserRuns)
    const onlyUserRuns = useUserAccessStore((state) => state.onlyUserRuns)
    const setShowUnassignedRuns = useUserAccessStore(
        (state) => state.setShowUnassignedRuns
    )
    const showUnassignedRuns = useUserAccessStore(
        (state) => state.showUnassignedRuns
    )
    const [date, setDate] = useStickyDateState(
        new Date(dayjs().add(1, 'day')),
        'storedDate'
    )

    const { data: parameters = {}, isLoading: isLoadingParameters } =
        useUserParameters()
    const {
        authorizedShowAllRuns = false,
        authorizedShowUnassignedRuns = false,
        hideFuturPlanningDaysForUsers = false,
    } = parameters

    const { data: driverData = {}, isLoading: isLoadingDriver } = useDriverRuns(
        date,
        onlyUserRuns,
        showUnassignedRuns,
        authorizedShowAllRuns,
        hideFuturPlanningDaysForUsers
    )

    const {
        rows = [],
        uniqueMasterRuns = [],
        userColor = '#4299E1',
    } = driverData

    if (isLoadingParameters || isLoadingDriver) {
        return <SkeletonLoaderCards />
    }

    return (
        <div>
            <ScheduleRunsFilter
                onlyUserRuns={onlyUserRuns}
                setOnlyUserRuns={setOnlyUserRuns}
                date={date}
                setDate={setDate}
                rows={rows}
                authorizedShowAllRuns={authorizedShowAllRuns}
                showUnassignedRuns={showUnassignedRuns}
                authorizedShowUnassignedRuns={authorizedShowUnassignedRuns}
                setShowUnassignedRuns={setShowUnassignedRuns}
            />
            <ScheduleRunsList
                onlyUserRuns={onlyUserRuns}
                showUnassignedRun={showUnassignedRuns}
                rows={rows}
                userColor={userColor}
                uniqueMasterRuns={uniqueMasterRuns}
                loading={isLoadingDriver}
                isHidedPlanning={() =>
                    isHidedPlanning(date, hideFuturPlanningDaysForUsers)
                }
            />
        </div>
    )
}

export default DriverRunsContainer
