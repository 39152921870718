import dayjs from 'dayjs'
import * as isBetween from 'dayjs/plugin/isBetween'
import React from 'react'
import { getErrorMessage } from '../components/App/Regulation/FormHandlers/handleInvoiceForm'
import { toast } from 'react-toastify'
import { TarifList } from './TarifList'
import API from './api'
import { getRunInvoiceFormDetailsInitialState } from '../components/App/Regulation/Forms/Invoice/RunInvoiceFormDetailsReducer'
import { isHolidayOrSunday } from './DateService'
import { formatDateYYYYMMDD } from './dateUtils'

dayjs.extend(isBetween)

export const getTotalsFromInvoiceFormDetails = (updatedPrices, rate) => {
    return updatedPrices.reduce(
        (acc, runInvoiceDetails) => {
            const abatementAmount =
                runInvoiceDetails.totalBeforeAbatement > 0
                    ? runInvoiceDetails.totalBeforeAbatement -
                      runInvoiceDetails.totalAmount
                    : 0

            rate = parseFloat(rate)

            // Calculer totalAmo et totalAmc avec une précision maximale
            let totalAmo = (runInvoiceDetails.totalAmount * rate) / 100
            let totalAmc = (runInvoiceDetails.totalAmount * (100 - rate)) / 100
            // TODO PROBLEME D"ARRONDI ICI COMMENT FAIRE ???
            // Arrondir totalAmo et totalAmc pour l'affichage et la cohérence
            totalAmo = parseFloat(totalAmo.toFixed(2))
            totalAmc = parseFloat(totalAmc.toFixed(2))

            const totalAmount = totalAmo + totalAmc
            return {
                totalAmount: acc.totalAmount + totalAmount,
                totalAmo: acc.totalAmo + totalAmo,
                totalAmc: acc.totalAmc + totalAmc,
                totalDiscount:
                    acc.totalDiscount + runInvoiceDetails.totalDiscount,
                totalSupplements: acc.totalSupplements.concat(
                    runInvoiceDetails.runInvoiceSupplements
                ),
                totalAmountPriceA:
                    acc.totalAmountPriceA +
                    runInvoiceDetails.details
                        .map(
                            (detail) => detail.tarifType === 'A' && detail.total
                        )
                        .reduce((a, b) => a + b, 0),
                totalAmountPriceB:
                    acc.totalAmountPriceB +
                    runInvoiceDetails.details
                        .map(
                            (detail) => detail.tarifType === 'B' && detail.total
                        )
                        .reduce((a, b) => a + b, 0),
                totalAmountPriceC:
                    acc.totalAmountPriceC +
                    runInvoiceDetails.details
                        .map(
                            (detail) => detail.tarifType === 'C' && detail.total
                        )
                        .reduce((a, b) => a + b, 0),

                totalAmountPriceD:
                    acc.totalAmountPriceD +
                    runInvoiceDetails.details
                        .map(
                            (detail) => detail.tarifType === 'D' && detail.total
                        )
                        .reduce((a, b) => a + b, 0),
                totalPecAmount:
                    acc.totalPecAmount + runInvoiceDetails.pecAmount,
                totalKilometerA:
                    acc.totalKilometerA +
                    runInvoiceDetails.details
                        .map(
                            (detail) =>
                                detail.tarifType === 'A' && detail.kilometer
                        )
                        .reduce((a, b) => a + b, 0),
                totalKilometerB:
                    acc.totalKilometerB +
                    runInvoiceDetails.details
                        .map(
                            (detail) =>
                                detail.tarifType === 'B' && detail.kilometer
                        )
                        .reduce((a, b) => a + b, 0),
                totalKilometerC:
                    acc.totalKilometerC +
                    runInvoiceDetails.details
                        .map(
                            (detail) =>
                                detail.tarifType === 'C' && detail.kilometer
                        )
                        .reduce((a, b) => a + b, 0),
                totalKilometerD:
                    acc.totalKilometerD +
                    runInvoiceDetails.details
                        .map(
                            (detail) =>
                                detail.tarifType === 'D' && detail.kilometer
                        )
                        .reduce((a, b) => a + b, 0),
                totalForfaitAmount:
                    acc.totalForfaitAmount + runInvoiceDetails.forfaitAmount,
                totalAbatementAmount:
                    acc.totalAbatementAmount + abatementAmount,
                totalSupplementsAmount:
                    acc.totalSupplementsAmount +
                    runInvoiceDetails.runInvoiceSupplements
                        ?.map((supplement) => parseFloat(supplement.price))
                        .reduce((a, b) => a + b, 0),
                totalNbForfait:
                    acc.totalNbForfait + (runInvoiceDetails.isForfait ? 1 : 0),
                totalNbHorokilometric:
                    acc.totalNbHorokilometric +
                    (runInvoiceDetails.isHorokilometrique &&
                    runInvoiceDetails.amount
                        ? 1
                        : 0),
                totalNbKilometric:
                    acc.totalNbKilometric +
                    (!runInvoiceDetails.isForfait &&
                    !runInvoiceDetails.isHorokilometrique
                        ? 1
                        : 0),
                totalNbSharedRun:
                    acc.totalNbSharedRun + (runInvoiceDetails.isShared ? 1 : 0),
                totalNbMinimumPerception:
                    acc.totalNbMinimumPerception +
                    (runInvoiceDetails.isMinimumPerception &&
                    runInvoiceDetails.minimumPerceptionAmount
                        ? 1
                        : 0),
            }
        },
        {
            totalAmount: 0,
            totalAmo: 0,
            totalAmc: 0,
            totalDiscount: 0,
            totalAmountPriceA: 0,
            totalAmountPriceB: 0,
            totalAmountPriceC: 0,
            totalAmountPriceD: 0,
            totalKilometerA: 0,
            totalKilometerB: 0,
            totalKilometerC: 0,
            totalKilometerD: 0,
            totalForfaitAmount: 0,
            totalSupplements: [],
            totalSupplementsAmount: 0,
            totalAbatementAmount: 0,
            totalPecAmount: 0,
            totalNbForfait: 0,
            totalNbHorokilometric: 0,
            totalNbKilometric: 0,
            totalNbSharedRun: 0,
            totalNbMinimumPerception: 0,
        }
    )
}

export const checkIsBetween = (date, start, end) => {
    return dayjs(date).isBetween(start, end, null, '[]')
}
export const updateCalculatedPriceFromRunInvoiceDetailsForms = (
    runsInvoiceFormsDetails
) => {
    return runsInvoiceFormsDetails.map((runInvoiceDetails) => {
        let { priceBasis, amount, runInvoiceSupplements } = runInvoiceDetails
        let isHorokilometrique = false
        // Si le montant est déjà défini on prend le montant
        amount = runInvoiceDetails.amount || amount
        const newDetails = []
        const discounts = []
        const userKmDetails = {
            A: runInvoiceDetails.kilometerA,
            B: runInvoiceDetails.kilometerB,
            C: runInvoiceDetails.kilometerC,
            D: runInvoiceDetails.kilometerD,
        }
        const priceBasisLines = priceBasis.priceBasisLines
        let total = 0
        let totalDiscounts = 0

        for (const tarifType in userKmDetails) {
            const tarif = priceBasisLines.find(
                (line) => line.tarifType === tarifType
            )
            if (tarif && userKmDetails[tarifType] > 0) {
                const km = userKmDetails[tarifType]
                let tarifTotal = tarif.price * km
                let tarifDiscount = (tarif.discount / 100) * tarifTotal
                totalDiscounts += tarifDiscount
                total += tarifTotal

                if (km > 0) {
                    discounts.push({
                        pourcentage: tarif.discount,
                        price: tarifDiscount,
                    })
                    newDetails.push({
                        tarifType: tarif.tarifType,
                        price: tarif.price,
                        discount: tarif.discount,
                        totalDiscount: tarifDiscount,
                        kilometer: km,
                        total: tarifTotal,
                    })
                }
            }
        }

        let discount = 0 // TODO DIFINED FOR FIX CASES
        let pecAmount = priceBasis.pecAmount
        // PEC = prise en charge
        // En retour direct il n'y a qu'une seule PEC
        if (
            (runInvoiceDetails.directReturn &&
                runInvoiceDetails.isReturnPath) ||
            runInvoiceDetails.forfaitAmount ||
            amount
        ) {
            pecAmount = 0
        }
        total += pecAmount

        discount = newDetails[0]?.discount
        if (!amount) {
            total -= totalDiscounts
            let pecDiscount = (discount / 100) * pecAmount
            totalDiscounts += pecDiscount
            total -= pecDiscount
        } else {
            totalDiscounts =
                (discount / 100) * (amount ? parseFloat(amount) : total)
            isHorokilometrique = true
            total = parseFloat(amount) - totalDiscounts
        }
        if (runInvoiceDetails.isForfait && runInvoiceDetails.forfaitAmount) {
            total = parseFloat(runInvoiceDetails.forfaitAmount)
            totalDiscounts = 0
        }

        let totalNotDiscountedOrNotIncludedInAbatement = 0

        if (runInvoiceSupplements && runInvoiceSupplements.length > 0) {
            for (const supplement of runInvoiceSupplements) {
                if (supplement.isDiscounted) {
                    let supplementDiscount =
                        (discount / 100) * parseFloat(supplement.price)
                    totalDiscounts += supplementDiscount
                    total += parseFloat(supplement.price) - supplementDiscount
                } else {
                    totalNotDiscountedOrNotIncludedInAbatement += parseFloat(
                        supplement.price
                    )
                }
            }
        }

        let totalBeforeAbatement = 0

        if (runInvoiceDetails.abatement?.value) {
            totalBeforeAbatement =
                total + totalNotDiscountedOrNotIncludedInAbatement
            total -= (runInvoiceDetails.abatement?.value / 100) * total
        }

        let isMinimumPerception = false
        if (
            runInvoiceDetails.isMinimumPerception &&
            parseFloat(runInvoiceDetails.minimumPerceptionAmount) > total
        ) {
            isMinimumPerception = true
            total = parseFloat(runInvoiceDetails.minimumPerceptionAmount)
            discount = 0
            totalDiscounts = 0
            pecAmount = 0
        }

        total += totalNotDiscountedOrNotIncludedInAbatement

        runInvoiceDetails.amount = amount
        runInvoiceDetails.isHorokilometrique = isHorokilometrique
        runInvoiceDetails.isMinimumPerception = isMinimumPerception
        runInvoiceDetails.totalBeforeAbatement = totalBeforeAbatement
        runInvoiceDetails.pecAmount = pecAmount
        runInvoiceDetails.discount = discount
        runInvoiceDetails.details = newDetails
        runInvoiceDetails.runInvoiceSupplements = runInvoiceSupplements
        runInvoiceDetails.totalAmount = total
        runInvoiceDetails.totalDiscount = totalDiscounts
        runInvoiceDetails.discounts = discounts

        return runInvoiceDetails
    })
}
export const InitCalculatedPriceFromRunInvoiceDetails = (
    invoice,
    run,
    isTPMR
) => {
    let { kilometer, amount, priceBases } = run
    let pickUpLocation = run.pickUpLocation
    let depositLocation = run.depositLocation
    // check si ya amount et si c'est horokilometrique check si ya bien le taux de prescription
    return invoice.runsInvoiceFormsDetails.map((runInvoiceDetails) => {
        // Cas ou les kilometer du linkedRun ne sont pas encore définis
        if (!runInvoiceDetails.totalKilometer) {
            runInvoiceDetails.totalKilometer = kilometer
        } else {
            // Cas ou les kilometer du linkedRun sont déjà définis
            kilometer = runInvoiceDetails.totalKilometer
        }

        if (runInvoiceDetails.runId !== run.id) {
            // console.log(run)
            pickUpLocation = run.linkedRun.pickUpLocation
            depositLocation = run.linkedRun.depositLocation
        } else {
            pickUpLocation = run.pickUpLocation
            depositLocation = run.depositLocation
        }

        return transformRunInvoiceDetails(
            runInvoiceDetails,
            priceBases,
            kilometer,
            amount,
            isTPMR,
            pickUpLocation,
            depositLocation
        )
    })
}

const checkIfIsForfait = (currentPriceBasis, runInvoiceDetails) => {
    // Vérifie si des forfaits sont définis dans la base tarifaire
    if (currentPriceBasis.forfaits?.length > 0) {
        // Trouve le premier forfait qui correspond à la distance totale
        const forfait = currentPriceBasis.forfaits.find(
            (forfait) =>
                runInvoiceDetails.totalKilometer >= forfait.minDistance &&
                runInvoiceDetails.totalKilometer <= forfait.maxDistance
        )
        // Si un forfait correspondant est trouvé, met à jour les détails de la facture
        if (forfait) {
            runInvoiceDetails.isForfait = true
            runInvoiceDetails.forfaitAmount = forfait.price
        } else {
            runInvoiceDetails.isForfait = false
            runInvoiceDetails.forfaitAmount = null
        }
    }

    return runInvoiceDetails
}
export const seriesInitCalculatedPriceFromRunInvoiceDetails = (
    runsInvoiceFormsDetails,
    priceBases,
    isTPMR,
    runs
) => {
    // TODO SERIES
    // check si ya amount et si c'est horokilometrique check si ya bien le taux de prescription
    return runsInvoiceFormsDetails.map((runInvoiceDetails) => {
        // trouve la run correspondante au runInvoiceDetails.runId
        let run = runs.find((run) => run.id === runInvoiceDetails.runId)

        return transformRunInvoiceDetails(
            runInvoiceDetails,
            priceBases,
            runInvoiceDetails.totalKilometer,
            null,
            isTPMR,
            run.pickUpLocation,
            run.depositLocation
        )
    })
}

const findWaitingPrice = (
    minutes,
    tarifHoraire,
    isFixedWaitingPrice = false
) => {
    if (isFixedWaitingPrice) {
        return parseFloat(tarifHoraire).toFixed(2)
    }
    let heures = minutes / 60
    return parseFloat(heures * tarifHoraire).toFixed(2)
}

export const findCurrentPriceBasis = (priceBases, targetRunDate) => {
    if (priceBases.length === 0) return null
    const runDate = dayjs(targetRunDate)

    const priceBasis = priceBases.find((priceBasis) => {
        const startDate = dayjs(priceBasis.dateFrom)
        const endDate = dayjs(priceBasis.dateTo)
        return runDate.isBetween(startDate, endDate, null, '[]')
    })
    if (!priceBasis) {
        toast.error("Aucune base tarifaire n'est disponible ?")
    }

    return priceBasis
}

export const findApplicableTarif = (
    currentPriceBasis,
    departureTime,
    arrivingTime,
    directReturn,
    date
) => {
    const priceBasisLines = currentPriceBasis.priceBasisLines
    let applicableTarifs = []

    let tarifA, tarifB, tarifC, tarifD

    for (const line of priceBasisLines) {
        if (line.tarifType === 'A') {
            tarifA = line
        }
        if (line.tarifType === 'B') {
            tarifB = line
        }
        if (line.tarifType === 'C') {
            tarifC = line
        }
        if (line.tarifType === 'D') {
            tarifD = line
        }
    }

    const extractMinutes = (timeStr) => {
        const [hours, minutes] = timeStr.split(':').map(Number)
        return hours * 60 + minutes
    }

    const departureTimeMinutes = extractMinutes(
        dayjs.utc(departureTime).format('HH:mm')
    )
    const arrivingTimeMinutes = extractMinutes(
        dayjs.utc(arrivingTime).format('HH:mm')
    )
    const tarifAStartMinutes = extractMinutes(
        dayjs.utc(tarifA.startFrom).format('HH:mm')
    )
    const tarifAEndMinutes = extractMinutes(
        dayjs.utc(tarifA.endAt).format('HH:mm')
    )

    if (isHolidayOrSunday(date)) {
        console.log(
            'Jour férié ou dimanche - Tarifs tranche 2 (B-D) applicables'
        )
        applicableTarifs.push(tarifB, tarifD)
    } else {
        if (
            isTimeBetween(
                departureTimeMinutes,
                tarifAStartMinutes,
                tarifAEndMinutes
            ) ||
            isTimeBetween(
                arrivingTimeMinutes,
                tarifAStartMinutes,
                tarifAEndMinutes
            )
        ) {
            console.log('Tarifs tranche 1 (A-C) applicables')
            applicableTarifs.push(tarifA, tarifC)
        }

        if (
            departureTimeMinutes < tarifAStartMinutes ||
            arrivingTimeMinutes > tarifAEndMinutes
        ) {
            console.log('Tarifs tranche 2 (B-D) applicables')
            applicableTarifs.push(tarifB, tarifD)
        }
    }

    if (directReturn) {
        return applicableTarifs.filter(
            (t) => t.tarifType === 'A' || t.tarifType === 'B'
        )
    } else {
        return applicableTarifs.filter(
            (t) => t.tarifType === 'C' || t.tarifType === 'D'
        )
    }
}

export const RenderInvoiceId = ({ invoiceId, label = false }) => {
    const year = invoiceId.substring(0, 2)
    const month = invoiceId.substring(2, 4)
    const id = invoiceId.substring(4)

    return (
        <div className="flex font-semibold">
            {label && <span className={'pr-1'}>N°</span>}
            <span className="year">{year}</span>
            <span className="separator">-</span>
            <span className="month">{month}</span>
            <span className="separator">-</span>
            <span className="id">{id}</span>
        </div>
    )
}

export const checkAbleToInvoice = (
    patient,
    invoiceForm,
    prescription,
    run,
    showNotif = true,
    dateOfBirth
) => {
    console.log(dateOfBirth, 'dateOfBirth')
    let msg = getErrorMessage(
        patient,
        invoiceForm,
        prescription,
        run,
        dateOfBirth
    )

    if (msg.length > 0) {
        if (showNotif) {
            msg.forEach((e) => {
                toast.error(e, { autoClose: false })
            })
        }
        return true
    }
}

export const findColorByStatus = (status) => {
    switch (status) {
        case 'Virement reçu':
            return 'border-green-500 text-green-500 bg-green-100'
        case 'En attente':
            return 'border-gray-500 text-gray-500 bg-gray-100'
        case 'Annulé':
            return 'border-orange-500 text-orange-500 bg-orange-100'
        default:
            return 'border-gray-500 text-gray-500 bg-gray-100'
    }
}

export const findColorByTarifType = (tarifType) => {
    const found = TarifList.find((item) => item.value === tarifType)
    return found ? found.color : 'bg-gray-200' // Retourne 'bg-gray-200' comme couleur par défaut si le tarifType n'est pas trouvé
}

export const formatAndSortInvoices = (invoices) => {
    return (
        invoices
            .map((invoice) => {
                // Extraction et formatage des informations
                return {
                    ...invoice,
                    patientFullName:
                        invoice.runs && invoice.runs.length > 0
                            ? `${invoice.patient.firstname} ${invoice.patient.lastname}`
                            : 'N/A',
                    healthComplementaryAmount:
                        invoice.healthComplementaryAmount || 'N/A',
                    payingCenterAmount: invoice.payingCenterAmount || 'N/A',
                    totalAmount: invoice.totalInvoiceAmount || 'N/A',
                    rejectionReason: invoice.rejectionReason || 'N/A',
                    status: invoice.status ? invoice.status.label : 'N/A',
                    numFacture: invoice.cpamInvoiceId,
                }
            })
            // Tri par numéro de facture
            .sort((a, b) => a.numFacture.localeCompare(b.numFacture))
    )
}

export const handleInvoiceCpamModalForm = (
    invoice,
    dispatch,
    ACTION,
    closeModal,
    rate
) => {
    // check for every invoice detail forfaitAmount is float or a number and does not contain letters
    const isForfaitAmountValid = invoice.runsInvoiceFormsDetailsHolder.every(
        (runInvoiceDetails) => {
            let forfaitAmount = String(runInvoiceDetails.forfaitAmount)
            return (
                !runInvoiceDetails.isForfait ||
                forfaitAmount === '' ||
                forfaitAmount.match(/^[\d.]+$/)
            )
        }
    )
    if (!isForfaitAmountValid) {
        toast.error('Veuillez renseigner un montant forfaitaire valide')
        return
    } else {
        // parse float every forfaitAmount
        invoice.runsInvoiceFormsDetailsHolder.forEach((runInvoiceDetails) => {
            runInvoiceDetails.forfaitAmount = parseFloat(
                runInvoiceDetails.forfaitAmount
            )
        })
    }

    // check for every supplement price is float or a number and does not contain letters
    const everySupplementHasPrice = invoice.runsInvoiceFormsDetailsHolder.every(
        (runInvoiceDetails) =>
            runInvoiceDetails.runInvoiceSupplements.every(
                (supplement) =>
                    supplement.price > 0 &&
                    String(supplement.price).match(/^[\d.]+$/)
            )
    )

    if (!everySupplementHasPrice) {
        toast.error('Veuillez renseigner un prix valide pour chaque supplément')
        return
    } else {
        // parse float every supplement price
        invoice.runsInvoiceFormsDetailsHolder.forEach((runInvoiceDetails) => {
            runInvoiceDetails.runInvoiceSupplements.forEach((supplement) => {
                supplement.price = parseFloat(supplement.price)
            })
        })
    }

    // TODO BUG JS ?? Si on update via le dispatch le state n'est pas mis à jour
    //
    // dispatch({
    //     type: ACTION.UPDATE_FORM_PRICE_BASIS,
    //     payload: invoice.priceBasisForm,
    // })

    invoice.runsInvoiceFormsDetailsHolder =
        invoice.runsInvoiceFormsDetailsHolder.map((run) => {
            return {
                ...run,
                priceBasis: invoice.priceBasisForm,
            }
        })

    const updatedPrices = updateCalculatedPriceFromRunInvoiceDetailsForms(
        invoice.runsInvoiceFormsDetailsHolder
    )
    dispatch({
        type: ACTION.UPDATE_ALL_RUNS_INVOICE_DETAILS,
        payload: updatedPrices,
    })
    //update price basis
    dispatch({
        type: ACTION.UPDATE_PRICE_BASIS,
        payload: invoice.priceBasisForm,
    })

    const totals = getTotalsFromInvoiceFormDetails(updatedPrices, rate)

    dispatch({
        type: ACTION.SET_TOTALS,
        payload: totals,
    })

    closeModal()
}

export const handleInvoiceStatusChange = async (
    type,
    status,
    statusChangedAt,
    invoice,
    dispatch,
    ACTION
) => {
    let data
    if (type === 'AMO') {
        data = {
            payingCenterStatus: status,
            payingCenterStatusPaidAt: formatDateYYYYMMDD(statusChangedAt),
        }
    } else {
        data = {
            healthComplementaryStatus: status,
            healthComplementaryStatusPaidAt:
                formatDateYYYYMMDD(statusChangedAt),
        }
    }
    try {
        const response = await API.CpamInvoice.changeStatuses(invoice.id, data)
        dispatch({
            type: ACTION.FETCH_INVOICE_CPAM,
            payload: response,
        })
        toast.success('Le statut a été modifié.')
    } catch (error) {
        if (error.response && error.response.error) {
            // Assuming the API returns errors in a property named `data`
            toast.error(error.response.error['hydra:description'])
        } else {
            // Handle other errors like network issues
            toast.error(
                'Une erreur interne est survenue, veuillez contacter un administrateur.'
            )
        }
    }
}

export const handleSuspendedInvoice = async (invoice, dispatch, ACTION) => {
    if (
        invoice.status.label === 'Créée' ||
        invoice.status.label === 'Suspendue'
    ) {
        let data = {
            status:
                invoice.status.label === 'Suspendue' ? 'Créée' : 'Suspendue',
        }
        try {
            const response = await API.CpamInvoice.suspend(invoice.id, data)
            dispatch({
                type: ACTION.FETCH_INVOICE_CPAM,
                payload: response,
            })
        } catch (error) {
            if (error.response && error.response.error) {
                // Assuming the API returns errors in a property named `data`
                toast.error(error.response.error['hydra:description'])
            } else {
                // Handle other errors like network issues
                toast.error(
                    'Une erreur interne est survenue, veuillez contacter un administrateur.'
                )
            }
        }
    }
}

export const getRunInvoiceFormDetailsFromRuns = (runs) => {
    return runs
        .filter((run) => !run.isGiven)
        .map((run) => {
            let { runInvoiceDetails } = run
            let runId = run.id
            let nbPeople

            if (runInvoiceDetails?.nbPeople === 1) {
                nbPeople = 1
            } else if (runInvoiceDetails?.nbPeople > 1) {
                nbPeople = runInvoiceDetails?.nbPeople
            } else if (run.childrenIds.length > 0) {
                nbPeople = run.childrenIds.length + 1
            } else if (run.departureOrder && run.parent?.childrenIds) {
                nbPeople = run.parent?.childrenIds.length + 1
            } else {
                nbPeople = run.departureOrder ? 2 : 1
            }

            let isShared = nbPeople > 1

            return getRunInvoiceFormDetailsInitialState({
                runInvoiceDetails,
                runId,
                runDate: run.date,
                kilometer: run.kilometer,
                firstRunOfDirectReturn: run.directReturn && !run.isReturnPath,
                directReturn: run.directReturn,
                arrivingTime: run.arrivingTime,
                departureTime: run.departureTime,
                waitingTime: run.waitingTime,
                isReturnPath: run.isReturnPath,
                amount: run.amount,
                nbPeople: nbPeople,
                isMinimumPerception:
                    runInvoiceDetails?.minimumPerceptionAmount > 0,
                isShared: isShared,
            })
        })
}

const checkIfIsMinimumPerception = (
    currentPriceBasis,
    runInvoiceDetails,
    pickUpLocation,
    depositLocation
) => {
    if (currentPriceBasis.minimumPerceptions?.length > 0) {
        // Vérifier si la ville de départ et la ville d'arrivée sont les mêmes
        // TODO EDGE CASES HOPITAL DEUX VILLES DIFF // AJUSTER START FROM TO BDD
        if (pickUpLocation.city === depositLocation.city) {
            // Vérifie si une perception minimale est définie dans la base tarifaire

            // Trouve la perception minimale correspondante
            const minimumPerception = currentPriceBasis.minimumPerceptions.find(
                (perception) => perception.startFrom === pickUpLocation.city
            )

            // Si une perception minimale correspondante est trouvée, met à jour les détails de la facture
            if (minimumPerception) {
                console.log('Minimum perception found', minimumPerception)
                runInvoiceDetails.minimumPerceptionAmount =
                    minimumPerception.price
                runInvoiceDetails.isMinimumPerception = true
            } else {
                runInvoiceDetails.minimumPerceptionAmount = null
            }
        } else {
            // Gestion des cas particuliers de manière générique
            const specialCase =
                currentPriceBasis.minimumPerceptions.find(
                    (caseItem) =>
                        caseItem.startFrom === pickUpLocation.city &&
                        caseItem.endAt === depositLocation.city &&
                        pickUpLocation.label === caseItem.label
                ) ||
                currentPriceBasis.minimumPerceptions.find(
                    (caseItem) =>
                        caseItem.startFrom === depositLocation.city &&
                        caseItem.endAt === pickUpLocation.city &&
                        depositLocation.label === caseItem.label
                )

            if (specialCase) {
                // Trouve la perception minimale correspondante pour le cas particulier
                const minimumPerception =
                    currentPriceBasis.minimumPerceptions.find(
                        (perception) => perception.label === specialCase.label
                    )

                // Si une perception minimale correspondante est trouvée, met à jour les détails de la facture
                if (minimumPerception) {
                    console.log(
                        'Minimum perception found for special case',
                        minimumPerception
                    )
                    runInvoiceDetails.minimumPerceptionAmount =
                        minimumPerception.price
                    runInvoiceDetails.isMinimumPerception = true
                } else {
                    runInvoiceDetails.minimumPerceptionAmount = null
                }
            } else {
                runInvoiceDetails.minimumPerceptionAmount = null
            }
        }
    }

    return runInvoiceDetails
}
const transformRunInvoiceDetails = (
    runInvoiceDetails,
    priceBases,
    kilometer = null,
    amount = null,
    isTPMR = false,
    pickUpLocation,
    depositLocation
) => {
    let details = []
    let isHorokilometrique = false
    // If the amount is already defined, use it
    amount = runInvoiceDetails.amount || amount

    // Find the price basis according to the date
    const currentPriceBasis = findCurrentPriceBasis(
        priceBases,
        runInvoiceDetails.date
    )

    // Check if the run is a forfait
    runInvoiceDetails = checkIfIsForfait(currentPriceBasis, runInvoiceDetails)

    runInvoiceDetails = checkIfIsMinimumPerception(
        currentPriceBasis,
        runInvoiceDetails,
        pickUpLocation,
        depositLocation
    )

    // Find the applicable rate
    const applicableTarifs = findApplicableTarif(
        currentPriceBasis,
        runInvoiceDetails.departureTime,
        runInvoiceDetails.arrivingTime,
        runInvoiceDetails.directReturn,
        runInvoiceDetails.date
    )

    const isFixedWaitingPrice = currentPriceBasis.label === 'Mayotte'

    // Add the waiting supplement on the outward journey if it is a direct return
    let runInvoiceSupplements = []
    let index = 1
    if (
        runInvoiceDetails.firstRunOfDirectReturn &&
        !runInvoiceDetails.forfaitAmount
    ) {
        const waitingTime = runInvoiceDetails.waitingTime
        runInvoiceSupplements.push({
            index: index,
            label: 'Attente' + ' ' + waitingTime + ' min',
            price: parseFloat(
                findWaitingPrice(
                    waitingTime,
                    currentPriceBasis.waitingHourlyRate,
                    isFixedWaitingPrice
                )
            ),
            isDiscounted: true,
        })
        index++
    }

    if (isTPMR) {
        runInvoiceSupplements.push({
            index: index,
            label: 'TPMR',
            price: 30,
            isDiscounted: false,
        })
        index++
    }

    // TODO make a prorata for the rates / kilometer
    const dividedKm = kilometer / applicableTarifs.length
    let total = 0
    let discounts = []
    let totalDiscounts = 0

    for (const tarif of applicableTarifs) {
        let tarifTotal = tarif.price * dividedKm
        let tarifDiscount = (tarif.discount / 100) * tarifTotal

        total += tarifTotal
        totalDiscounts += tarifDiscount

        if (dividedKm > 0) {
            discounts.push({
                pourcentage: tarif.discount,
                price: tarifDiscount,
            })
            // Add the detail for this specific rate
            details.push({
                tarifType: tarif.tarifType,
                price: tarif.price,
                discount: tarif.discount,
                totalDiscount: tarifDiscount,
                kilometer: dividedKm,
                total: tarifTotal,
            })

            runInvoiceDetails[`kilometer${tarif.tarifType}`] = dividedKm
        }
    }

    let discount = 0 // TODO DEFINE FOR FIX CASES
    let pecAmount = currentPriceBasis.pecAmount
    // PEC = support
    // In direct return there is only one PEC
    if (
        (runInvoiceDetails.directReturn && runInvoiceDetails.isReturnPath) ||
        runInvoiceDetails.forfaitAmount ||
        amount
    ) {
        pecAmount = 0
    }
    total += pecAmount

    // Calculate discount and totalDiscounts
    discount = details[0]?.discount
    if (!amount) {
        total -= totalDiscounts

        let pecDiscount = (discount / 100) * pecAmount
        totalDiscounts += pecDiscount

        total -= pecDiscount
    } else {
        totalDiscounts =
            (discount / 100) * (amount ? parseFloat(amount) : total)
        isHorokilometrique = true
        total = parseFloat(amount) - totalDiscounts
    }

    if (runInvoiceDetails.isForfait && runInvoiceDetails.forfaitAmount) {
        total = parseFloat(runInvoiceDetails.forfaitAmount)
        totalDiscounts = 0
    }

    if (runInvoiceSupplements && runInvoiceSupplements.length > 0) {
        for (const supplement of runInvoiceSupplements) {
            if (supplement.isDiscounted) {
                let supplementDiscount =
                    (discount / 100) * parseFloat(supplement.price)
                totalDiscounts += supplementDiscount
                total += parseFloat(supplement.price) - supplementDiscount
            } else {
                total += parseFloat(supplement.price)
            }
        }
    }

    let isMinimumPerception = false
    if (
        runInvoiceDetails.isMinimumPerception &&
        runInvoiceDetails.minimumPerceptionAmount > total
    ) {
        isMinimumPerception = true
        total = runInvoiceDetails.minimumPerceptionAmount
        discount = 0
        totalDiscounts = 0
        discounts = 0
        pecAmount = 0
    }

    runInvoiceDetails.amount = amount
    runInvoiceDetails.isHorokilometrique = isHorokilometrique
    runInvoiceDetails.isMinimumPerception = isMinimumPerception
    runInvoiceDetails.discount = discount
    runInvoiceDetails.pecAmount = pecAmount
    runInvoiceDetails.priceBasis = currentPriceBasis
    runInvoiceDetails.details = details
    runInvoiceDetails.runInvoiceSupplements = runInvoiceSupplements
    runInvoiceDetails.totalAmount = total
    runInvoiceDetails.totalDiscount = totalDiscounts
    runInvoiceDetails.discounts = discounts

    return runInvoiceDetails
}

const checkInvoiceAbelErrors = (run, currentPriceBasis, rate) => {
    let msg = []
    if (run.directReturn && !run.linkedRun) {
        msg.push('Veuillez modifier la course avant de facturer')
    }
    if (!currentPriceBasis) {
        msg.push("Aucune base tarifaire n'est disponible")
    }
    if (!rate) {
        msg.push('Veuillez renseigner le taux de prise en charge')
    }
    if (msg.length > 0) {
        msg.forEach((e) => {
            toast.error(e)
        })
        return true
    }
    return false
}

export const getNewForfaitAndSupplements = (
    runInvoiceDetails,
    priceBasis,
    run
) => {
    runInvoiceDetails.totalKilometer = run.kilometer

    runInvoiceDetails = checkIfIsForfait(priceBasis, runInvoiceDetails)
    runInvoiceDetails = checkIfIsMinimumPerception(
        priceBasis,
        runInvoiceDetails,
        run.pickUpLocation,
        run.depositLocation
    )

    let runInvoiceSupplements = []
    let index = 1
    if (
        runInvoiceDetails.firstRunOfDirectReturn &&
        !runInvoiceDetails.forfaitAmount
    ) {
        const waitingTime = runInvoiceDetails.waitingTime
        runInvoiceSupplements.push({
            index: index,
            label: 'Attente' + ' ' + waitingTime + ' min',
            price: parseFloat(
                findWaitingPrice(waitingTime, priceBasis.waitingHourlyRate)
            ),
            isDiscounted: true,
        })
        index++
    }

    if (runInvoiceDetails.isTPMR) {
        runInvoiceSupplements.push({
            index: index,
            label: 'TPMR',
            price: 30,
            isDiscounted: false,
        })
        index++
    }

    const applicableTarifs = findApplicableTarif(
        priceBasis,
        run.departureTime,
        run.arrivingTime,
        runInvoiceDetails.directReturn,
        run.date
    )

    let details = []

    // TODO make a prorata for the rates / kilometer
    const dividedKm = run.kilometer / applicableTarifs.length
    let total = 0
    let discounts = []
    let totalDiscounts = 0

    for (const tarif of applicableTarifs) {
        let tarifTotal = tarif.price * dividedKm
        let tarifDiscount = (tarif.discount / 100) * tarifTotal

        total += tarifTotal
        totalDiscounts += tarifDiscount

        if (dividedKm > 0) {
            discounts.push({
                pourcentage: tarif.discount,
                price: tarifDiscount,
            })
            // Add the detail for this specific rate
            details.push({
                tarifType: tarif.tarifType,
                price: tarif.price,
                discount: tarif.discount,
                totalDiscount: tarifDiscount,
                kilometer: dividedKm,
                total: tarifTotal,
            })

            runInvoiceDetails[`kilometer${tarif.tarifType}`] = dividedKm
        }
    }

    runInvoiceDetails.details = details
    runInvoiceDetails.runInvoiceSupplements = runInvoiceSupplements

    return runInvoiceDetails
}

export const handleResetInvoiceFormHolder = (invoice, priceBasis, runs) => {
    return invoice.runsInvoiceFormsDetails.map((runInvoiceDetails) => {
        const runInfo = getRunInfo(runInvoiceDetails, runs)
        const resetedInvoiceFormHolder = getNewForfaitAndSupplements(
            runInvoiceDetails,
            priceBasis,
            runInfo
        )
        const { kilometerA, kilometerB, kilometerC, kilometerD } =
            assignKilometerValues(resetedInvoiceFormHolder.details)

        return {
            ...resetedInvoiceFormHolder,
            kilometerA: kilometerA || null,
            kilometerB: kilometerB || null,
            kilometerC: kilometerC || null,
            kilometerD: kilometerD || null,
        }
    })
}

const getRunInfo = (runInvoiceDetails, runs) => {
    const run = runs.find((run) => run.id === runInvoiceDetails.runId)
    return {
        kilometer: runInvoiceDetails.totalKilometer || run.kilometer,
        departureTime: run.departureTime,
        arrivingTime: run.arrivingTime,
        date: run.date,
        pickUpLocation: run.pickUpLocation,
        depositLocation: run.depositLocation,
    }
}

const assignKilometerValues = (details) => {
    let kilometerValues = {
        kilometerA: 0,
        kilometerB: 0,
        kilometerC: 0,
        kilometerD: 0,
    }

    details.forEach((detail) => {
        if (detail.kilometer > 0) {
            kilometerValues[`kilometer${detail.tarifType}`] = detail.kilometer
        }
    })

    return kilometerValues
}

export const isTimeBetween = (time, start, end) => {
    return time > start && time < end
}
