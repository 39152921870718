import { apiClient, withErrorHandling } from '../apiClient'
import { formatHydraData } from '../../api'
import { formatDateYYYYMMDD } from '../../dateUtils'

const PATH_KEY = '/driver'
export const Driver = {
    fetchOneRun: withErrorHandling(async (id) => {
        return await apiClient.get(`${PATH_KEY}/fetch_run/${id}`)
    }),
    fetchAllRuns: withErrorHandling(async (date) => {
        const formattedDate = formatDateYYYYMMDD(date)
        const path = `${PATH_KEY}/fetch_all?date=${formattedDate}`

        return formatHydraData(await apiClient.get(path))
    }),
    invalidateRun: withErrorHandling(async (id) => {
        return await apiClient.get(`${PATH_KEY}/invalidate_run/${id}`)
    }),
    startDay: async (masterRunId) => {
        const response = await apiClient.post(
            `/api/master_runs/${masterRunId}/start`
        )
        return response.data
    },
    endDay: async (masterRunId) => {
        const response = await apiClient.post(
            `/api/master_runs/${masterRunId}/end`
        )
        return response.data
    },
    fetchMasterRun: withErrorHandling(async (masterRunId) => {
        return formatHydraData(
            await apiClient.get(`/api/master_runs/${masterRunId}`)
        )
    }),
    uploadToS3: withErrorHandling(async (data) => {
        const response = await apiClient.post(
            `/api/documents/s3-presigned-url`,
            {
                fileName: data.file.name,
                fileType: data.file.type,
                runId: data.runId, // L'ID du run qu'on souhaite compléter
                docType: data.docType,
            }
        )
        console.log(response)
        const { url } = response
        await fetch(url, {
            method: 'PUT',
            body: data.file,
            headers: { 'Content-Type': data.file.type },
        })
        return response.data
    }),
    deleteFromS3: withErrorHandling(async (data) => {
        const response = await apiClient.put(`/api/documents/s3-delete`, {
            runId: data.runId, // L'ID du run qu'on souhaite compléter
            docType: data.docType,
        })
        return response.data
    }),
}
