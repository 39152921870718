import React, { useCallback, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useLongPress } from 'use-long-press'
import TOOLS from '../../../../services/tools'
import MobileOption from '../../../Commons/MobileOption'
import DefaultOptions from '../../../Commons/DefaultOptions'
import Loader from '../../../Commons/Loaders/Loader'
import * as dayjs from 'dayjs'
import { formatEuro } from '../Manage'
import { LotStatus } from '../../../../services/InvoiceStatus'

const isLotAbleToBeTransfered = (lot) => {
    return !lot.B2File
}

export const findBorderByStatusType = (status) => {
    const found = LotStatus.find((item) => item.value === status.label)
    return found ? found.border : 'border-l-8 border-gray-200' // Retourne 'bg-gray-200' comme couleur par défaut si le tarifType n'est pas trouvé
}

export const findValueByStatus = (status) => {
    const found = LotStatus.find((item) => item.value === status.label)
    return found ? found.value : 'Créé' // Retourne 'bg-gray-200' comme couleur par défaut si le tarifType n'est pas trouvé
}

export const findColorByStatusType = (status) => {
    const found = LotStatus.find((item) => item.value === status.label)
    return found ? found.color : 'bg-gray-200' // Retourne 'bg-gray-200' comme couleur par défaut si le tarifType n'est pas trouvé
}

const LotListComponent = ({
    lots,
    loading,
    totalItems,
    transferGroup,
    setAddToTransferGroup,
}) => {
    let history = useNavigate()
    const location = useLocation()
    const [showMobileOptions, setShowMobileOptions] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [selectedRowId, setSelectedRowId] = useState(null)
    const historyPush = (id) => {
        history('/facturation/lots/' + id)
    }

    const isLocationTransfer = location.search.includes('?transfer=')

    const lotGroupedByPayingCenter = lots.reduce((groups, lot) => {
        const label = lot.payingCenter.label
        if (!groups[label]) {
            groups[label] = []
        }
        groups[label].push(lot)
        return groups
    }, {})

    const handleAddToTransferGroup = (lot) => {
        if (transferGroup.some((row) => row.id === lot.id)) {
            setAddToTransferGroup(
                transferGroup.filter((row) => row.id !== lot.id)
            )
        } else {
            setAddToTransferGroup([...transferGroup, lot])
        }
    }

    const optionsRef = useRef()

    TOOLS.useOnClickOutside(optionsRef, () => {
        setShowOptions(false)
    })

    const callback = useCallback((event) => {}, [])

    const bind = useLongPress(callback, {
        onStart: (event) => {
            setSelectedRowId(parseInt(event.currentTarget.id))
        },
        onFinish: (event) => setShowMobileOptions(true),
        onCancel: (event) => {
            setSelectedRowId(null)
            setShowMobileOptions(false)
        },
        onMove: (event) => console.log('Detected mouse or touch movement'),
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: true,
        detect: 'touch',
    })

    const handleSelectAction = (e, lot) => {
        if (isLocationTransfer && isLotAbleToBeTransfered(lot)) {
            handleAddToTransferGroup(lot)
            e.stopPropagation()
        } else {
            historyPush(lot.id)
        }
    }

    return (
        <div className="px-2 xl:pt-5">
            <div className="flex py-2">
                <div className="ml-auto text-xs font-bold">
                    {totalItems} Factures
                </div>
            </div>
            {loading ? (
                <Loader />
            ) : (
                Object.entries(lotGroupedByPayingCenter).map(
                    ([payingCenterLabel, lots]) => (
                        <div key={payingCenterLabel}>
                            <h2 className={'py-2 text-xl'}>
                                {payingCenterLabel}
                            </h2>
                            <div className="flex w-full flex-col space-y-2 lg:space-y-0 ">
                                {lots.map((lot) => (
                                    <div
                                        className="parents relative flex w-full pb-2"
                                        key={lot.id}
                                        {...bind}
                                        onClick={(e) =>
                                            handleSelectAction(e, lot)
                                        }
                                    >
                                        <div
                                            className={`grid w-full  ${findBorderByStatusType(
                                                lot.status
                                            )}
                                cursor-pointer select-none rounded bg-white lg:select-auto ${
                                    transferGroup.some(
                                        (row) => row.id === lot.id
                                    )
                                        ? 'shadow-transferable'
                                        : 'shadow-mobile'
                                }`}
                                        >
                                            <div
                                                className={`parents row-span-1 flex grid-rows-1  flex-wrap items-center justify-between px-2 py-2  text-sm lg:text-base`}
                                            >
                                                <div className="w-1/2 truncate sm:w-1/4 ">
                                                    <div>
                                                        {dayjs(
                                                            lot.createdAt
                                                        ).format('DD/MM/YYYY')}
                                                    </div>
                                                    <span
                                                        className={
                                                            'font-semibold'
                                                        }
                                                    >
                                                        Lot {lot.groupId}
                                                    </span>
                                                </div>
                                                <div
                                                    className={`flex w-1/2 space-x-4 divide-x-2 truncate text-center sm:w-1/4
                                       `}
                                                >
                                                    <div className="w-1/2">
                                                        <div>
                                                            {
                                                                lot
                                                                    ?.payingCenter
                                                                    .label
                                                            }
                                                        </div>
                                                        <span className="font-semibold">
                                                            {
                                                                lot.totalInvoiceAmountRefundedByAmo
                                                            }
                                                            €
                                                        </span>
                                                    </div>
                                                    <div className="w-1/2">
                                                        <div>Assuré / AMC</div>
                                                        <span className="font-semibold">
                                                            {
                                                                lot.totalInvoiceRefundedByComplementary
                                                            }
                                                            €
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex w-1/2 flex-col truncate text-center  sm:w-1/4 ">
                                                    <div>
                                                        {
                                                            lot.cpamInvoices
                                                                .length
                                                        }{' '}
                                                        factures
                                                    </div>
                                                    <span
                                                        className={
                                                            'font-semibold'
                                                        }
                                                    >
                                                        {formatEuro(
                                                            lot.totalInvoiceAmount
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="flex w-1/2 justify-center truncate sm:w-1/4 ">
                                                    <span
                                                        className={`flex w-28 justify-center rounded-full ${findColorByStatusType(
                                                            lot.status
                                                        )}
                                            `}
                                                    >
                                                        {findValueByStatus(
                                                            lot.status
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`absolute right-0 hidden h-10 cursor-pointer rounded pt-2 xl:block   ${
                                                lot.id === selectedRowId
                                                    ? null
                                                    : 'options '
                                            } `}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setShowOptions(true)
                                                setSelectedRowId(lot.id)
                                            }}
                                        >
                                            <i
                                                className={`fas fa-ellipsis-h w-20 pr-4 pt-1 text-right ${
                                                    showOptions ? 'hidden' : ''
                                                } `}
                                            />
                                            {lot.id === selectedRowId &&
                                            showOptions ? (
                                                <DefaultOptions
                                                    setShowMobileOptions={
                                                        setShowOptions
                                                    }
                                                    rowId={selectedRowId}
                                                    optionsRef={optionsRef}
                                                    // historyPushUpdate={historyPushUpdate}
                                                    historyPushConsulting={
                                                        historyPush
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                        {isLocationTransfer &&
                                        isLotAbleToBeTransfered(lot) ? (
                                            <div
                                                className={`absolute left-0 -ml-10 hidden h-10 w-10 cursor-pointer rounded pt-1 text-center xl:block
                                                `}
                                                onClick={(e) =>
                                                    handleSelectAction(e, lot)
                                                }
                                            >
                                                <button
                                                    className={`form-checkbox h-2 w-2 cursor-pointer rounded-sm border-dark-green text-dark-green ring-2 ring-offset-2 ${
                                                        transferGroup.some(
                                                            (row) =>
                                                                row.id ===
                                                                lot.id
                                                        )
                                                            ? 'bg-green-500 ring-green-500'
                                                            : 'bg-white ring-gray-500'
                                                    }`}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                )
            )}
            {showMobileOptions ? (
                <MobileOption
                    setShowMobileOptions={setShowMobileOptions}
                    rowId={selectedRowId}
                    historyPush={historyPush}
                    view={true}
                />
            ) : null}
        </div>
    )
}

export default LotListComponent
