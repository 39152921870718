import React, { useEffect } from 'react'
import SignaturePad from 'react-signature-pad-wrapper'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import { Erase, Trash } from 'iconoir-react'
import { RUNINFO_ACTIONS } from '../../../services/Reducers/RunInformationReducer'
import DeleteButton from '../../Commons/Buttons/DeleteButton'
import { useMutation } from '@tanstack/react-query'
import { Signature } from '../../../services/API/Entities/signature'
import { toast } from 'react-toastify'

const SignatureForm = ({
    signatureRef,
    dispatch,
    location,
    date,
    signature,
    signatureId,
}) => {
    const handleClear = () => {
        signatureRef.current.clear()
    }

    const { mutate: deleteSignature, isPending: isPendingSignature } =
        useMutation({
            mutationFn: Signature.deleteSignature,
            onSuccess: (data) => {
                dispatch({
                    type: RUNINFO_ACTIONS.CLEAR_SIGNATURE,
                    payload: data.id,
                })
                handleClear()
                toast.success('Signature supprimée')
            },
            onError: () => {},
        })

    const handleDelete = () => {
        deleteSignature(signatureId)
    }

    useEffect(() => {
        if (signature && signatureRef.current) {
            try {
                // Tenter de parser la signature vectorielle
                const strokes = JSON.parse(signature)
                if (Array.isArray(strokes)) {
                    signatureRef.current.fromData(strokes)
                } else {
                    // En cas de format inattendu, on ne fait rien ou on affiche une erreur
                    console.warn(
                        'Le format de la signature vectorielle est invalide.'
                    )
                }
            } catch (error) {
                // Si le parsing échoue, on peut conserver le fallback vers l'ancienne méthode
                console.warn(
                    'Erreur de parsing de la signature vectorielle, tentative de chargement en DataURL.'
                )
                signatureRef.current.fromDataURL(signature)
            }
        }
    }, [signature])
    return (
        <div className="w-full rounded-lg bg-white p-6 ">
            <div className={'mb-4 flex justify-between'}>
                <SecondaryButton
                    label={'Effacer'}
                    action={handleClear}
                    icon={<Erase />}
                />
                {signatureId ? (
                    <DeleteButton
                        icon={<Trash />}
                        action={handleDelete}
                        loader={isPendingSignature}
                        label={'Supprimer'}
                    />
                ) : null}
            </div>
            <div className="mb-4 w-full select-none rounded-md border-2 border-gray-300">
                <SignaturePad
                    options={{
                        minWidth: 1.5, // Réduit légèrement l'épaisseur du trait
                        maxWidth: 3,
                        penColor: 'rgb(0,0,0)',
                        backgroundColor: 'rgb(255,255,255)', // Fond blanc explicite
                    }}
                    className="h-40 w-full select-none"
                    canvasProps={{
                        width: 250, // Réduit de moitié
                        height: 300,
                    }}
                    ref={signatureRef}
                />
            </div>
            <div className="mb-4 text-lg text-gray-600">
                <div className="flex flex-col">
                    <div className="flex items-baseline">
                        <span className="mr-2">Fait le</span>
                        <input
                            type="text"
                            value={date}
                            placeholder={'jj/mm/aaaa'}
                            onChange={(e) =>
                                dispatch({
                                    type: RUNINFO_ACTIONS.SET_SIGNATURE_DATE,
                                    payload: e.target.value,
                                })
                            }
                            className="flex-grow border-b border-secondary-color text-center font-bold focus:border-primary-color focus:outline-none"
                        />
                    </div>
                    <div className="mt-2 flex items-baseline">
                        <span className="mr-11">à</span>
                        <input
                            type="text"
                            placeholder={'lieu'}
                            value={location}
                            onChange={(e) =>
                                dispatch({
                                    type: RUNINFO_ACTIONS.SET_SIGNATURE_LOCATION,
                                    payload: e.target.value,
                                })
                            }
                            className="flex-grow border-b border-secondary-color text-center font-bold focus:border-primary-color focus:outline-none"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignatureForm
