import { EditPencil } from 'iconoir-react'
import React from 'react'

const SignatureDocument = ({ action, signature }) => {
    return (
        <button
            onClick={action}
            className="flex h-24 w-full flex-col items-center justify-center gap-2 rounded-lg border-2 border-dashed border-orange-500 bg-orange-50/50 transition-all hover:bg-orange-50 active:bg-orange-100"
        >
            <EditPencil className="h-6 w-6 text-orange-600" />
            <span className="text-sm font-medium text-orange-700">
                {signature ? 'Modifier' : 'Signer'}
            </span>
        </button>
    )
}

export default SignatureDocument
