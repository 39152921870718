export const ESTABLISHMENT_ACTIONS = {
    GET_ESTABLISHMENT: 'getEstablishment',
    SET_ADDRESS: 'setAddress',
    UPDATE_ADDRESS_LABEL: 'updateAddressLabel',
    SET_LABEL: 'setLabel',
    SET_PHONE_NUMBER: 'setPhoneNumber',
    SET_MAIL: 'setMail',
    DELETE_ADDRESS: 'deleteAddress',
    SET_LOADER: 'setLoader',
    SET_DESCRIPTION: 'setDescription',
    REMOVE_ESTABLISHMENT: 'removeEstablishment',
    SET_SITE: 'setSite',
    SET_LABEL2: 'setLabel2',
}

export const getInitialState = () => {
    return {
        id: null,
        label: null,
        label2: null,
        site: null,
        address: null,
        addressLabel: null,
        phoneNumber: null,
        mail: null,
        loading: false,
        description: null,
    }
}

export const EstablishmentReducer = (state, action) => {
    switch (action.type) {
        case ESTABLISHMENT_ACTIONS.GET_ESTABLISHMENT:
            return {
                ...state,
                ...action.payload,
                loading: false,
            }
        case ESTABLISHMENT_ACTIONS.SET_LABEL:
            return {
                ...state,
                label: action.payload,
            }
        case ESTABLISHMENT_ACTIONS.SET_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: action.payload
                    ?.replace(/[^\dA-Z]/g, '')
                    .replace(/(.{2})/g, '$1 ')
                    .substring(0, 15)
                    .trim(),
            }
        case ESTABLISHMENT_ACTIONS.SET_MAIL:
            return {
                ...state,
                mail: action.payload,
            }
        case ESTABLISHMENT_ACTIONS.SET_LOADER:
            return {
                ...state,
                loading: action.payload,
            }
        case ESTABLISHMENT_ACTIONS.SET_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            }
        case ESTABLISHMENT_ACTIONS.SET_ADDRESS:
            return {
                ...state,
                address: action.payload,
            }
        case ESTABLISHMENT_ACTIONS.SET_LABEL2:
            return {
                ...state,
                label2: action.payload,
            }
        case ESTABLISHMENT_ACTIONS.SET_SITE:
            return {
                ...state,
                site: action.payload,
            }
        case ESTABLISHMENT_ACTIONS.REMOVE_ESTABLISHMENT:
            return getInitialState()
        default:
            throw new Error('Action non reconnue')
    }
}
