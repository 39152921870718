import React from 'react'
import RegulationListContainer from './RegulationListContainer'
import NoData from '../NoData'
import { useRunMutations, useRunsList } from '../../../hooks/useRunsList'
import { SkeletonLoaderCards } from '../../Commons/Loaders/SkeletonLoaderCards'

const Regulation = ({
    search,
    dateFrom,
    dateTo,
    type,
    page,
    setPage,
    seriesId,
    patient,
    object,
    handleErase,
    setAction,
    action,
    status,
    isSocietyTaxi,
    partner,
    user,
    shareFilter,
    transferGroup,
    updateTransferGroup,
    selectAll,
    setSelectAll,
    excludeStatus,
    societyConfig,
}) => {
    const parameters = societyConfig?.parameters || {}
    const { computerConfigurator = {}, mobileConfigurator = {} } =
        parameters || {}

    const { data, isLoading, isFetching, isError } = useRunsList({
        search,
        dateFrom,
        dateTo,
        type,
        page,
        patient,
        status,
        user,
        object,
        partner,
        shareFilter,
        excludeStatus,
        seriesId,
    })

    // Get mutations
    const { deleteRun, shareRun, removeShare } = useRunMutations()

    // Handle loading and error states
    if (isError) {
        return (
            <div className="p-4 text-center text-red-600">
                Une erreur est survenue lors du chargement des données
            </div>
        )
    }

    if (isLoading || societyConfig.isLoading || isFetching) {
        return <SkeletonLoaderCards />
    }

    const { rows = [], totalItems = 0, lastPage } = data || {}

    // Show NoData component when no results
    if (!rows.length) {
        return (
            <NoData
                message="Aucune prise en charge disponible"
                labelAction="Effacer les filtres"
                handleAction={handleErase}
                setAction={setAction}
                action={action}
                add={true}
                icon="fa-ambulance"
                link="/transport/0"
            />
        )
    }

    return (
        <RegulationListContainer
            rows={rows}
            setPage={setPage}
            totalItems={totalItems}
            loading={isFetching}
            page={page}
            lastPage={lastPage}
            isSocietyTaxi={isSocietyTaxi}
            handleDeleteElement={deleteRun.mutate}
            handleShareRun={(id, data) => shareRun.mutate({ id, data })}
            handleRemoveShare={removeShare.mutate}
            computerConfigurator={computerConfigurator.custom}
            mobileConfigurator={mobileConfigurator.custom}
            transferGroup={transferGroup}
            updateTransferGroup={updateTransferGroup}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
        />
    )
}

export default Regulation
