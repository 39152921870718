import React, { useEffect, useState } from 'react'
import API from '../../../../services/api'
import Loader from '../../../Commons/Loaders/Loader'
import NoData from '../../NoData'
import NoemieListComponent from './NoemieListComponent'
import { useQuery } from '@tanstack/react-query'
import { Noemie } from '../../../../services/API/Entities/noemie'
import { SkeletonLoaderCards } from '../../../Commons/Loaders/SkeletonLoaderCards'
import NoemieFilter from './NoemieFilter'

const NoemieList = () => {
    const [archived, setArchived] = useState(false)

    const { data, isLoading, isError } = useQuery({
        queryKey: ['noemies', archived],
        queryFn: () => Noemie.fetchAll(archived),
        select: (data) => ({
            noemies: data,
            totalItems: data.length,
        }),
    })

    if (isLoading) {
        return <SkeletonLoaderCards />
    }

    if (isError) {
        return (
            <NoData message="Une erreur s'est produite lors du chargement des caisses." />
        )
    }
    // if (data?.noemies.length === 0)
    //     return <NoData message="Aucun retour NOEMIE disponible" />

    return (
        <div>
            <NoemieFilter archived={archived} setArchived={setArchived} />
            <NoemieListComponent
                noemies={data.noemies}
                totalItems={data.totalItems}
                loading={isLoading}
            />
        </div>
    )
}

export default NoemieList
