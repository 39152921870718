import React, { useRef, useEffect } from 'react'

const SignatureViewer = ({ strokes, displayWidth = 250 }) => {
    const canvasRef = useRef(null)

    useEffect(() => {
        if (!strokes || strokes.length === 0) return

        // Calcul de la boîte englobante
        let minX = Infinity,
            minY = Infinity,
            maxX = -Infinity,
            maxY = -Infinity
        strokes.forEach((stroke) => {
            if (!stroke.points || stroke.points.length === 0) return
            stroke.points.forEach((point) => {
                minX = Math.min(minX, point.x)
                minY = Math.min(minY, point.y)
                maxX = Math.max(maxX, point.x)
                maxY = Math.max(maxY, point.y)
            })
        })

        const boxWidth = maxX - minX
        const boxHeight = maxY - minY
        if (boxWidth === 0 || boxHeight === 0) return
        // Calcul de la hauteur d'affichage en fonction de la largeur souhaitée
        const computedDisplayHeight = displayWidth * (boxHeight / boxWidth)

        // Récupérer le canvas
        const canvas = canvasRef.current
        if (!canvas) return
        // Définir les dimensions du canvas via le DOM (ne pas le définir via le JSX)
        canvas.width = displayWidth
        canvas.height = computedDisplayHeight

        const ctx = canvas.getContext('2d')
        // Remplir le canvas en blanc
        ctx.fillStyle = 'white'
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        // Paramètres de tracé
        ctx.strokeStyle = 'black'
        ctx.lineWidth = 1
        // Calcul des facteurs d'échelle
        const scaleX = canvas.width / boxWidth
        const scaleY = canvas.height / boxHeight
        // Dessiner chaque stroke
        strokes.forEach((stroke) => {
            if (!stroke.points || stroke.points.length < 2) return
            ctx.beginPath()
            ctx.moveTo(
                (stroke.points[0].x - minX) * scaleX,
                (stroke.points[0].y - minY) * scaleY
            )
            for (let i = 1; i < stroke.points.length; i++) {
                ctx.lineTo(
                    (stroke.points[i].x - minX) * scaleX,
                    (stroke.points[i].y - minY) * scaleY
                )
            }
            ctx.stroke()
        })
    }, [strokes, displayWidth])

    // Ne pas spécifier width/height ici pour éviter que le canvas soit recréé
    return <canvas ref={canvasRef} />
}

export default SignatureViewer
