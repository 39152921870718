import React, { useCallback, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import ActionModal from '../../Modal/ActionModal'
import { useLongPress } from 'use-long-press'
import TOOLS from '../../../services/tools'
import MobileOption from '../../Commons/MobileOption'
import DefaultOptions from '../../Commons/DefaultOptions'
import Loader from '../../Commons/Loaders/Loader'

const VehicleCardTable = ({
    rows,
    totalItems,
    handleDeleteElement,
    loading,
}) => {
    let history = useNavigate()
    const location = useLocation()
    const [showMobileOptions, setShowMobileOptions] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedRowId, setSelectedRowId] = useState(null)
    const [styleId, setStyleId] = useState(null)
    const [label, setLabel] = useState('')
    const [labelAction, setLabelAction] = useState('')
    const [actionButton, setActionButton] = useState('')

    const historyPush = (id) => {
        history(location.pathname + '/' + id)
    }

    const handleAction = () => {
        setModalOpen(false)
        handleDeleteElement(selectedRowId)
    }

    const handleModalDelete = (rowId) => {
        let row = rows.find((r) => r.id === rowId)
        setLabel(`
                'Êtes-vous sûr de vouloir supprimer le véhicule :
                ${row.label}  ${row.licensePlate} ? `)
        setActionButton('Supprimer')
        setLabelAction('Suppression')
        setModalOpen(true)
    }

    const optionsRef = useRef()

    TOOLS.useOnClickOutside(optionsRef, () => {
        setShowOptions(false)
        setStyleId(null)
    })

    const callback = useCallback((event) => {}, [])

    const bind = useLongPress(callback, {
        onStart: (event) => {
            setSelectedRowId(parseInt(event.currentTarget.id))
            setStyleId(parseInt(event.currentTarget.id))
        },
        onFinish: (event) => setShowMobileOptions(true),
        onCancel: (event) => {
            setSelectedRowId(null)
            setStyleId(null)
            setShowMobileOptions(false)
        },
        onMove: (event) => console.log('Detected mouse or touch movement'),
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: true,
        detect: 'touch',
    })

    return (
        <div className="px-2 text-lg lg:text-base xl:pt-5">
            <div className="flex justify-between py-2 pl-2">
                <div className="flex items-center">
                    <i className="fa fa-circle fa-lg pr-2 text-gray-300" />
                    <p>: Inactif</p>
                </div>
                <div className="font-bold lg:text-xs">
                    {totalItems} Véhicles
                </div>
            </div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    {rows.map((row) => (
                        <div
                            className="parents relative flex h-20 w-full pb-2"
                            key={row.id}
                        >
                            <div
                                key={row.id}
                                className={`grid w-full cursor-pointer  select-none rounded border-l-8 border-gray-300  shadow-mobile lg:select-auto ${
                                    !row.active ? 'bg-gray-300' : 'bg-white'
                                } ${row.id === styleId ? ' ring' : null} `}
                                id={row.id}
                                {...bind}
                                style={{ borderColor: row.color }}
                                onClick={() => historyPush(row.id)}
                            >
                                <div
                                    className={` parents row-span-1 flex grid-rows-1  flex-wrap items-center justify-between px-2 py-2 text-sm lg:text-base`}
                                >
                                    <div
                                        className="w-1/2 truncate sm:w-1/4 "
                                        title={row.licensePlate}
                                    >
                                        {row.licensePlate}
                                    </div>
                                    <div
                                        className="w-1/2 truncate sm:w-1/4 "
                                        title={row.label}
                                    >
                                        {row.label}
                                    </div>
                                    <div
                                        className=" w-1/2 truncate sm:w-1/4 "
                                        title={row.type.label}
                                    >
                                        <span
                                            className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-${
                                                row.type.label === 'VSL'
                                                    ? 'blue-100 text-blue-800'
                                                    : row.type.label ===
                                                      'Ambulance'
                                                    ? 'red-100 text-red-600'
                                                    : 'orange-100 text-orange-600'
                                            }`}
                                        >
                                            {row.type.label}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className={`absolute right-0 hidden h-10 cursor-pointer rounded pt-2 xl:block   ${
                                        row.id === selectedRowId
                                            ? null
                                            : 'options '
                                    } `}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setShowOptions(true)
                                        setSelectedRowId(row.id)
                                        setStyleId(row.id)
                                    }}
                                >
                                    <i
                                        className={`fas fa-ellipsis-h w-20 pr-4 pt-1 text-right ${
                                            showOptions ? 'hidden' : ''
                                        } `}
                                    />
                                    {row.id === selectedRowId && showOptions ? (
                                        <DefaultOptions
                                            setShowMobileOptions={
                                                setShowOptions
                                            }
                                            rowId={selectedRowId}
                                            handleModalDelete={
                                                handleModalDelete
                                            }
                                            historyPush={historyPush}
                                            historyPushUpdate={historyPush}
                                            optionsRef={optionsRef}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {showMobileOptions ? (
                <MobileOption
                    setShowMobileOptions={setShowMobileOptions}
                    rowId={selectedRowId}
                    handleModalDelete={handleModalDelete}
                    historyPush={historyPush}
                    historyPushUpdate={historyPush}
                    historyPushCopy={null}
                    setStyleId={setStyleId}
                />
            ) : null}
            {modalOpen ? (
                <ActionModal
                    setModalOpen={setModalOpen}
                    label={label}
                    action={handleAction}
                    labelAction={labelAction}
                    actionButton={actionButton}
                />
            ) : null}
        </div>
    )
}

export default VehicleCardTable
