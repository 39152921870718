import * as dayjs from 'dayjs'
import DateInput from '../../../Commons/Inputs/DateInput'
import TimeField from 'react-simple-timefield'
import React from 'react'
import { SERIES_ACTIONS } from '../../../../services/Reducers/SeriesReducer'
import { ArrowRight, Trademark, Xmark, Lock } from 'iconoir-react'
import WaitingTimeInput from '../../../Commons/Inputs/WaitingTimeInput'
import classNames from 'classnames'
import { getBorderColor } from '../../../../services/RunStatus'
import SelectDropDownListV3 from '../../../Commons/DropDownLists/SelectDropDownListV3'
import { useNavigate } from 'react-router-dom'
import {
    formatTimeInput,
    handleTimeToDate,
} from '../../../../services/dateUtils'

const CardSeriesRun = ({ teams, run, index, dispatch, isArtisan = false }) => {
    const navigate = useNavigate()
    const handleSeriesRunsTime = (value, timeType) => {
        if (timeType === 'departureTime') {
            dispatch({
                type: SERIES_ACTIONS.SET_RUN_TO_INSERT_DEPARTURE_TIME,
                payload: { value: handleTimeToDate(value), key: run.key },
            })
        } else if (timeType === 'arrivingTime') {
            dispatch({
                type: SERIES_ACTIONS.SET_RUN_TO_INSERT_ARRIVING_TIME,
                payload: { value: handleTimeToDate(value), key: run.key },
            })
        } else {
            dispatch({
                type: SERIES_ACTIONS.SET_RUN_TO_INSERT_RETURN_TIME,
                payload: { value: handleTimeToDate(value), key: run.key },
            })
        }
    }

    const handleSeriesRunsDateString = (event) => {
        let dateString
        if (event.target) {
            dateString = event.target.value
        } else {
            dateString = event
        }

        if (dayjs(dateString, 'DD/MM/YYYY').isValid()) {
            const newDate = new Date(dayjs(dateString, 'DD/MM/YYYY'))
            dispatch({
                type: SERIES_ACTIONS.SET_RUN_TO_INSERT_DATE,
                payload: {
                    newDate: newDate,
                    key: run.key,
                    dateString: dateString,
                },
            })
        }
    }

    const returnCanceledRunsMessage = () => {
        let msg = ''

        const isCanceled = (status) => status && status === 'canceled'

        if (run.isRoundTrip) {
            const isReturnCanceled = isCanceled(run.returnStatus)
            const isRunCanceled = isCanceled(run.status)

            if (isReturnCanceled && isRunCanceled) {
                msg = 'Transports aller et retour annulés.'
            } else if (isReturnCanceled) {
                msg = 'Transport retour annulé.'
            } else if (isRunCanceled) {
                msg = 'Transport aller annulé.'
            }
        } else if (isCanceled(run.status)) {
            msg = 'Transport annulé.'
        }

        return msg ? <div className="text-sm text-red-500">{msg}</div> : null
    }
    const returnSharedRunsMessage = () => {
        let msg = ''

        if (run.isRoundTrip) {
            if (run.runSharedAndGiven && run.returnRunSharedAndGiven) {
                msg = 'Transports aller et retour donné.'
            } else if (run.runSharedAndGiven) {
                msg = 'Transport aller donné.'
            } else if (run.returnRunSharedAndGiven) {
                msg = 'Transport retour donné.'
            }
        } else if (run.runSharedAndGiven) {
            msg = 'Transport donné.'
        }
        return msg ? <div className="text-sm">{msg}</div> : null
    }

    const getReturnBorderColor = (run) => {
        if (!run.isRoundTrip) return

        return getBorderColor(
            run.returnStatus ? run.returnStatus : 'todo',
            'right'
        )
    }

    const runLocked = run?.status !== 'todo'
    const handleNavigation = (run) => {
        if (run.id) navigate(`/transport/${run.id}`)
    }

    return (
        <div
            className={classNames(
                'border-r-amber-50- relative w-full space-x-2 rounded-lg border-l-4 border-r-4 bg-white px-2 py-1 text-lg shadow-mobile lg:items-center lg:text-base',
                getBorderColor(run.status ? run.status : 'todo', 'left'),
                getReturnBorderColor(run),
                {
                    stripeBg: run.partner && run.isGiven,
                }
            )}
            key={index}
        >
            {runLocked && (
                <div className="absolute right-0 top-0">
                    <Lock />
                </div>
            )}
            <div className="grid grid-cols-2 py-2">
                <div className="col-span-1 flex items-center text-sm lg:pr-5">
                    <button
                        className="pr-2"
                        onClick={() => handleNavigation(run)}
                    >
                        <span className="rounded-md border-2 border-secondary-color bg-white px-2 py-1 font-semibold text-gray-800">
                            {index + 1}.
                        </span>
                    </button>
                    <div className="capitalize">
                        {' '}
                        {dayjs(run.date).format('ddd')}
                    </div>
                    <DateInput
                        date={run.dateString}
                        handleDate={(e) => handleSeriesRunsDateString(e)}
                        showLabel={true}
                        error={run.errorDate}
                        showIcon={false}
                        disabled={index === 0}
                    />
                </div>
                <div className="col-span-1 flex items-center pr-10">
                    <TimeField
                        value={formatTimeInput(run.departureTime)}
                        onChange={(event, value) =>
                            handleSeriesRunsTime(value, 'departureTime')
                        }
                        input={
                            <input
                                className={`max-w-hours h-10 w-full border-b-2 text-center leading-tight focus:outline-none`}
                                disabled={runLocked}
                            />
                        }
                        colon=":"
                    />
                    <div className="flex items-center justify-center px-3 pt-3 ">
                        <ArrowRight />
                    </div>
                    <TimeField
                        value={formatTimeInput(run.arrivingTime)}
                        input={
                            <input
                                className={`max-w-hours h-10  w-full border-b-2 text-center leading-tight text-gray-700 focus:outline-none ${
                                    run.arrivingTimeError && 'border-red-500'
                                }`}
                                disabled={runLocked}
                            />
                        }
                        onChange={(event, value) =>
                            handleSeriesRunsTime(value, 'arrivingTime')
                        }
                        colon=":"
                    />
                    {run.isReturnPath && (
                        <div className="absolute right-0 top-0 pr-2 pt-1 lg:pr-3 ">
                            <Trademark />
                        </div>
                    )}
                </div>
            </div>
            {run.returnTime && run.haveIndirectReturn && (
                <div className="grid grid-cols-2">
                    <div className="col-span-1 flex w-full items-center py-2">
                        <Trademark className="mr-3 lg:flex" />
                        <div className="flex w-1/3 items-end">
                            <TimeField
                                value={formatTimeInput(run.returnTime)}
                                input={
                                    <input
                                        className={`max-w-date h-10 border-b-2 text-center leading-tight focus:outline-none ${
                                            run.returnTimeError &&
                                            'border-red-500'
                                        }`}
                                        disabled={runLocked}
                                    />
                                }
                                onChange={(event, value) =>
                                    handleSeriesRunsTime(value, 'returnTime')
                                }
                                colon=":"
                            />
                        </div>
                    </div>
                    {!isArtisan ? (
                        <div className="col-span-1 w-3/4">
                            <SelectDropDownListV3
                                placeholder={'Équipe'}
                                options={teams}
                                value={run.team}
                                handleOptionChange={(e) => {
                                    dispatch({
                                        type: SERIES_ACTIONS.SET_RUN_TO_INSERT_TEAM,
                                        payload: {
                                            key: run.key,
                                            value: e,
                                        },
                                    })
                                }}
                                isDisabled={run.isRoundTripReturn || runLocked}
                                isClearable={true}
                                isSearchable={false}
                                disabled={runLocked}
                            />
                        </div>
                    ) : null}
                </div>
            )}
            {run.haveDirectReturn && (
                <div className="flex w-full flex-row items-center">
                    <div className="inline-block w-1/3">
                        <WaitingTimeInput
                            label="Temps d'attente"
                            value={run.waitingTime}
                            type="number"
                            required={true}
                            setValue={(e) =>
                                dispatch({
                                    type: SERIES_ACTIONS.SET_RUN_TO_INSERT_WAITING_TIME,
                                    payload: {
                                        key: run.key,
                                        value: parseInt(e.target.value),
                                    },
                                })
                            }
                            placeholder="20"
                            id="waitingtime"
                            showLabel={true}
                            min={0}
                            disabled={runLocked}
                        />
                    </div>
                    <div className="flex w-1/2">
                        <SelectDropDownListV3
                            placeholder={'Équipe'}
                            options={teams}
                            value={run.team}
                            handleOptionChange={(e) => {
                                dispatch({
                                    type: SERIES_ACTIONS.SET_RUN_TO_INSERT_TEAM,
                                    payload: {
                                        key: run.key,
                                        value: e,
                                    },
                                })
                            }}
                            isDisabled={run.isRoundTripReturn || runLocked}
                            isClearable={true}
                            isSearchable={false}
                        />
                    </div>
                </div>
            )}
            <div className="flex">
                {returnCanceledRunsMessage()}
                {returnSharedRunsMessage()}
            </div>
            {!run.haveIndirectReturn && !run.haveDirectReturn && (
                <div className="flex w-1/2 pb-1">
                    <SelectDropDownListV3
                        placeholder={'Équipe'}
                        options={teams}
                        value={run.team}
                        handleOptionChange={(e) => {
                            dispatch({
                                type: SERIES_ACTIONS.SET_RUN_TO_INSERT_TEAM,
                                payload: {
                                    key: run.key,
                                    value: e,
                                },
                            })
                        }}
                        isDisabled={run.isRoundTripReturn || runLocked}
                        isClearable={true}
                        isSearchable={false}
                    />
                </div>
            )}
            {run.error && (
                <div className="text-sm text-red-500">{run.error}</div>
            )}
            {index !== 0 && !runLocked && (
                <div
                    className="absolute bottom-0 right-0 cursor-pointer px-2 text-xl lg:text-lg"
                    title="Supprimer le transport"
                    onClick={() =>
                        dispatch({
                            type: SERIES_ACTIONS.DELETE_RUN_TO_INSERT,
                            payload: run,
                        })
                    }
                >
                    <Xmark classname="ml-auto pr-2" />
                </div>
            )}
        </div>
    )
}

export default CardSeriesRun
