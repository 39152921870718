import React, { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { OneFingerSelectHandGesture, Plus, SendDiagonal } from 'iconoir-react'
import ClassicInput from '../../../Commons/Inputs/ClassicInput'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import ClassicSwitch from '../../../Commons/Inputs/ClassicSwitch'
import API from '../../../../services/api'
import { Lot } from '../../../../services/API/Entities/lot'

const LotFilter = ({
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    search,
    setSearch,
    transferGroup,
    setTransferGroup,
    setFirstLoading,
    archived,
    setArchived,
    setOpenFilterModal,
    setIsReloading,
    isReloading,
}) => {
    const [transferLabel, setTransferLabel] = useState('Télétransmettre')
    const navigate = useNavigate()
    const location = useLocation()
    const queryClient = useQueryClient()

    const isTransferActive = location.search.includes('?transfer=')

    // Define mutation for transferring CPAM data
    const transferMutation = useMutation({
        mutationFn: (data) => Lot.transfer(data),
        onMutate: () => {
            setFirstLoading(true)
            setIsReloading(true)
        },
        onSuccess: async (response) => {
            if (response.ok) {
                navigate({
                    pathname: '/facturation/lots',
                    search: null,
                })
                setTransferGroup([])
                toast.success('Les lots ont été transférés')

                // Invalidate relevant queries
                await queryClient.invalidateQueries({
                    queryKey: ['invoices'],
                })
                await queryClient.invalidateQueries({ queryKey: ['lots'] })
            }
        },
        onError: async (error) => {
            console.log(error)
            if (error.json) {
                const json = await error.json()
                if (json) {
                    console.error(json)
                    toast.error(json.error)
                }
            } else {
                toast.error('Une erreur est survenue lors du transfert')
            }
        },
        onSettled: () => {
            setFirstLoading(false)
            setIsReloading(false)
        },
    })

    const handleTransferCPAM = () => {
        setTransferLabel('Sélection')

        if (isTransferActive && transferGroup.length > 0) {
            // Check if each element of transferGroup has the same payingCenterId
            const payingCenterId = transferGroup[0].payingCenter['@id']
            const isSamePayingCenter = transferGroup.every(
                (element) => element.payingCenter['@id'] === payingCenterId
            )

            if (isSamePayingCenter) {
                const data = {
                    lots: transferGroup.map((element) => element['@id']),
                    payingCenter: payingCenterId,
                }
                transferMutation.mutate(data)
            } else {
                toast.error(
                    'Les lots sélectionnés ne sont pas du même centre payeur'
                )
            }
        } else {
            navigate({
                pathname: '/facturation/lots',
                search: '?transfer=true',
            })
        }
        setTransferGroup([])
    }

    return (
        <div className="flex grid grid-cols-2 items-center justify-between gap-4 px-2 pb-2 pt-2 lg:grid-cols-4">
            <div className="col-span-1 flex items-center justify-center">
                <i className="fa fa-search pr-2" />
                <ClassicInput
                    label="Rechercher"
                    value={search}
                    setValue={(e) => setSearch(e.target.value)}
                    id="search"
                    placeholder="Rechercher..."
                />
            </div>
            <div className="col-span-1 flex justify-center">
                <ClassicSwitch
                    label="Archives"
                    value={archived}
                    setValue={() => setArchived(!archived)}
                    info={true}
                    infoLabel="Afficher la liste des lots archivées"
                />
            </div>
            <div>
                {!isTransferActive ? (
                    <PrimaryButton
                        icon={<SendDiagonal />}
                        action={handleTransferCPAM}
                        customIconClass="pr-2"
                        disabled={isReloading || transferMutation.isPending}
                        label="Télétransmettre"
                        hiddenLabelOnMobile={false}
                    />
                ) : transferGroup.length === 0 ? (
                    <SecondaryButton
                        icon={<OneFingerSelectHandGesture />}
                        action={handleTransferCPAM}
                        label={transferLabel}
                        disabled={isReloading || transferMutation.isPending}
                        active={isTransferActive}
                        hiddenLabelOnMobile={false}
                    />
                ) : (
                    <PrimaryButton
                        icon={<SendDiagonal />}
                        customIconClass="pr-2"
                        action={handleTransferCPAM}
                        label="Valider et transférer"
                        disabled={isReloading || transferMutation.isPending}
                        hiddenLabelOnMobile={false}
                    />
                )}
            </div>
            <div className="col-span-1 col-start-2 flex justify-center lg:col-start-auto lg:justify-end">
                <PrimaryButton
                    label="Nouveau lot"
                    title="Nouveau lot"
                    action={() => navigate('/facturation/lots/0')}
                    icon={<Plus className="text-xl" />}
                    hiddenLabelOnMobile={true}
                />
            </div>
        </div>
    )
}

export default LotFilter
