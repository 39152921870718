import React, { useEffect, useReducer } from 'react'
import FreeInvoiceForm from './FreeInvoiceForm'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../Commons/Loaders/Loader'
import SendFormButtons from '../../../Commons/Buttons/SendFormButtons'
import {
    FreeInvoice,
    handleFreeInvoiceForm,
} from '../../../../services/API/Entities/freeInvoice'
import NoData from '../../NoData'
import {
    FREE_INVOICE_ACTIONS,
    FreeInvoiceReducer,
    getFreeInvoiceInitialState,
} from '../../../../services/Reducers/FreeInvoiceReducer'
import {
    getClientFormInitialState,
    PATIENT_FORM_ACTIONS,
    PatientFormReducer,
} from '../../Regulation/Reducers/PatientFormReducer'
import {
    ESTABLISHMENT_ACTIONS,
    EstablishmentReducer,
    getInitialState,
} from '../../../../services/Reducers/EstablishmentReducer'
import { Society } from '../../../../services/API/Entities/society'
import { transformObjectsIntoIris } from '../../../../tools/Utility'
import { formatDateYYYYMMDD } from '../../../../services/dateUtils'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'

export const getTotalHT = (freeInvoiceLines) => {
    return freeInvoiceLines.reduce((acc, item) => {
        const quantity = parseFloat(item.quantity) || 0
        const unitPrice = parseFloat(item.unitPrice) || 0
        const discount = parseFloat(item.discount) || 0
        const priceBeforeDiscount = unitPrice * quantity
        const priceAfterDiscount = priceBeforeDiscount * (1 - discount / 100)
        return acc + priceAfterDiscount
    }, 0)
}

export const getTotalTTC = (freeInvoiceLines) => {
    return freeInvoiceLines.reduce((acc, item) => {
        const totalPrice = parseFloat(item.totalPrice) || 0
        return acc + totalPrice
    }, 0)
}

export const totalTax = (freeInvoiceLines) => {
    return parseFloat(
        getTotalTTC(freeInvoiceLines) - getTotalHT(freeInvoiceLines) || 0
    )
}

export const tvaGroups = (lines) =>
    lines.reduce((acc, item) => {
        const rate = parseFloat(item.vat) || 0
        const quantity = parseFloat(item.quantity) || 0
        const unitPrice = parseFloat(item.unitPrice) || 0
        const totalPrice = parseFloat(item.totalPrice) || 0
        const discount = parseFloat(item.discount) || 0

        if (!acc[rate]) {
            acc[rate] = 0
        }

        // Calculate price before VAT but after discount
        const priceBeforeDiscount = unitPrice * quantity
        const priceAfterDiscount = priceBeforeDiscount * (1 - discount / 100)

        // TVA amount is the difference between total price and price after discount
        acc[rate] += totalPrice - priceAfterDiscount

        return acc
    }, {})

const FreeInvoiceWrapper = () => {
    const navigate = useNavigate()
    const location = useLocation()
    let { id } = useParams()
    const queryClient = useQueryClient()

    const urlParams = new URLSearchParams(location.search)
    const isCopy = urlParams.get('copy')
    const isInvoiced = urlParams.get('invoice')
    const isQuote = location.pathname.includes('devis')
    let idToGet = isCopy || (id != 0 ? id : null)
    if (idToGet) {
        id = idToGet
    }

    const {
        data: freeInvoice,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['freeInvoice', id],
        queryFn: () => FreeInvoice.fetchOne(id),
        enabled: !!id && id !== '0',
        retry: false,
    })

    const {
        data: society,
        isLoading: isLoadingSociety,
        isError: isErrorSociety,
    } = useQuery({
        queryKey: ['society'],
        queryFn: () =>
            Society.getSociety(
                localStorage.getItem('society').split('/').reverse()[0]
            ),
        retry: false,
    })

    const [freeInvoiceForm, dispatch] = useReducer(
        FreeInvoiceReducer,
        { isQuote },
        getFreeInvoiceInitialState
    )

    const { mutate: freeInvoiceMutation, isPending: isPending } = useMutation({
        mutationFn: (data) => handleFreeInvoiceForm(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['freeInvoice', id] })
            navigate(`/facturation/${!isQuote ? 'factures' : 'devis'}`)
        },
    })

    const handleFreeInvoiceModalForm = async () => {
        if (freeInvoiceForm.establishment || freeInvoiceForm.patient) {
            console.log(freeInvoiceForm.date)
            const data = {
                ...freeInvoiceForm,
                date: formatDateYYYYMMDD(freeInvoiceForm.date),
                establishment: transformObjectsIntoIris(
                    freeInvoiceForm.establishment
                ),
                paidAt:
                    freeInvoiceForm.paidAt?.length === 10
                        ? formatDateYYYYMMDD(
                              dayjs(freeInvoiceForm.paidAt, 'DD/MM/YYYY')
                          )
                        : null,
                patient: transformObjectsIntoIris(freeInvoiceForm.patient),
                totalIncludingTaxes: getTotalTTC(
                    freeInvoiceForm.freeInvoiceLines
                ),
                totalExcludingTaxes: getTotalHT(
                    freeInvoiceForm.freeInvoiceLines
                ),
                discount: freeInvoiceForm.freeInvoiceLines.reduce(
                    (acc, item) => acc + parseFloat(item.discount),
                    0 || 0
                ),
                totalVAT: totalTax(freeInvoiceForm.freeInvoiceLines),
                paymentMethod: freeInvoiceForm.paymentMethod?.label,
                freeInvoiceLines: freeInvoiceForm.freeInvoiceLines.map(
                    (line) => ({
                        ...line,
                        unitPrice: parseFloat(line.unitPrice),
                        quantity: parseFloat(line.quantity),
                        vat: parseFloat(line.vat),
                        discount: parseFloat(line.discount),
                        totalPrice: parseFloat(line.totalPrice),
                    })
                ),
            }
            freeInvoiceMutation(data)
        } else {
            toast.error('Veuillez renseigner un patient ou un établissement')
        }
    }

    const [patientForm, patientDispatch] = useReducer(
        PatientFormReducer,
        { undefined },
        getClientFormInitialState
    )

    const [establishmentForm, establishmentDispatch] = useReducer(
        EstablishmentReducer,
        { undefined },
        getInitialState
    )

    useEffect(() => {
        if (freeInvoice && society) {
            if (isCopy) {
                if (isInvoiced) {
                    dispatch({
                        type: FREE_INVOICE_ACTIONS.FETCH_QUOTE_TO_INVOICE,
                        payload: freeInvoice,
                    })
                } else {
                    dispatch({
                        type: FREE_INVOICE_ACTIONS.FETCH_FREE_INVOICE_COPY,
                        payload: freeInvoice,
                    })
                }
            } else {
                dispatch({
                    type: FREE_INVOICE_ACTIONS.FETCH_FREE_INVOICE,
                    payload: freeInvoice,
                })
            }
            if (freeInvoice.patient) {
                patientDispatch({
                    type: PATIENT_FORM_ACTIONS.GET_PATIENT,
                    payload: freeInvoice.patient,
                })
            }
            if (freeInvoice.establishment) {
                establishmentDispatch({
                    type: ESTABLISHMENT_ACTIONS.GET_ESTABLISHMENT,
                    payload: freeInvoice.establishment,
                })
            }
        }
        if (society) {
            dispatch({
                type: FREE_INVOICE_ACTIONS.SET_SOCIETY_INFO,
                payload: society,
            })
        }
    }, [freeInvoice, society, isCopy, isInvoiced])

    if (isLoading || isLoadingSociety) {
        return <Loader />
    }

    if (isError || isErrorSociety) {
        return <NoData message="Une erreur s'est produite." />
    }

    return (
        <>
            <FreeInvoiceForm
                dispatch={dispatch}
                freeInvoiceForm={freeInvoiceForm}
                patientForm={patientForm}
                patientDispatch={patientDispatch}
                establishmentForm={establishmentForm}
                establishmentDispatch={establishmentDispatch}
            />
            <SendFormButtons
                handleForm={handleFreeInvoiceModalForm}
                buttonLabel={`${!freeInvoiceForm.id ? 'Créer ' : 'Modifier '} ${
                    !freeInvoiceForm.isInvoice ? 'le devis' : 'la facture'
                }`}
                loader={isPending}
            />
        </>
    )
}

export default FreeInvoiceWrapper
