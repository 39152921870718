import { RenderInvoiceId } from '../../../../../services/InvoiceUtils'
import { PagePlus, EditPencil, MailIn, SendDiagonal } from 'iconoir-react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { formatTimeLocal } from '../../../../../services/dateUtils'

export const InvoiceFormDetails = ({ invoice }) => {
    const b2FileId =
        invoice.cpamInvoiceGroup?.B2File?.id ||
        invoice.cpamInvoiceGroup?.B2File?.['@id']?.split('/')?.pop()

    const navigate = useNavigate()
    return (
        <div
            className={
                ' flex divide-x-2 rounded-lg border-2 bg-white p-4 shadow-md'
            }
        >
            <div className={'flex w-1/2 flex-col space-y-4 '}>
                <div className={'flex items-center '}>
                    <p className={'text-blue-500'}>
                        <RenderInvoiceId
                            invoiceId={invoice.cpamInvoiceId}
                            label={true}
                        />
                    </p>
                </div>
                <div className={'flex items-center'}>
                    <PagePlus className={'mr-2'} />
                    {dayjs(invoice.createdAt).format('DD/MM/YY à HH:mm')}
                </div>
                {invoice.updatedAt && (
                    <div className={'flex items-center'}>
                        <EditPencil className={'mr-2'} />
                        {dayjs(invoice.updatedAt).format('DD/MM/YY à HH:mm')}
                    </div>
                )}
            </div>
            <div className={'flex w-1/2 flex-col space-y-4 pl-2'}>
                {invoice.cpamInvoiceGroup?.B2File && (
                    <button
                        onClick={() =>
                            navigate(`/facturation/noemie/${b2FileId}`)
                        }
                        className={
                            'mr-auto font-semibold text-secondary-color underline hover:text-primary-color'
                        }
                    >
                        Noemie lot N° {invoice.cpamInvoiceGroup.groupId}
                    </button>
                )}

                {invoice.cpamInvoiceGroup?.B2File && (
                    <div className={'flex items-center'}>
                        <SendDiagonal className={'mr-2'} />
                        {dayjs(
                            invoice.cpamInvoiceGroup.B2File.createdAt
                        ).format('DD/MM/YY à HH:mm')}
                    </div>
                )}
                {invoice.cpamInvoiceGroup?.B2File?.noemieReceivedAt && (
                    <div className={'flex items-center'}>
                        <MailIn className={'mr-2'} />
                        {dayjs(
                            invoice.cpamInvoiceGroup.B2File.noemieReceivedAt
                        ).format('DD/MM/YY à HH:mm')}
                    </div>
                )}
            </div>
        </div>
    )
}
