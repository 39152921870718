import React, { useRef, useState, useEffect } from 'react'
import {
    Sphere,
    Refresh,
    Camera,
    FloppyDisk,
    ArrowLeft,
    Erase,
} from 'iconoir-react'

const Scanner = ({ imageSrc, onBack, loadedOpenCV, saveUrl }) => {
    const [originalImg, setOriginalImg] = useState(null)
    const [originalImageDimensions, setOriginalImageDimensions] = useState(null)
    const [selectedPoints, setSelectedPoints] = useState([])
    const [initializedContour, setInitializedContour] = useState(false)
    const [showResult, setShowResult] = useState(false)
    const [extractedImageUrl, setExtractedImageUrl] = useState(null)

    // États pour le déplacement des points et le viseur
    const [isDragging, setIsDragging] = useState(false)
    const [draggedPointIndex, setDraggedPointIndex] = useState(null)
    const [magnifierVisible, setMagnifierVisible] = useState(false)
    const [magnifierPosition, setMagnifierPosition] = useState({ x: 0, y: 0 })

    // Références vers les canvas
    const sourceCanvasRef = useRef(null)
    const resultCanvasRef = useRef(null)
    const magnifierCanvasRef = useRef(null)

    // Gestion des étapes : "selection" → "extraction" → "sauvegarde"
    const [currentStep, setCurrentStep] = useState('selection')
    const steps = ['selection', 'extraction', 'sauvegarde']
    const stepIndex = steps.indexOf(currentStep)

    // --- Chargement de l'image et dessin sur le canvas source ---
    useEffect(() => {
        if (!imageSrc) return
        const img = new Image()
        img.src = imageSrc
        img.crossOrigin = 'anonymous'
        img.onload = () => {
            setOriginalImg(img)
            setOriginalImageDimensions({ width: img.width, height: img.height })
            const canvas = sourceCanvasRef.current
            if (canvas) {
                canvas.width = img.width
                canvas.height = img.height
                const ctx = canvas.getContext('2d')
                ctx.clearRect(0, 0, canvas.width, canvas.height)
                ctx.drawImage(img, 0, 0, img.width, img.height)
            }
        }
    }, [imageSrc])

    // --- Calcul automatique du contour une fois l'image et OpenCV chargés ---
    useEffect(() => {
        if (originalImg && loadedOpenCV && !initializedContour) {
            console.log(loadedOpenCV)
            const computedPoints = computePaperContour()
            if (computedPoints.length === 4) {
                setSelectedPoints(computedPoints)
            }
            setInitializedContour(true)
        }
    }, [originalImg, loadedOpenCV, initializedContour])

    // --- Redessiner le canvas source avec l'image originale et l'overlay ---
    useEffect(() => {
        const canvas = sourceCanvasRef.current
        if (!canvas || !originalImageDimensions || !originalImg) return
        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(originalImg, 0, 0, canvas.width, canvas.height)

        if (selectedPoints.length > 0) {
            // Tracé en pointillé blanc
            ctx.beginPath()
            ctx.strokeStyle = 'white'
            ctx.lineWidth = 4
            ctx.setLineDash([10, 10])
            ctx.moveTo(selectedPoints[0][0], selectedPoints[0][1])
            selectedPoints.forEach(([x, y], index) => {
                if (index > 0) ctx.lineTo(x, y)
            })
            if (selectedPoints.length === 4) {
                ctx.lineTo(selectedPoints[0][0], selectedPoints[0][1])
            }
            ctx.stroke()

            // Tracé rouge semi-transparent par-dessus
            ctx.beginPath()
            ctx.strokeStyle = 'rgba(255, 0, 0, 0.5)'
            ctx.lineWidth = 2
            ctx.setLineDash([])
            ctx.moveTo(selectedPoints[0][0], selectedPoints[0][1])
            selectedPoints.forEach(([x, y], index) => {
                if (index > 0) ctx.lineTo(x, y)
            })
            if (selectedPoints.length === 4) {
                ctx.lineTo(selectedPoints[0][0], selectedPoints[0][1])
            }
            ctx.stroke()

            // Affichage des points avec numéro
            selectedPoints.forEach(([x, y], index) => {
                // Cercle extérieur blanc
                ctx.beginPath()
                ctx.arc(x, y, 20, 0, 2 * Math.PI)
                ctx.fillStyle = 'white'
                ctx.fill()

                // Cercle intérieur rouge
                ctx.beginPath()
                ctx.arc(x, y, 15, 0, 2 * Math.PI)
                ctx.fillStyle = 'red'
                ctx.fill()

                // Numéro du point
                ctx.font = 'bold 40px Arial'
                ctx.fillStyle = 'white'
                ctx.textAlign = 'center'
                ctx.textBaseline = 'middle'
                ctx.fillText((index + 1).toString(), x, y)
            })
        }
    }, [selectedPoints, originalImg, originalImageDimensions])

    // --- Calcul du contour du papier à partir du canvas source ---
    const computePaperContour = () => {
        if (!loadedOpenCV || !sourceCanvasRef.current || !window.cv) return []
        const cv = window.cv
        const src = cv.imread(sourceCanvasRef.current)
        const contour = findPaperContour(src)
        let points = []
        if (contour) {
            const {
                topLeftCorner,
                topRightCorner,
                bottomRightCorner,
                bottomLeftCorner,
            } = getCornerPoints(contour)
            if (
                topLeftCorner &&
                topRightCorner &&
                bottomRightCorner &&
                bottomLeftCorner
            ) {
                points = [
                    [topLeftCorner.x, topLeftCorner.y],
                    [topRightCorner.x, topRightCorner.y],
                    [bottomRightCorner.x, bottomRightCorner.y],
                    [bottomLeftCorner.x, bottomLeftCorner.y],
                ]
            }
        }
        src.delete()
        return points
    }

    const findPaperContour = (img) => {
        if (!window.cv) return null
        const cv = window.cv
        const imgGray = new cv.Mat()
        cv.cvtColor(img, imgGray, cv.COLOR_RGBA2GRAY)
        cv.GaussianBlur(
            imgGray,
            imgGray,
            new cv.Size(3, 3),
            0,
            0,
            cv.BORDER_DEFAULT
        )
        cv.Canny(imgGray, imgGray, 50, 200)
        const imgThresh = new cv.Mat()
        cv.threshold(imgGray, imgThresh, 0, 255, cv.THRESH_OTSU)
        const contours = new cv.MatVector()
        const hierarchy = new cv.Mat()
        cv.findContours(
            imgThresh,
            contours,
            hierarchy,
            cv.RETR_CCOMP,
            cv.CHAIN_APPROX_SIMPLE
        )
        let maxArea = 0
        let maxContourIndex = -1
        for (let i = 0; i < contours.size(); i++) {
            const cnt = contours.get(i)
            const area = cv.contourArea(cnt)
            if (area > maxArea) {
                maxArea = area
                maxContourIndex = i
            }
        }
        const maxContour =
            maxContourIndex >= 0 ? contours.get(maxContourIndex) : null
        imgGray.delete()
        imgThresh.delete()
        contours.delete()
        hierarchy.delete()
        return maxContour
    }

    const distance = (p1, p2) => Math.hypot(p1.x - p2.x, p1.y - p2.y)

    const getCornerPoints = (contour) => {
        const cv = window.cv
        const rect = cv.minAreaRect(contour)
        const center = rect.center
        let topLeftCorner = null,
            topRightCorner = null,
            bottomLeftCorner = null,
            bottomRightCorner = null
        let topLeftCornerDist = -Infinity,
            topRightCornerDist = -Infinity,
            bottomLeftCornerDist = -Infinity,
            bottomRightCornerDist = -Infinity
        for (let i = 0; i < contour.data32S.length; i += 2) {
            const point = { x: contour.data32S[i], y: contour.data32S[i + 1] }
            const d = distance(point, center)
            if (point.x < center.x && point.y < center.y) {
                if (d > topLeftCornerDist) {
                    topLeftCorner = point
                    topLeftCornerDist = d
                }
            } else if (point.x > center.x && point.y < center.y) {
                if (d > topRightCornerDist) {
                    topRightCorner = point
                    topRightCornerDist = d
                }
            } else if (point.x < center.x && point.y > center.y) {
                if (d > bottomLeftCornerDist) {
                    bottomLeftCorner = point
                    bottomLeftCornerDist = d
                }
            } else if (point.x > center.x && point.y > center.y) {
                if (d > bottomRightCornerDist) {
                    bottomRightCorner = point
                    bottomRightCornerDist = d
                }
            }
        }
        return {
            topLeftCorner,
            topRightCorner,
            bottomRightCorner,
            bottomLeftCorner,
        }
    }

    const sortPoints = (points) => {
        const center = points.reduce(
            (acc, point) => ({
                x: acc.x + point[0] / 4,
                y: acc.y + point[1] / 4,
            }),
            { x: 0, y: 0 }
        )
        return points.sort((a, b) => {
            const angleA = Math.atan2(a[1] - center.y, a[0] - center.x)
            const angleB = Math.atan2(b[1] - center.y, b[0] - center.x)
            return angleA - angleB
        })
    }

    const updateMagnifier = (pointerX, pointerY) => {
        const magnifierSize = 150 // diamètre du viseur
        const zoomFactor = 1
        if (!originalImg || !originalImageDimensions) return

        const regionWidth = magnifierSize / zoomFactor
        const regionHeight = magnifierSize / zoomFactor

        let sx = pointerX - regionWidth / 2
        let sy = pointerY - regionHeight / 2
        sx = Math.max(0, Math.min(sx, originalImg.width - regionWidth))
        sy = Math.max(0, Math.min(sy, originalImg.height - regionHeight))

        const mCanvas = magnifierCanvasRef.current
        if (!mCanvas) return
        mCanvas.width = magnifierSize
        mCanvas.height = magnifierSize
        const mCtx = mCanvas.getContext('2d')

        mCtx.clearRect(0, 0, mCanvas.width, mCanvas.height)
        mCtx.drawImage(
            originalImg,
            sx,
            sy,
            regionWidth,
            regionHeight,
            0,
            0,
            magnifierSize,
            magnifierSize
        )

        mCtx.strokeStyle = 'red'
        mCtx.lineWidth = 2
        mCtx.beginPath()
        mCtx.arc(
            magnifierSize / 2,
            magnifierSize / 2,
            magnifierSize / 2 - 1,
            0,
            2 * Math.PI
        )
        mCtx.stroke()

        mCtx.fillStyle = 'red'
        mCtx.beginPath()
        mCtx.arc(magnifierSize / 2, magnifierSize / 2, 5, 0, 2 * Math.PI)
        mCtx.fill()
    }

    // --- Extraction via un canvas offscreen pour obtenir l'image sans overlay ---
    const handleExtract = () => {
        if (selectedPoints.length < 4) {
            alert('Merci de sélectionner 4 points sur l’image.')
            return
        }
        if (!loadedOpenCV) {
            alert("OpenCV n'est pas chargé.")
            return
        }
        if (!originalImg) {
            alert("L'image originale n'est pas chargée.")
            return
        }
        try {
            const cv = window.cv
            const offCanvas = document.createElement('canvas')
            offCanvas.width = originalImg.width
            offCanvas.height = originalImg.height
            const offCtx = offCanvas.getContext('2d')
            offCtx.drawImage(
                originalImg,
                0,
                0,
                offCanvas.width,
                offCanvas.height
            )
            const src = cv.imread(offCanvas)

            const sortedPoints = sortPoints([...selectedPoints])
            const [tl, tr, br, bl] = sortedPoints
            const widthA = Math.hypot(br[0] - bl[0], br[1] - bl[1])
            const widthB = Math.hypot(tr[0] - tl[0], tr[1] - tl[1])
            const maxWidth = Math.max(widthA, widthB)
            const heightA = Math.hypot(tr[0] - br[0], tr[1] - br[1])
            const heightB = Math.hypot(tl[0] - bl[0], tl[1] - bl[1])
            const maxHeight = Math.max(heightA, heightB)

            const srcCoords = sortedPoints.flat()
            const srcTri = cv.matFromArray(4, 1, cv.CV_32FC2, srcCoords)
            const dstTri = cv.matFromArray(4, 1, cv.CV_32FC2, [
                0,
                0,
                maxWidth,
                0,
                maxWidth,
                maxHeight,
                0,
                maxHeight,
            ])

            const M = cv.getPerspectiveTransform(srcTri, dstTri)
            const dst = new cv.Mat()
            cv.warpPerspective(
                src,
                dst,
                M,
                new cv.Size(maxWidth, maxHeight),
                cv.INTER_LINEAR,
                cv.BORDER_CONSTANT,
                new cv.Scalar()
            )

            const grayMat = new cv.Mat()
            cv.cvtColor(dst, grayMat, cv.COLOR_RGBA2GRAY)
            const displayMat = new cv.Mat()
            cv.cvtColor(grayMat, displayMat, cv.COLOR_GRAY2RGBA)

            cv.imshow(resultCanvasRef.current, displayMat)

            // On récupère l'image extraite pour l'affichage sur la 3ème étape
            const url = resultCanvasRef.current.toDataURL('image/png')
            setExtractedImageUrl(url)
            setShowResult(true)
            setCurrentStep('extraction')

            src.delete()
            dst.delete()
            srcTri.delete()
            dstTri.delete()
            M.delete()
            grayMat.delete()
            displayMat.delete()
        } catch (error) {
            console.error("Erreur lors de l'extraction:", error)
        }
    }

    function dataURLtoFile(dataURL, filename) {
        const arr = dataURL.split(',')
        const mimeMatch = arr[0].match(/:(.*?);/)
        if (!mimeMatch) {
            throw new Error('Type MIME non trouvé dans la dataURL')
        }
        const mime = mimeMatch[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: mime })
    }
    const handleSave = () => {
        const url =
            extractedImageUrl ||
            (resultCanvasRef.current &&
                resultCanvasRef.current.toDataURL('image/png'))
        if (!url) return

        // Convertir la dataURL en File
        const imageFile = dataURLtoFile(url, 'scanned-image.png')

        // Ensuite, appelez votre fonction pour compresser et uploader
        saveUrl(imageFile)
    }

    // --- Gestion des événements de déplacement (souris et tactile) ---
    const convertCoordinates = (clientX, clientY, canvas) => {
        const rect = canvas.getBoundingClientRect()
        const scaleX = canvas.width / rect.width
        const scaleY = canvas.height / rect.height
        return {
            x: (clientX - rect.left) * scaleX,
            y: (clientY - rect.top) * scaleY,
        }
    }

    const getNearestPointIndex = (x, y) => {
        const threshold = 120
        return selectedPoints.findIndex(
            ([px, py]) => Math.hypot(px - x, py - y) < threshold
        )
    }

    const handleMouseDown = (e) => {
        const canvas = sourceCanvasRef.current
        if (!canvas) return
        const { x, y } = convertCoordinates(e.clientX, e.clientY, canvas)
        const pointIndex = getNearestPointIndex(x, y)
        if (pointIndex !== -1) {
            setIsDragging(true)
            setDraggedPointIndex(pointIndex)
            setMagnifierVisible(true)
            updateMagnifier(x, y)
        } else if (selectedPoints.length < 4) {
            setSelectedPoints([...selectedPoints, [x, y]])
        }
    }

    const handleMouseMove = (e) => {
        if (
            !isDragging ||
            draggedPointIndex === null ||
            !sourceCanvasRef.current
        )
            return
        const { x, y } = convertCoordinates(
            e.clientX,
            e.clientY,
            sourceCanvasRef.current
        )
        const newPoints = [...selectedPoints]
        newPoints[draggedPointIndex] = [x, y]
        setSelectedPoints(newPoints)
        updateMagnifier(x, y)
    }

    const handleMouseUp = () => {
        setIsDragging(false)
        setDraggedPointIndex(null)
        setMagnifierVisible(false)
    }

    const handleTouchStart = (e) => {
        e.preventDefault()
        const canvas = sourceCanvasRef.current
        if (!canvas) return
        const touch = e.touches[0]
        const { x, y } = convertCoordinates(
            touch.clientX,
            touch.clientY,
            canvas
        )
        const pointIndex = getNearestPointIndex(x, y)
        if (pointIndex !== -1) {
            setIsDragging(true)
            setDraggedPointIndex(pointIndex)
            setMagnifierVisible(true)
            updateMagnifier(x, y)
        } else if (selectedPoints.length < 4) {
            setSelectedPoints([...selectedPoints, [x, y]])
        }
    }

    const handleTouchMove = (e) => {
        e.preventDefault()
        if (
            !isDragging ||
            draggedPointIndex === null ||
            !sourceCanvasRef.current
        )
            return
        const touch = e.touches[0]
        const { x, y } = convertCoordinates(
            touch.clientX,
            touch.clientY,
            sourceCanvasRef.current
        )
        const newPoints = [...selectedPoints]
        newPoints[draggedPointIndex] = [x, y]
        setSelectedPoints(newPoints)
        updateMagnifier(x, y)
    }

    const handleTouchEnd = (e) => {
        e.preventDefault()
        setIsDragging(false)
        setDraggedPointIndex(null)
        setMagnifierVisible(false)
    }

    const handleReset = () => {
        setSelectedPoints([])
        setShowResult(false)
        setInitializedContour(false)
        setExtractedImageUrl(null)
        setCurrentStep('selection')
        if (originalImg) {
            const canvas = sourceCanvasRef.current
            if (canvas) {
                canvas.width = originalImg.width
                canvas.height = originalImg.height
                const ctx = canvas.getContext('2d')
                ctx.clearRect(0, 0, canvas.width, canvas.height)
                ctx.drawImage(
                    originalImg,
                    0,
                    0,
                    originalImg.width,
                    originalImg.height
                )
            }
            if (loadedOpenCV) {
                const computedPoints = computePaperContour()
                if (computedPoints.length === 4) {
                    setSelectedPoints(computedPoints)
                }
                setInitializedContour(true)
            }
        }
    }

    const goNext = () => {
        if (currentStep === 'selection') {
            if (!showResult) {
                alert('Veuillez extraire d’abord avant de continuer.')
                return
            }
            setCurrentStep('extraction')
        } else if (currentStep === 'extraction') {
            setCurrentStep('sauvegarde')
        }
    }

    const goPrevious = () => {
        if (currentStep === 'extraction') {
            setCurrentStep('selection')
        } else if (currentStep === 'sauvegarde') {
            setCurrentStep('extraction')
        }
    }

    return (
        <div className="fixed inset-0 overflow-hidden bg-white">
            <div className="mx-auto h-screen max-w-4xl p-6">
                {/* En-tête */}
                <div className="mb-4 flex items-center justify-between">
                    <button
                        onClick={onBack}
                        className="flex cursor-pointer items-center gap-2 rounded-lg border p-2"
                    >
                        <ArrowLeft className="h-4 w-4" />
                        Reprendre la photo
                    </button>
                </div>
                {/* Conteneur des étapes avec effet "snap" */}
                <div className="relative h-[80vh]">
                    <div
                        className="flex h-full transition-transform duration-300"
                        style={{
                            transform: `translateX(-${stepIndex * 100}%)`,
                            width: '100%',
                        }}
                    >
                        {/* Panel 1 : Sélection */}
                        <div className="w-full flex-shrink-0 p-4">
                            <h2 className="mb-4 text-xl font-bold">
                                Sélection
                            </h2>
                            <p className="mb-2">
                                Sélectionnez la zone à extraire en cliquant sur
                                4 points.
                            </p>
                            <div className="relative">
                                <canvas
                                    ref={sourceCanvasRef}
                                    className="w-full rounded-lg border shadow-sm"
                                    style={{
                                        border: '1px solid #aaa',
                                        cursor: 'crosshair',
                                        touchAction: 'none',
                                    }}
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={handleMouseUp}
                                    onTouchStart={handleTouchStart}
                                    onTouchMove={handleTouchMove}
                                    onTouchEnd={handleTouchEnd}
                                />
                                {magnifierVisible && (
                                    <canvas
                                        ref={magnifierCanvasRef}
                                        style={{
                                            position: 'absolute',
                                            width: 150,
                                            height: 150,
                                            borderRadius: '50%',
                                            border: '2px solid #000',
                                            top: magnifierPosition.y + 20,
                                            left: magnifierPosition.x + 20,
                                            pointerEvents: 'none',
                                            background: '#fff',
                                        }}
                                    />
                                )}
                            </div>
                            <div className="mt-4 flex gap-4">
                                <button
                                    onClick={handleExtract}
                                    disabled={
                                        !loadedOpenCV ||
                                        selectedPoints.length !== 4
                                    }
                                    className="flex items-center gap-2 rounded-lg border p-2"
                                >
                                    <Camera className="h-4 w-4" />
                                    Extraire
                                </button>
                                <button
                                    onClick={handleReset}
                                    className="flex items-center gap-2 rounded-lg border p-2"
                                >
                                    <Refresh className="h-4 w-4" />
                                    Réinitialiser
                                </button>
                                <button
                                    onClick={() => setSelectedPoints([])}
                                    className="flex items-center gap-2 rounded-lg border p-2"
                                >
                                    <Erase className="h-4 w-4" />
                                    Effacer
                                </button>
                            </div>
                        </div>
                        {/* Panel 2 : Prévisualisation */}
                        <div className="w-full flex-shrink-0 p-4">
                            <h2 className="mb-4 text-xl font-bold">
                                Prévisualisation
                            </h2>
                            {/*{showResult ? (*/}
                            <canvas
                                ref={resultCanvasRef}
                                className="w-full rounded-lg border shadow-sm"
                                style={{ border: '1px solid #aaa' }}
                            />
                            {/*) : (*/}
                            {/*    <p>Aucun document extrait.</p>*/}
                            {/*)}*/}
                            <div className="mt-4 flex gap-4">
                                <button
                                    onClick={() => setCurrentStep('selection')}
                                    className="flex items-center gap-2 rounded-lg border p-2"
                                >
                                    <ArrowLeft className="h-4 w-4" />
                                    Retour
                                </button>
                                <button
                                    onClick={() => setCurrentStep('sauvegarde')}
                                    className="flex items-center gap-2 rounded-lg border p-2"
                                >
                                    Suivant
                                </button>
                            </div>
                        </div>
                        {/* Panel 3 : Sauvegarde */}
                        <div className="w-full flex-shrink-0 p-4">
                            <h2 className="mb-4 text-xl font-bold">
                                Sauvegarde
                            </h2>
                            {extractedImageUrl ? (
                                <img
                                    src={extractedImageUrl}
                                    alt="Document extrait"
                                    className="w-full rounded-lg border shadow-sm"
                                />
                            ) : (
                                <p>Aucun document extrait.</p>
                            )}
                            <div className="mt-4 flex gap-4">
                                <button
                                    onClick={() => setCurrentStep('extraction')}
                                    className="flex items-center gap-2 rounded-lg border p-2"
                                >
                                    <ArrowLeft className="h-4 w-4" />
                                    Retour
                                </button>
                                <button
                                    onClick={handleSave}
                                    className="flex items-center gap-2 rounded-lg border p-2"
                                >
                                    <FloppyDisk className="h-4 w-4" />
                                    Sauvegarder
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* Flèches de navigation (overlay) */}
                    {currentStep !== 'selection' && (
                        <button
                            onClick={goPrevious}
                            className="absolute left-4 top-1/2 -translate-y-1/2 transform rounded-full bg-white p-2 shadow"
                        >
                            <ArrowLeft className="h-6 w-6" />
                        </button>
                    )}
                    {currentStep !== 'sauvegarde' && (
                        <button
                            onClick={goNext}
                            className="absolute right-4 top-1/2 -translate-y-1/2 transform rounded-full bg-white p-2 shadow"
                        >
                            <ArrowLeft className="h-6 w-6 rotate-180" />
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Scanner
