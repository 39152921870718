import React, { useEffect, useState } from 'react'
import API from '../../../services/api'
import { useNavigate } from 'react-router-dom'
import PatientView from './PatientView'
import RunView from './RunView'
import ActionView from './ActionView'
import ShareView from './ShareView'
import { SharingOptionsByType } from '../../../services/SharingOptions'
import ActionGroupedRunsView from './ActionGroupedRunsView'
import PatientGroupedRunsView from './PatientGroupedRunsView'
import RunsGroupedRunsView from './RunsGroupedRunsView'
import { checkIfTeamRunOnExactSameTimes } from '../../../tools/Utility'
import { Documents } from '../../../services/Documents'
import { toast } from 'react-toastify'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import TabButton from '../../Commons/Buttons/TabButton'
import {
    addMinutes,
    formatDateFromApiToDateObject,
    formatDateToApi,
    getTimeDifferenceInMinutes,
} from '../../../services/dateUtils'

const RunModalContainer = ({
    setModalOpen,
    run,
    masterRunsList,
    statusList,
    reRender,
    setOpenRunManageModal,
    planning,
    setOpenCompetitionModal,
    disabledButtons,
    setDisabledButtons,
}) => {
    const [hasChanged, setHasChanged] = useState(false)
    const [status, setStatus] = useState({
        label: run.status.label,
        value: `/statuses/${run.status.id}`,
    })
    const [partner, setPartner] = useState(
        run.partner
            ? {
                  label: run.partner?.lastname + ' ' + run.partner?.firstname,
                  value: `/partners/${run.partner?.id}`,
              }
            : null
    )

    const [documents, setDocuments] = useState(
        run.documents
            ? {
                  label: run.documents.label,
                  value: run.documents.label,
                  ['@id']: `/documents/${run.documents.label}`,
              }
            : Documents[0]
    )

    const [runMasterRun, setRunMasterRun] = useState(null)
    const [note, setNote] = useState(run.note)
    const [shareRoundTrip, setShareRoundTrip] = useState(false)
    const [sharingOption, setSharingOption] = useState(
        SharingOptionsByType.whatsapp
    )

    const [runView, setRunView] = useState(true)
    const [patientView, setPatientView] = useState(false)
    const [actionView, setActionView] = useState(false)
    const [shareView, setShareView] = useState(false)
    const [arrivingTime, setArrivingTime] = useState(
        formatDateFromApiToDateObject(run.arrivingTime)
    )
    const [departureTime, setDepartureTime] = useState(
        formatDateFromApiToDateObject(run.departureTime)
    )
    const [effectiveArrivingTime, setEffectiveArrivingTime] = useState(
        formatDateFromApiToDateObject(run.arrivingTime)
    )
    const [effectiveDepartureTime, setEffectiveDepartureTime] = useState(
        formatDateFromApiToDateObject(run.departureTime)
    )
    const [approximateTime, setApproximateTime] = useState(run.approximateTime)

    const [openSharingOptions, setOpenSharingOptions] = useState(false)

    const [hoursChanged, setHoursChanged] = useState(false)
    const [effectiveHoursChanged, setEffectiveHoursChanged] = useState(false)
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [groupRunOrderChanged, setGroupRunOrderChanged] = useState(false)

    const [isUserCommentsRed, setIsUserCommentsRed] = useState(
        run.isUserCommentsRed
    )

    const [indirectReturnDepartureTime, setIndirectReturnDepartureTime] =
        useState(formatDateFromApiToDateObject(run.return?.departureTime))
    const [haveIndirectReturn, setHaveIndirectReturn] = useState(false)

    const [groupedRuns, setGroupedRuns] = useState(run.groupedRuns)

    let history = useNavigate()

    useEffect(() => {
        if (run.masterRun) {
            setRunMasterRun(
                masterRunsList.find((m) => m.value === run.masterRun['@id'])
            )
        }
        if (run.return && !run.return?.directReturn) {
            setHaveIndirectReturn(true)
        }

        if (run.effectiveDepartureTime || run.effectiveArrivingTime) {
            setEffectiveDepartureTime(
                formatDateFromApiToDateObject(run.effectiveDepartureTime) ||
                    formatDateFromApiToDateObject(run.departureTime)
            )
            setEffectiveArrivingTime(
                formatDateFromApiToDateObject(run.effectiveArrivingTime) ||
                    formatDateFromApiToDateObject(run.arrivingTime)
            )
        }
    }, [run, masterRunsList])

    const checkErrors = () => {
        let errorOccurred = false
        if (
            openSharingOptions &&
            partner &&
            !partner.partner.email &&
            sharingOption.value === 'mail'
        ) {
            toast.error(
                "L'adresse email de votre partenaire n'est pas renseignée"
            )
            errorOccurred = true
        }
        if (arrivingTime < departureTime) {
            toast.error(
                "L'heure d'arrivée doit être supérieure à l'heure de départ."
            )
            errorOccurred = true
        }
        if (run.isRoundTrip) {
            if (
                haveIndirectReturn &&
                indirectReturnDepartureTime < arrivingTime
            ) {
                toast.error(
                    "L'heure de retour doit être supérieure à l'heure de d'arrivée."
                )
                errorOccurred = true
            }

            if (run.isReturnPath) {
                if (departureTime < new Date(run.lead?.arrivingTime)) {
                    toast.error(
                        "L'heure de départ ne peut être inférieure à l'heure d'arrivée de la course parente."
                    )
                    errorOccurred = true
                }
            }
        }

        return errorOccurred
    }

    const handleView = (view) => {
        setRunView(view === 'run')
        setActionView(view === 'action')
        setPatientView(view === 'patient')
        setShareView(view === 'share')
    }
    const handleValidateChanges = async () => {
        setDisabledButtons(true)
        if (!checkErrors()) {
            if (openSharingOptions && partner) {
                const sharedData = {
                    partner: partner.value,
                    note: note,
                    sharingOption: sharingOption.value,
                    shareRoundTrip: shareRoundTrip,
                    user: localStorage.getItem('email'),
                }
                try {
                    await API.Runs.share(run.id, sharedData)
                    setModalOpen(false)
                    validateChanges()
                } catch (error) {
                    setDisabledButtons(false)
                }
            } else {
                validateChanges()
            }
        } else {
            setDisabledButtons(false)
        }
    }

    const validateNewTimes = (data, returnData, runMasterRun) => {
        if (hoursChanged) {
            data.arrivingTime = formatDateToApi(arrivingTime)
            data.departureTime = formatDateToApi(departureTime)
            if (effectiveHoursChanged) {
                data.effectiveArrivingTime = effectiveArrivingTime
                    ? formatDateToApi(effectiveArrivingTime)
                    : null
                data.effectiveDepartureTime = effectiveDepartureTime
                    ? formatDateToApi(effectiveDepartureTime)
                    : null
            }

            if (run.isRoundTrip && !run.isReturnPath) {
                if (haveIndirectReturn) {
                    const timeDifference = getTimeDifferenceInMinutes(
                        arrivingTime,
                        departureTime
                    )
                    returnData.departureTime = formatDateToApi(
                        indirectReturnDepartureTime
                    )
                    returnData.arrivingTime = formatDateToApi(
                        addMinutes(indirectReturnDepartureTime, timeDifference)
                    )
                } else {
                    const newReturnDepartureTime = addMinutes(
                        arrivingTime,
                        run.waitingTime
                    )
                    const timeDifference = getTimeDifferenceInMinutes(
                        arrivingTime,
                        departureTime
                    )
                    returnData.departureTime = formatDateToApi(
                        newReturnDepartureTime
                    )
                    returnData.arrivingTime = formatDateToApi(
                        addMinutes(newReturnDepartureTime, timeDifference)
                    )
                }

                // const sameHours = masterRunsList.some(
                //     (masterRun) =>
                //         masterRun.runs.some((r) => r.id === run.return.id) &&
                //         checkIfTeamRunOnExactSameTimes(
                //             masterRun.runs,
                //             run.return.id,
                //             returnData.departureTime,
                //             returnData.arrivingTime
                //         )
                // )
                //
                // if (sameHours) {
                //     toast.error(
                //         "Une course avec exactement les mêmes horaires est déjà attribuée à l'équipe de la course retour."
                //     )
                //     return true
                // }
            }

            // if (runMasterRun && runMasterRun.runs) {
            //     const sameHours =
            //         checkIfTeamRunOnExactSameTimes(
            //             runMasterRun.runs,
            //             run.id,
            //             departureTime,
            //             arrivingTime
            //         ) ||
            //         checkIfTeamRunOnExactSameTimes(
            //             runMasterRun.runs,
            //             run.id,
            //             effectiveDepartureTime,
            //             effectiveArrivingTime
            //         )
            //     if (sameHours) {
            //         toast.error(
            //             'Une course avec exactement les mêmes horaires est déjà attribuée à cette équipe.'
            //         )
            //         return true
            //     }
            // }
        }
        return false
    }

    const validateChanges = () => {
        console.log('validateChanges')
        if (groupRunOrderChanged) {
            handleChangeGroupedRunsOrder()
        } else {
            const data = {
                masterRun: runMasterRun ? runMasterRun.value : null,
                status: status['@id'],
                note: note,
                isUserCommentsRed: isUserCommentsRed,
                parent: null,
                departureOrder: null,
                documents: documents['@id'],
                approximateTime: approximateTime,
                clientOpenedPageDatetime: planning.clientOpenedPageDatetime,
            }

            let returnData = {}
            let error = false
            if (hoursChanged) {
                error = validateNewTimes(data, returnData, runMasterRun)
            }

            if (!error) {
                console.log('ici', data)
                API.Runs.put(run.id, data).then((response) => {
                    if (response.refresh) {
                        setOpenCompetitionModal(true)
                    } else {
                        if (run.isRoundTrip && !run.isReturnPath) {
                            API.Runs.put(run.return.id, returnData).then(
                                (response) => {
                                    if (response.refresh) {
                                        setOpenCompetitionModal(true)
                                    } else {
                                        reRender()
                                        setModalOpen(false)
                                        toast.success(
                                            'Les courses aller et retour ont été modifiées.'
                                        )
                                    }
                                }
                            )
                        } else {
                            reRender()
                            setModalOpen(false)
                            toast.success('La course a été modifiée.')
                        }
                    }
                })
            }
        }
    }

    const handleChangeGroupedRunsOrder = () => {
        // Il y a moins d'éléments dans groupedRuns que dans run.groupedRun c'est qu'on en a retiré.
        // Je sors du groupe ceux qui ont été retirés.

        const runsToUngroup = run.groupedRuns.filter(
            (run1) => !groupedRuns.find((run2) => run2.id === run1.id)
        )

        //flatmap grouped runs with other runs
        const flatMapsAllRuns = runMasterRun.runs
            .flatMap((r) => {
                if (r.groupedRun) {
                    return r.groupedRuns.flatMap((groupedRun) => {
                        if (
                            runsToUngroup.some(
                                (runToUngroup) =>
                                    runToUngroup.id === groupedRun.id
                            )
                        ) {
                            return null
                        } else {
                            return groupedRun
                        }
                    })
                } else {
                    return r
                }
            })
            .filter((item) => item !== null)

        const otherTeamRuns = []
        flatMapsAllRuns.forEach((r) => {
            if (!groupedRuns.some((runToGroup) => runToGroup.id === r.id)) {
                otherTeamRuns.push(r)
            }
        })

        if (groupedRuns.length === 1) {
            groupedRuns[0].departureOrder = null
            groupedRuns[0].parent = null
        }

        const allRuns = [...otherTeamRuns, ...groupedRuns]

        const data = {
            runs: allRuns.map((r) => {
                const d = {
                    id: r['@id'],
                }
                if (r.status)
                    d.status = r.status['@id'] ? r.status['@id'] : r.status
                if (r.departureOrder) {
                    d.departureOrder = r.departureOrder
                } else {
                    d.departureOrder = null
                }
                if (r.parent) {
                    d.parent = r.parent['@id'] ? r.parent['@id'] : r.parent
                } else {
                    d.parent = null
                }

                return d
            }),
        }

        if (runsToUngroup.length > 0) {
            data['unGroup'] = true
            data['runsToUnGroup'] = runsToUngroup.map((r) => {
                return {
                    id: r.id,
                }
            })
        }

        //save
        API.MasterRuns.put(runMasterRun.id, data).then((data) => {
            setActionModalOpen(false)
            setModalOpen(false)
            reRender()
        })
    }

    return (
        <div className="modal fixed bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-500 bg-opacity-50 px-2 pb-20 text-gray-700 md:px-6">
            <div className="shadow-modal invisible-scrollbar min-h-modal mx-auto flex w-full max-w-4xl flex-col overflow-y-auto rounded-lg bg-white">
                <div className="flex flex-col items-start p-8">
                    <div className="flex w-full items-center border-b pb-4 text-xl">
                        <div className="flex space-x-4 text-center font-semibold ">
                            <TabButton
                                value={runView}
                                action={() => handleView('run')}
                                label={run.groupedRun ? 'Courses' : 'Course'}
                            />
                            <TabButton
                                value={patientView}
                                action={() => handleView('patient')}
                                label={run.groupedRun ? 'Fiches' : 'Fiche'}
                            />
                            {run.partner && (
                                <TabButton
                                    value={shareView}
                                    action={() => handleView('share')}
                                    label="Partage"
                                />
                            )}
                            <TabButton
                                value={actionView}
                                action={() => handleView('action')}
                                label="Options"
                            />
                        </div>
                        <svg
                            className="ml-auto h-6 w-6 cursor-pointer fill-current text-gray-700"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18 18"
                            onClick={() => setModalOpen(false)}
                        >
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                        </svg>
                    </div>
                    {!run.groupedRun ? (
                        patientView ? (
                            <PatientView run={run} history={history} />
                        ) : runView ? (
                            <RunView
                                run={run}
                                history={history}
                                setOpenRunManageModal={setOpenRunManageModal}
                                setModalOpen={setModalOpen}
                                fromDashboard={true}
                            />
                        ) : shareView ? (
                            <ShareView run={run} history={history} />
                        ) : (
                            <ActionView
                                run={run}
                                status={status}
                                setStatus={setStatus}
                                statusList={statusList}
                                setHasChanged={setHasChanged}
                                masterRunsList={masterRunsList}
                                runMasterRun={runMasterRun}
                                arrivingTime={arrivingTime}
                                setArrivingTime={setArrivingTime}
                                setDepartureTime={setDepartureTime}
                                effectiveArrivingTime={effectiveArrivingTime}
                                setEffectiveArrivingTime={
                                    setEffectiveArrivingTime
                                }
                                effectiveDepartureTime={effectiveDepartureTime}
                                setEffectiveDepartureTime={
                                    setEffectiveDepartureTime
                                }
                                setRunMasterRun={setRunMasterRun}
                                setIndirectReturnDepartureTime={
                                    setIndirectReturnDepartureTime
                                }
                                indirectReturnDepartureTime={
                                    indirectReturnDepartureTime
                                }
                                haveIndirectReturn={haveIndirectReturn}
                                departureTime={departureTime}
                                history={history}
                                setPartner={setPartner}
                                partner={partner}
                                note={note}
                                setNote={setNote}
                                shareRoundTrip={shareRoundTrip}
                                setShareRoundTrip={setShareRoundTrip}
                                setSharingOption={setSharingOption}
                                sharingOption={sharingOption}
                                openSharingOptions={openSharingOptions}
                                setOpenSharingOptions={setOpenSharingOptions}
                                setEffectiveHoursChanged={
                                    setEffectiveHoursChanged
                                }
                                setHoursChanged={setHoursChanged}
                                documents={documents}
                                setDocuments={setDocuments}
                                setIsUserCommentsRed={setIsUserCommentsRed}
                                isUserCommentsRed={isUserCommentsRed}
                                approximateTime={approximateTime}
                                setApproximateTime={setApproximateTime}
                            />
                        )
                    ) : patientView ? (
                        <PatientGroupedRunsView
                            history={history}
                            parentDetailsRun={run}
                        />
                    ) : runView ? (
                        <RunsGroupedRunsView
                            parentDetailsRun={run}
                            history={history}
                            setOpenRunManageModal={setOpenRunManageModal}
                            setModalOpen={setModalOpen}
                            fromDashboard={true}
                        />
                    ) : (
                        <ActionGroupedRunsView
                            run={run}
                            setGroupRunOrderChanged={setGroupRunOrderChanged}
                            setHasChanged={setHasChanged}
                            groupedRuns={groupedRuns}
                            setGroupedRuns={setGroupedRuns}
                        />
                    )}
                </div>
                {hasChanged && (
                    <div className="mt-auto flex w-full justify-between  px-8 pb-4 text-xl lg:text-base">
                        <SecondaryButton
                            label="Annuler"
                            title="Annuler"
                            action={() => setModalOpen(false)}
                        />
                        <PrimaryButton
                            label="Valider"
                            title="Valider"
                            action={handleValidateChanges}
                            disabled={disabledButtons}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default RunModalContainer
