import React from 'react'
import SortedGroupedRunsCard from './Mobile/SortedGroupedRunsCard'
import SortedRunCard from './Mobile/SortedRunCard'
import { toast } from 'react-toastify'
import { ACTIONS } from '../../../../services/Reducers/PlanningReducer'
import { SortRunsByGroupAndDepartureTime } from '../../../../tools/Sort'
import PcSortedRunCard from './Pc/PcSortedRunCard'
import PcSortedGroupedRunsCard from './Pc/PcSortedGroupedRunsCard'

const RunsList = ({
    planning,
    totalRunsTeam,
    onInfoClicked,
    dispatch,
    showRunObjectColors,
}) => {
    const onCardClicked = (run) => {
        if (run.partner && run.isGiven) {
            //partner run
            toast.error('Impossible de sélectionner un transport partenaire.')
        } else {
            //team run
            dispatch({
                type: ACTIONS.SET_SELECTED_TEAM_RUN_SELECTED,
                payload: {
                    id: run.id,
                    selected: !run.selected,
                },
            })
            //team run
            dispatch({
                type: ACTIONS.SET_TEAM_RUN_SELECTED,
                payload: {
                    id: run.id,
                    selected: !run.selected,
                    teamId: run.masterRun.id,
                },
            })
        }
    }
    return (
        <div className="flex flex-col px-6">
            <div className="flex flex-col space-y-4 lg:space-y-1">
                {planning.selectedTeam.runs.length === 0 ? (
                    <div className="flex w-full items-center justify-center pt-20 text-lg md:text-xl">
                        Aucun transport n'est assigné à cette équipe.
                    </div>
                ) : (
                    SortRunsByGroupAndDepartureTime(
                        planning.selectedTeam.runs
                    ).map((run) => {
                        if (run.groupedRun) {
                            return (
                                <div>
                                    <div className="block lg:hidden">
                                        <SortedGroupedRunsCard
                                            run={run}
                                            onCardClicked={onCardClicked}
                                            onInfoClicked={onInfoClicked}
                                            showRunObjectColors={
                                                showRunObjectColors
                                            }
                                        />
                                    </div>
                                    <div className="hidden lg:block">
                                        <PcSortedGroupedRunsCard
                                            run={run}
                                            onCardClicked={onCardClicked}
                                            onInfoClicked={onInfoClicked}
                                            showRunObjectColors={
                                                showRunObjectColors
                                            }
                                        />
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    <div className="block lg:hidden">
                                        <SortedRunCard
                                            run={run}
                                            onCardClicked={onCardClicked}
                                            onInfoClicked={onInfoClicked}
                                            showRunObjectColors={
                                                showRunObjectColors
                                            }
                                        />
                                    </div>
                                    <div className="hidden lg:block">
                                        <PcSortedRunCard
                                            run={run}
                                            onCardClicked={onCardClicked}
                                            onInfoClicked={onInfoClicked}
                                            showRunObjectColors={
                                                showRunObjectColors
                                            }
                                        />
                                    </div>
                                </div>
                            )
                        }
                    })
                )}
            </div>
        </div>
    )
}

export default RunsList
