import React from 'react'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import { EditPencil, Plus, Printer } from 'iconoir-react'
import * as dayjs from 'dayjs'
import { formatDateLocal } from '../../../../services/dateUtils'

const NoemieHeader = ({
    isReceived,
    noemieReceivedAt,
    handlePrintAction,
    setShowManualDeclaration,
}) => (
    <div className={'flex items-center justify-between pb-5'}>
        {isReceived ? (
            <div className={'flex flex-col'}>
                <h1 className="text-2xl font-bold">
                    Retours noemie du {formatDateLocal(noemieReceivedAt)}
                </h1>
                <p>Cliquez sur un lot pour voir le détail du retour</p>
            </div>
        ) : (
            <h1 className="text-2xl font-bold">Retour NOEMIE non reçu</h1>
        )}
        <PrimaryButton
            label={'Générer les justificatifs'}
            customIconClass={'pr-2'}
            action={handlePrintAction}
            hiddenLabelOnMobile={true}
            icon={<Printer />}
        />
        <PrimaryButton
            label={
                noemieReceivedAt ? 'Modifier le retour' : 'Déclarer le retour'
            }
            icon={
                noemieReceivedAt ? (
                    <EditPencil className={'mr-2'} />
                ) : (
                    <Plus className={'mr-2'} />
                )
            }
            hiddenLabelOnMobile={true}
            action={() => setShowManualDeclaration(true)}
        />
    </div>
)

export default NoemieHeader
