import React, { useEffect, useReducer, useState } from 'react'
import ParametersConfig from './ParameterConfig'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import SubscriptionContainer from './Subscription/SubscriptionContainer'
import ConfiguratorContainer from './Configurator/ConfiguratorContainer'
import API from '../../../services/api'
import Loader from '../../Commons/Loaders/Loader'
import { SocietyContainer } from './Society/SocietyContainer'
import {
    getInitialState,
    SOCIETY_ACTIONS,
    SocietyReducer,
} from '../../../services/Reducers/SocietyReducer'
import {
    ADDRESS_ACTIONS,
    AddressAutoCompleteReducer,
    getAddressInitialState,
} from '../../../services/Reducers/AddressAutoCompleteReducer'
import TabButton from '../../Commons/Buttons/TabButton'
import { Building, CreditCard, EditPencil, Settings } from 'iconoir-react'
import { useQuery } from '@tanstack/react-query'
import { Society } from '../../../services/API/Entities/society'

const ParameterContainer = ({ parameters }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const societyId = localStorage.getItem('society')?.split('/').reverse()[0]
    const [loading, setLoading] = useState(false)

    // Query pour récupérer la société
    const { data: societyData, isLoading: isLoadingSociety } = useQuery({
        queryKey: ['society', societyId],
        queryFn: async () => {
            return await Society.getSociety(societyId)
        },
    })
    const [society, dispatch] = useReducer(
        SocietyReducer,
        undefined,
        getInitialState
    )
    const [address, addressDispatch] = useReducer(
        AddressAutoCompleteReducer,
        { undefined },
        getAddressInitialState
    )

    const isUserMaintainer = JSON.parse(localStorage.getItem('isMaintainer'))

    // Rediriger les non-maintainers vers le configurateur
    React.useEffect(() => {
        if (!isUserMaintainer) {
            navigate('/parametres/configurateur')
        }
        if (!isLoadingSociety) {
            dispatch({
                type: SOCIETY_ACTIONS.GET_SOCIETY,
                payload: societyData,
            })
            addressDispatch({
                type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                payload: societyData.address,
            })
        }
    }, [isUserMaintainer, navigate, societyData])

    if (isLoadingSociety || parameters === undefined) {
        return <Loader />
    }

    console.log('ParameterContainer', parameters, society)

    return (
        <div className="flex flex-col">
            {/* Navigation Tabs */}
            <div className="flex items-center font-semibold text-gray-700 sm:text-base lg:col-span-2">
                <div className="flex grid h-10 w-full cursor-pointer grid-cols-4 justify-between gap-4 rounded-xl border-dark-green lg:w-auto">
                    {isUserMaintainer && (
                        <>
                            <TabButton
                                value={location.pathname === '/parametres'}
                                action={() => navigate('/parametres')}
                                label="General"
                                icon={<Settings />}
                            />
                            <TabButton
                                value={location.pathname.startsWith(
                                    '/parametres/societe'
                                )}
                                action={() => navigate('/parametres/societe')}
                                label="Société"
                                icon={<Building />}
                            />
                            <TabButton
                                value={location.pathname.startsWith(
                                    '/parametres/abonnement'
                                )}
                                action={() =>
                                    navigate('/parametres/abonnement')
                                }
                                label="Abonnement"
                                icon={<CreditCard />}
                            />
                        </>
                    )}
                    <TabButton
                        value={location.pathname.startsWith(
                            '/parametres/configurateur'
                        )}
                        action={() => navigate('/parametres/configurateur')}
                        label="Configurateur"
                        icon={<EditPencil />}
                    />
                </div>
            </div>

            {/* Routes */}
            <Routes>
                <Route
                    path="/"
                    element={
                        <ParametersConfig
                            parameters={parameters}
                            smsCreditsRemaining={society?.smsCreditsRemaining}
                        />
                    }
                />
                <Route
                    path="/societe"
                    element={
                        <SocietyContainer
                            society={society}
                            action={SOCIETY_ACTIONS}
                            dispatch={dispatch}
                            address={address}
                            addressDispatch={addressDispatch}
                        />
                    }
                />
                <Route
                    path="/abonnement/*"
                    element={<SubscriptionContainer />}
                />
                <Route
                    path="/configurateur/*"
                    element={
                        <ConfiguratorContainer
                            computerConfigurator={
                                parameters?.computerConfigurator
                            }
                            mobileConfigurator={parameters?.mobileConfigurator}
                            setLoading={setLoading}
                        />
                    }
                />
            </Routes>
        </div>
    )
}

export default ParameterContainer
