import React, { useReducer, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
    getComplementaryInitialState,
    HEALTH_COMPLEMENTARY_ACTIONS,
    healthComplementaryFormReducer,
} from '../Regulation/Reducers/HealthComplementaryFormReducer'
import {
    handleHealthComplementaryForm,
    HealthComplementaries,
} from '../../../services/API/Entities/healthComplementaries'
import SendFormButtons from '../../Commons/Buttons/SendFormButtons'
import { SkeletonLoaderCards } from '../../Commons/Loaders/SkeletonLoaderCards'
import HealthComplementaryForm from '../Regulation/Forms/HealthComplementaryForm'
import { toast } from 'react-toastify'

const HealthComplementaryWrapper = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const queryClient = useQueryClient()

    const {
        data: healthComplementary,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['healthComplementary', id],
        queryFn: () => HealthComplementaries.fetchOne(id),
        enabled: !!id,
        retry: 0,
    })

    const [healthComplementaryForm, dispatch] = useReducer(
        healthComplementaryFormReducer,
        { undefined },
        getComplementaryInitialState
    )

    const healthComplementaryMutation = useMutation({
        mutationFn: () =>
            handleHealthComplementaryForm(healthComplementaryForm),
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['healthComplementary', id],
            })
            toast.success('La complémentaire a été modifiée')
            navigate(-1)
        },
    })

    useEffect(() => {
        if (healthComplementary) {
            dispatch({
                type: HEALTH_COMPLEMENTARY_ACTIONS.FETCH_HEALTH_COMPLEMENTARY,
                payload: healthComplementary,
            })
        }
    }, [healthComplementary])

    const handleHealthComplementaryModalForm = async () => {
        // invalidate the query client to fetch the latest data
        healthComplementaryMutation.mutate(healthComplementaryForm)
    }

    if (isLoading) {
        return <SkeletonLoaderCards />
    }

    if (isError) return <div>Une erreur est survenue</div>

    return (
        <>
            <HealthComplementaryForm
                healthComplementary={healthComplementaryForm}
                dispatch={dispatch}
                addressItem={healthComplementary.address}
            />
            <SendFormButtons handleForm={handleHealthComplementaryModalForm} />
        </>
    )
}

export default HealthComplementaryWrapper
