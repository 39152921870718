import { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'

const Camera = ({ onPhotoCapture, setShowScanner }) => {
    const videoRef = useRef(null)
    const canvasRef = useRef(null)
    const [isTorchOn, setIsTorchOn] = useState(false)
    const [imageData, setImageData] = useState(null)
    const [facingMode, setFacingMode] = useState('environment')
    const [isCameraActive, setIsCameraActive] = useState(true)

    useEffect(() => {
        if (!isCameraActive) return

        const startCamera = async () => {
            try {
                const constraints = {
                    video: {
                        facingMode: facingMode,
                        width: { ideal: 1920 },
                        height: { ideal: 1080 },
                    },
                    audio: false,
                }

                const stream = await navigator.mediaDevices.getUserMedia(
                    constraints
                )
                if (videoRef.current) {
                    videoRef.current.srcObject = stream
                    videoRef.current.play()
                }
            } catch (error) {
                console.error("Erreur lors de l'accès à la caméra : ", error)
                alert(
                    "Impossible d'accéder à la caméra. Veuillez vérifier les paramètres de votre appareil."
                )
            }
        }

        startCamera()

        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                const tracks = videoRef.current.srcObject.getTracks()
                tracks.forEach((track) => track.stop())
            }
        }
    }, [facingMode, isCameraActive])

    const toggleFlash = () => {
        if (!videoRef.current || !videoRef.current.srcObject) return

        const track = videoRef.current.srcObject.getVideoTracks()[0]

        if (!track) {
            console.warn('Aucune piste vidéo disponible.')
            return
        }

        const capabilities = track.getCapabilities()
        if (!capabilities.torch) {
            console.warn("Le flash n'est pas supporté sur cet appareil.")
            return
        }

        track
            .applyConstraints({
                advanced: [{ torch: !isTorchOn }],
            })
            .then(() => {
                setIsTorchOn((prev) => !prev)
            })
            .catch((e) =>
                console.error("Erreur lors de l'activation du flash :", e)
            )
    }

    // Fonction pour arrêter le flux vidéo et fermer la caméra
    const closeCamera = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            const tracks = videoRef.current.srcObject.getTracks()
            tracks.forEach((track) => track.stop())
            videoRef.current.srcObject = null
        }
        setIsCameraActive(false)
    }

    const captureImage = () => {
        if (canvasRef.current && videoRef.current) {
            const canvas = canvasRef.current
            const video = videoRef.current
            const context = canvas.getContext('2d')

            canvas.width = video.videoWidth
            canvas.height = video.videoHeight
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
            const image = canvas.toDataURL('image/png')
            setImageData(image)
        }
    }

    const switchCamera = () => {
        setFacingMode((prevMode) =>
            prevMode === 'environment' ? 'user' : 'environment'
        )
    }

    // Fonction appelée lors de la validation de la photo.
    // Elle ferme la caméra puis transmet la photo capturée.
    const handlePhotoValidation = () => {
        closeCamera()
        toast.success('Photo sauvegardée!')
        onPhotoCapture(imageData)
        setImageData(null)
    }

    const handleClose = () => {
        // add time out for closing camera
        setTimeout(() => {
            closeCamera()
            setShowScanner(false)
        }, 1000)
    }

    return (
        <div className="fixed inset-0 overflow-hidden bg-black">
            {/* Affichage de la vidéo uniquement si la caméra est active */}
            {isCameraActive && (
                <video
                    ref={videoRef}
                    className="absolute h-screen w-screen object-cover"
                    autoPlay
                    playsInline
                />
            )}
            <canvas ref={canvasRef} className="hidden"></canvas>

            {/* Interface appareil photo */}
            <div className="absolute inset-0 flex flex-col justify-between p-4">
                {/* Contrôles supérieurs */}
                <div className="flex items-center justify-between">
                    <button
                        onClick={() => setShowScanner(false)}
                        className="rounded-full bg-black/50 p-4 text-white backdrop-blur-sm transition-all hover:bg-black/70"
                    >
                        X
                    </button>
                    <div className="flex gap-4">
                        <button
                            onClick={toggleFlash}
                            className="rounded-full bg-black/50 p-4 text-white backdrop-blur-sm transition-all hover:bg-black/70"
                        >
                            ⚡️
                        </button>
                    </div>
                </div>

                {/* Zone centrale avec grille de photo */}
                <div className="pointer-events-none grid flex-1 grid-cols-3 grid-rows-3 gap-0.5 opacity-30">
                    {[...Array(9)].map((_, i) => (
                        <div key={i} className="border border-white/30"></div>
                    ))}
                </div>

                {/* Contrôles inférieurs */}
                <div className="flex items-center justify-center pb-2">
                    <button
                        onClick={() => {
                            captureImage()
                            toast.success('Photo capturée!')
                        }}
                        className="flex h-20 w-20 transform items-center justify-center rounded-full
                       border-4 border-white bg-white/20 backdrop-blur-sm transition-all hover:scale-105 hover:bg-white/30"
                    >
                        <div className="h-16 w-16 rounded-full bg-white"></div>
                    </button>
                </div>
            </div>

            {/* Aperçu de la photo capturée */}
            {imageData && (
                <div className="absolute inset-0 flex items-center justify-center bg-black/80 p-4">
                    <div className="relative w-full max-w-xl">
                        <img
                            src={imageData}
                            alt="Captured"
                            className="w-full rounded-lg shadow-xl"
                        />
                        <div className="absolute bottom-10 left-0 right-0 flex justify-between gap-4 p-4">
                            <button
                                onClick={handleClose}
                                className="rounded-lg bg-red-500 px-6 py-3 text-white transition-all hover:bg-red-600"
                            >
                                Annuler
                            </button>
                            <button
                                onClick={handlePhotoValidation}
                                className="rounded-lg bg-green-500 px-6 py-3 text-white transition-all hover:bg-green-600"
                            >
                                Valider
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Camera
