import React, {
    Fragment,
    useEffect,
    useRef,
    useState,
    useTransition,
} from 'react'
import dayjs from 'dayjs'
import { ACTIONS } from '../../../../services/Reducers/PlanningReducer'
import DashboardRunCard from './DashboardRunCard'
import RunModalContainer from '../../../Modal/RunModal/RunModalContainer'
import DashboardGroupedRunCard from './DashboardGroupedRunCard'
import API from '../../../../services/api'
import LoaderAbsolute from '../../../Commons/Loaders/LoaderAbsolute'
import ActionModal from '../../../Modal/ActionModal'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import usePlanningStore from '../../../../stores/Planning'
import { toast } from 'react-toastify'
import UnassignedRunsTitle from './UnassignedRunsTitle'
import PlanningDashboardTeamInfo from './PlanningDashboardTeamInfo'
import PlanningDashboardTimeLine from './PlanningDashboardTimeLine'
import VerticalDashedLine from './VerticalDashedLine'
import { usePlanningContext } from '../PlanningContext'
import { getHeightForTeam } from '../PlanningUtility'

const useOnClickAddMenuOutside = (ref, outsideHandler, clickHandler) => {
    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                // clickHandler(event)
            } else {
                outsideHandler(event)
            }
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, outsideHandler, clickHandler])
}

const PlanningDashboard = ({ showRunObjectColors }) => {
    const {
        planning,
        dispatch,
        reRender,
        handleClearTeamAndUnassignedRun,
        totalRunsTeam,
        handleOpenRunManageModal,
        unassignSelectedTeamsRuns,
        handleTeamClicked,
        handleGroupIconClicked,
        flatGroupedRunsAndRuns,
        handleInformationRunModalOpen,
        handleOpenCompetitionModal,
        verticalTimeLineRef,
        handleDisabledButtons,
    } = usePlanningContext()
    const dashboard = planning

    useEffect(() => {
        handleClearTeamAndUnassignedRun()
    }, [])

    const location = useLocation()
    const [help, setHelp] = useState(localStorage.getItem('help') !== 'false')

    const addMenuRef = useRef()
    const [openAddMenu, setOpenAddMenu] = useState(false)
    useOnClickAddMenuOutside(
        addMenuRef,
        () => setOpenAddMenu(false),
        () => setOpenAddMenu(true)
    )

    const dashboardHidedTeams = usePlanningStore(
        (state) => state.dashboardHidedTeams
    )
    const setDashboardHidedTeams = usePlanningStore(
        (state) => state.setDashboardHidedTeams
    )

    const timesIntervalSizeStyle = () => {
        return {
            minWidth:
                dashboard.intervalSize.size * dashboard.intervalSize.range +
                'px',
        }
    }

    const onInfoClick = (run) => {
        dispatch({
            type: ACTIONS.SET_INFO_CARD_CLICKED,
            payload: {
                run: run,
                seriesId: run.series ? run.series.id : 0,
            },
        })
    }

    const handleStyle = (run) => {
        return {
            width: run.width,
            left: run.left,
            zIndex: run.zIndex,
            top: run.top,
        }
    }

    const handleGroupRunsWithSameHours = (groupsRunsWithSameHours, team) => {
        let allGroupedRuns = []

        groupsRunsWithSameHours.forEach((groupOfRuns) => {
            const sortedGroupOfRuns = groupOfRuns.sort(
                (a, b) => new Date(a.departureTime) - new Date(b.departureTime)
            )
            sortedGroupOfRuns.forEach((r, index) => {
                r.departureOrder = index + 1
                if (index !== 0) {
                    r.parent = sortedGroupOfRuns[0]
                }
            })

            allGroupedRuns = [...allGroupedRuns, ...sortedGroupOfRuns]
        })

        const otherTeamRuns = []
        const flatOtherTeamRuns = flatGroupedRunsAndRuns(team.runs)

        flatOtherTeamRuns.forEach((r) => {
            if (!allGroupedRuns.some((runToGroup) => runToGroup.id === r.id)) {
                otherTeamRuns.push(r)
            }
        })

        const allRuns = [...otherTeamRuns, ...allGroupedRuns]

        let unassignedRunToAdd = []
        dashboard.unassignedRuns.forEach((groupRuns) => {
            unassignedRunToAdd = [
                ...unassignedRunToAdd,
                ...groupRuns.filter((r) => r.selected),
            ]
        })
        unassignedRunToAdd.forEach((r) => {
            if (!allRuns.some((run) => run.id === r.id)) {
                allRuns.push(r)
            }
        })

        const data = {
            runs: allRuns.map((r) => {
                const d = {
                    id: r['@id'],
                }
                if (r.status)
                    d.status = r.status['@id'] ? r.status['@id'] : r.status
                if (r.departureOrder) d.departureOrder = r.departureOrder
                if (r.parent) d.parent = r.parent['@id']

                return d
            }),
        }

        //save
        API.MasterRuns.put(team.id, data).then((data) => {
            reRender()
            toast.success('Les transports ont été groupés.')
        })
    }

    const onTeamCardClick = (run) => {
        if (run.partner && run.isGiven) {
            //partner run
            toast.error('Impossible de sélectionner un transport partenaire.')
        } else {
            //team run
            dispatch({
                type: ACTIONS.SET_TEAM_RUN_SELECTED,
                payload: {
                    id: run.id,
                    selected: !run.selected,
                    teamId: run.masterRun.id,
                },
            })
        }
    }

    const onUnassignedCardClick = (run) => {
        dispatch({
            type: ACTIONS.SET_UNASSIGNED_RUN_SELECTED,
            payload: { id: run.id, selected: !run.selected },
        })
    }

    const atLeastTwoRunsAreSelected = () => {
        let nbSelectedRuns = 0
        dashboard.teams.forEach((t) => {
            nbSelectedRuns =
                nbSelectedRuns + t.runs.filter((r) => r.selected).length
        })

        dashboard.unassignedRuns.forEach((groupRuns) => {
            nbSelectedRuns =
                nbSelectedRuns + groupRuns.filter((r) => r.selected).length
        })

        return nbSelectedRuns >= 2
    }

    const groupRunsWithSameHours = () => {
        handleActionModalOpen()
        handleGroupRunsWithSameHours(
            dashboard.teamAndRunsToGroup.groupsOfRunsToGroup,
            dashboard.teamAndRunsToGroup.team
        )
    }

    const handleActionModalOpen = () => {
        dispatch({
            type: ACTIONS.SET_ACTION_MODAL_OPEN,
            payload: !dashboard.actionModalOpen,
        })
    }

    const handleHideTeam = (team) => {
        if (!team.hide) {
            const teamIds = [...dashboardHidedTeams]
            teamIds.push(team.id)
            setDashboardHidedTeams(teamIds)
        } else {
            const teamIds = [...dashboardHidedTeams]
            const indexToRemove = teamIds.indexOf(team.id)
            if (indexToRemove >= 0) {
                teamIds.splice(indexToRemove, 1)
                setDashboardHidedTeams(teamIds)
            }
        }

        dispatch({
            type: ACTIONS.SET_HIDE_TEAM,
            payload: {
                hide: !team.hide,
                teamId: team.id,
            },
        })
    }

    const areSelectedRuns = () => {
        let areSelectedTeamRuns = dashboard.unassignedRuns.some(
            (r) => r.selected
        )
        if (!areSelectedTeamRuns) {
            for (let i = 0; i < dashboard.teams.length; i++) {
                for (let j = 0; j < dashboard.teams[i].runs.length; j++) {
                    if (dashboard.teams[i].runs[j].selected) {
                        areSelectedTeamRuns = true
                        break
                    }
                }
                if (areSelectedTeamRuns) {
                    break
                }
            }
        }

        return areSelectedTeamRuns
    }

    const calculateVerticalTimeLinePosition = (firstSplash) => {
        const hours = dayjs().hour()
        const minutes = dayjs().minute()

        const position = calculatePosition(hours, minutes, false)
        dispatch({
            type: ACTIONS.SET_VERTICAL_TIMELINE_POSITION,
            payload: position,
        })
        if (firstSplash) {
            verticalTimeLineRef.current.scrollTo(
                position - verticalTimeLineRef.current.clientWidth / 2,
                0
            )
        }
    }

    const calculatePosition = (hours, minutes, unassignedRun) => {
        const sizePerRange =
            planning.intervalSize.size * planning.intervalSize.range

        const position =
            hours * sizePerRange +
            (sizePerRange / 60) * minutes +
            planning.padding
        return position
    }

    const horizontalScroll = () => {
        const slider = document.querySelector('#container')
        let isDown = false
        let startX
        let scrollLeft

        slider.addEventListener('mousedown', (e) => {
            isDown = true
            startX = e.pageX - slider.offsetLeft
            scrollLeft = slider.scrollLeft
        })
        slider.addEventListener('mouseleave', () => {
            isDown = false
        })
        slider.addEventListener('mouseup', () => {
            isDown = false
        })
        slider.addEventListener('mousemove', (e) => {
            if (!isDown) return
            e.preventDefault()
            const x = e.pageX - slider.offsetLeft
            const walk = (x - startX) * 3 //scroll-fast
            slider.scrollLeft = scrollLeft - walk
        })
    }

    useEffect(() => {
        calculateVerticalTimeLinePosition(true)
    }, [planning.intervalSize.range, planning.isPatientFilter])

    useEffect(() => {
        const interval = setInterval(() => {
            calculateVerticalTimeLinePosition(false)
        }, 36000)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        horizontalScroll()
    }, [])

    const countUnassignedRunsSelected = () => {
        return dashboard.unassignedRuns.reduce((accumulator, runs) => {
            return (
                accumulator +
                runs.reduce(
                    (innerAccumulator, run) =>
                        run.selected ? innerAccumulator + 1 : innerAccumulator,
                    0
                )
            )
        }, 0)
    }

    const otherRunsThanTeamRunsSelected = (team) => {
        const countOtherTeamsRunSelecteddashboard = dashboard.teams.reduce(
            (acc, t) => {
                return t.id !== team.id
                    ? acc + t.runs.filter((run) => run.selected).length
                    : acc
            },
            0
        )
        return (
            countOtherTeamsRunSelecteddashboard +
                countUnassignedRunsSelected() >
            0
        )
    }

    const main = document.querySelector('#main')

    const [timesFromTop, setTimesFromTop] = useState(100)
    const [fixedTimes, setFixedTimes] = useState(false)

    const planningId = document.querySelector('#planning')

    useEffect(() => {
        const handleLoad = () => {
            setTimesFromTop(
                document.querySelector('#times').getBoundingClientRect().y
            )
        }
        window.addEventListener('load', handleLoad)
        return () => window.removeEventListener('load', handleLoad)
    }, [])

    if (main) {
        //for not in focus mode
        main.addEventListener('scroll', (event) => {
            if (main.scrollTop > timesFromTop) {
                setFixedTimes(true)
            } else {
                setFixedTimes(false)
            }
        })
    }

    if (planningId && planning.focusMode) {
        planningId.addEventListener('scroll', (event) => {
            if (planningId.scrollTop > timesFromTop) {
                setFixedTimes(true)
            } else {
                setFixedTimes(false)
            }
        })
    }

    const [scrollX, setScrollX] = useState(0)
    const [isPending, startTransition] = useTransition()

    useEffect(() => {
        const verticalContainer = verticalTimeLineRef.current

        if (verticalContainer) {
            const handleScroll = () => {
                startTransition(() => {
                    setScrollX(verticalContainer.scrollLeft)
                })
            }

            verticalContainer.addEventListener('scroll', handleScroll)

            return () => {
                verticalContainer.removeEventListener('scroll', handleScroll)
            }
        }
    }, [])

    const getSameHoursRunsForEachTeam = (runs) => {}

    return (
        <div className="pt-4">
            <div className="">
                <div className="">
                    <div className="">
                        <div className="flex flex-col">
                            <div
                                className="invisible-scroll relative cursor-move overflow-auto overflow-y-hidden"
                                id="container"
                                ref={verticalTimeLineRef}
                            >
                                <div
                                    className="relative"
                                    style={{
                                        left: dashboard.verticalTimeLinePosition,
                                    }}
                                >
                                    <div className="vertical-time-line absolute z-10 w-1" />
                                </div>
                                <div
                                    className={classNames('flex w-full pb-2', {
                                        'fixed top-0 z-10': fixedTimes,
                                    })}
                                    style={{
                                        transform: `translateX(-${
                                            fixedTimes ? scrollX : 0
                                        }px)`,
                                    }}
                                    id="times"
                                >
                                    <PlanningDashboardTimeLine
                                        dashboard={dashboard}
                                        timesIntervalSizeStyle={
                                            timesIntervalSizeStyle
                                        }
                                        fixedTimes={fixedTimes}
                                    />
                                </div>
                                <div className={classNames('flex w-full pb-2')}>
                                    <VerticalDashedLine
                                        dashboard={dashboard}
                                        timesIntervalSizeStyle={
                                            timesIntervalSizeStyle
                                        }
                                    />
                                </div>
                                <div className={`${fixedTimes && 'pt-10'}`}>
                                    {dashboard.teams
                                        .sort((a, b) => a.position - b.position)
                                        .filter((team) => !team.completelyHided)
                                        .map((team) => (
                                            <div
                                                key={team.id}
                                                className="relative"
                                                style={{
                                                    width:
                                                        dashboard.intervalSize
                                                            .size *
                                                            dashboard
                                                                .intervalSize
                                                                .range *
                                                            (dashboard.times
                                                                .length -
                                                                1) +
                                                        dashboard.padding +
                                                        dashboard.intervalSize
                                                            .size,
                                                }}
                                            >
                                                <PlanningDashboardTeamInfo
                                                    team={team}
                                                    atLeastTwoRunsAreSelected={
                                                        atLeastTwoRunsAreSelected
                                                    }
                                                    unassignSelectedTeamsRuns={
                                                        unassignSelectedTeamsRuns
                                                    }
                                                    handleGroupIconClicked={
                                                        handleGroupIconClicked
                                                    }
                                                    totalRunsTeam={
                                                        totalRunsTeam
                                                    }
                                                    dashboard={dashboard}
                                                    handleTeamClicked={
                                                        handleTeamClicked
                                                    }
                                                    otherRunsThanTeamRunsSelected={
                                                        otherRunsThanTeamRunsSelected
                                                    }
                                                    handleHideTeam={
                                                        handleHideTeam
                                                    }
                                                    disabledButtons={
                                                        dashboard.disabledButtons
                                                    }
                                                />
                                                <div>
                                                    {!team.hide && (
                                                        <div
                                                            className={`flex  `}
                                                            style={{
                                                                width:
                                                                    dashboard
                                                                        .intervalSize
                                                                        .size *
                                                                        dashboard
                                                                            .intervalSize
                                                                            .range *
                                                                        (dashboard
                                                                            .times
                                                                            .length -
                                                                            1) +
                                                                    dashboard.padding,
                                                                backgroundColor:
                                                                    planning.displayVehicleColor &&
                                                                    team.color,
                                                                height: getHeightForTeam(
                                                                    team.runs
                                                                ),
                                                            }}
                                                        >
                                                            {team.runs.map(
                                                                (run) => {
                                                                    if (
                                                                        run.groupedRun
                                                                    ) {
                                                                        return (
                                                                            <Fragment
                                                                                key={
                                                                                    run.id
                                                                                }
                                                                            >
                                                                                <DashboardGroupedRunCard
                                                                                    onInfoClick={
                                                                                        onInfoClick
                                                                                    }
                                                                                    onTeamCardClick={
                                                                                        onTeamCardClick
                                                                                    }
                                                                                    style={() =>
                                                                                        handleStyle(
                                                                                            run
                                                                                        )
                                                                                    }
                                                                                    run={
                                                                                        run
                                                                                    }
                                                                                    unassignedRun={
                                                                                        false
                                                                                    }
                                                                                    dashboard={
                                                                                        dashboard
                                                                                    }
                                                                                />
                                                                            </Fragment>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Fragment
                                                                                key={
                                                                                    run.id
                                                                                }
                                                                            >
                                                                                <DashboardRunCard
                                                                                    onInfoClick={
                                                                                        onInfoClick
                                                                                    }
                                                                                    onCardClick={
                                                                                        onTeamCardClick
                                                                                    }
                                                                                    style={() =>
                                                                                        handleStyle(
                                                                                            run
                                                                                        )
                                                                                    }
                                                                                    run={
                                                                                        run
                                                                                    }
                                                                                    unassignedRun={
                                                                                        false
                                                                                    }
                                                                                    dashboard={
                                                                                        dashboard
                                                                                    }
                                                                                    showRunObjectColors={
                                                                                        showRunObjectColors
                                                                                    }
                                                                                />
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                }
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                                <div
                                    style={{
                                        width:
                                            dashboard.intervalSize.size *
                                                dashboard.intervalSize.range *
                                                (dashboard.times.length - 1) +
                                            dashboard.padding,
                                    }}
                                    className=""
                                >
                                    <UnassignedRunsTitle
                                        dashboard={dashboard}
                                    />
                                    {dashboard.unassignedRuns.map(
                                        (runs, index) => (
                                            <div
                                                key={index}
                                                className="flex h-32"
                                            >
                                                {runs.map((run) => (
                                                    <Fragment key={run.id}>
                                                        <DashboardRunCard
                                                            onInfoClick={
                                                                onInfoClick
                                                            }
                                                            onCardClick={
                                                                onUnassignedCardClick
                                                            }
                                                            run={run}
                                                            style={() =>
                                                                handleStyle(run)
                                                            }
                                                            dashboard={
                                                                dashboard
                                                            }
                                                            showRunObjectColors={
                                                                showRunObjectColors
                                                            }
                                                        />
                                                    </Fragment>
                                                ))}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {dashboard.informationRunModalOpen && (
                    <RunModalContainer
                        setModalOpen={handleInformationRunModalOpen}
                        run={dashboard.clickedRun}
                        masterRunsList={dashboard.teams.filter(
                            (team) => !team.isPartner
                        )}
                        statusList={dashboard.statusList}
                        reRender={reRender}
                        setOpenRunManageModal={handleOpenRunManageModal}
                        planning={planning}
                        setOpenCompetitionModal={handleOpenCompetitionModal}
                        disabledButtons={planning.disabledButtons}
                        setDisabledButtons={handleDisabledButtons}
                    />
                )}
            </div>
            {dashboard.loading && <LoaderAbsolute />}
            {dashboard.actionModalOpen && (
                <ActionModal
                    setModalOpen={handleActionModalOpen}
                    warningLabel="Êtes-vous sûr de vouloir grouper les transports ?"
                    action={groupRunsWithSameHours}
                    labelAction="Transports sur le mêmes horaires"
                    actionButton="Grouper"
                />
            )}
            {/*{help && <DocumentationContainer setHelp={setHelp} />}*/}
        </div>
    )
}

export default PlanningDashboard
