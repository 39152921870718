import React, { useState } from 'react'
import Loader from '../../../../Commons/Loaders/Loader'
import SignatureViewer from '../../../../Commons/SignatureViewer'
import { RUNINFO_ACTIONS } from '../../../../../services/Reducers/RunInformationReducer'
import { TRANSPORT_ACTIONS } from '../../../Regulation/Reducers/TransportFormReducer'
import UploadDocument from '../../../../DriverAccess/Files/UploadDocument'
import { Driver } from '../../../../../services/API/Entities/driver'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { SERIES_ACTIONS } from '../../../../../services/Reducers/SeriesReducer'
import ActionModal from '../../../../Modal/ActionModal'

const SettingsSeriesForm = ({ series, dispatch }) => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [docType, setDoctype] = useState(null)
    const [fullscreenImage, setFullscreenImage] = useState(null)
    const [deletingDocument, setDeletingDocument] = useState(false)
    const [showValidationModal, setShowValidationModal] = useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const handleDeleteDocument = async () => {
        closeValidationModal()
        setDeletingDocument(true)
        const data = {
            series: series.id,
            docType: docType,
        }
        try {
            const response = await Driver.deleteFromS3(data)

            dispatch({
                type: SERIES_ACTIONS.SET_PRESCRIPTION_URL,
                payload: null,
            })
        } catch (error) {
            console.error('Erreur lors de la suppression du document :', error)
        }
    }

    const openValidationModal = (doctype) => {
        setDoctype(doctype)
        setShowValidationModal(true)
    }
    const closeValidationModal = () => {
        setDoctype(null)
        setShowValidationModal(false)
    }

    // const handleDownloadSignature = async () => {
    //     const pdfSignature = await fetchDownloadSignaturePdf()
    //     if (pdfSignature.data instanceof Blob) {
    //         toast.success('Succès.')
    //         saveAs(
    //             pdfSignature.data,
    //             `annexe_series_${
    //                 series.patient.lastname + '_' + series.patient.firstname
    //             }.pdf`
    //         )
    //     } else {
    //         toast.error('Erreur lors de la génération du PDF')
    //     }
    // }

    // const {
    //     refetch: fetchDownloadSignaturePdf,
    //     isLoading: isLoadingDownloadSignaturePdf,
    // } = useQuery({
    //     queryKey: ['pdf_signature', series.id],
    //     queryFn: async () => {
    //         return await Run.downloadSignaturePdf({
    //             id: series.id,
    //             signatureId: series.signatureId,
    //             location: series.signatureLocation,
    //             date: dayjs(series.signatureDate, 'DD/MM/YYYY').format(
    //                 'YYYY-MM-DD'
    //             ),
    //         }) // Assurez-vous que c'est bien un blob
    //     },
    //     enabled: false,
    // })

    return (
        <div className="flex w-full flex-col space-y-4 pb-10">
            <div className="flex items-center justify-between pb-10 pt-6">
                <h1 className="text-2xl font-semibold">
                    Paramètres de la série
                </h1>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <div className=" rounded-lg bg-white p-6 shadow-md">
                    <div className="space-y-6">
                        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                            {series.signature ? (
                                <div className={'flex flex-col'}>
                                    <div className="mb-2 flex items-center justify-between">
                                        <p className="mb-2 font-bold text-gray-600">
                                            Signature du patient
                                        </p>
                                    </div>
                                    <div className="flex flex-col items-center rounded border border-gray-300">
                                        {series.signature.startsWith('[') ? (
                                            <SignatureViewer
                                                strokes={JSON.parse(
                                                    series.signature
                                                )}
                                            />
                                        ) : (
                                            <img
                                                src={series.signature}
                                                alt="Signature du patient"
                                                className="h-40 max-w-full "
                                            />
                                        )}
                                    </div>
                                    <div className="mb-4 text-lg text-gray-600">
                                        <div className="flex flex-col space-y-4">
                                            <div className="flex items-baseline pt-4">
                                                <span className="mr-2">
                                                    Fait le
                                                </span>
                                                <input
                                                    type="text"
                                                    value={series.signatureDate}
                                                    placeholder={'jj/mm/aaaa'}
                                                    onChange={(e) =>
                                                        dispatch({
                                                            type: SERIES_ACTIONS.SET_SIGNATURE_DATE,
                                                            payload:
                                                                e.target.value,
                                                        })
                                                    }
                                                    className="flex-grow border-b border-secondary-color text-center font-bold focus:border-primary-color focus:outline-none"
                                                />
                                            </div>
                                            <div className="mt-2 flex items-baseline">
                                                <span className="mr-11">à</span>
                                                <input
                                                    type="text"
                                                    placeholder={'lieu'}
                                                    value={
                                                        series.signatureLocation
                                                    }
                                                    onChange={(e) =>
                                                        dispatch({
                                                            type: SERIES_ACTIONS.SET_SIGNATURE_LOCATION,
                                                            payload:
                                                                e.target.value,
                                                        })
                                                    }
                                                    className="flex-grow border-b border-secondary-color text-center font-bold focus:border-primary-color focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div className={'col-span-1'}>
                                <h3 className="mb-2 font-bold text-gray-600">
                                    Bon de transport
                                </h3>
                                {series.prescriptionUrl ? (
                                    <div className="flex items-center justify-between lg:justify-normal lg:space-x-10">
                                        {/* Affiche le document + bouton poubelle */}
                                        <img
                                            src={series.prescriptionUrl}
                                            alt="Bon de transport"
                                            className="h-48 w-48 cursor-pointer rounded-lg border object-contain"
                                            onClick={() =>
                                                setFullscreenImage(
                                                    series.prescriptionUrl
                                                )
                                            }
                                        />
                                        <button
                                            onClick={() =>
                                                openValidationModal(
                                                    'prescription'
                                                )
                                            }
                                            className="text-red-500"
                                        >
                                            <i className="fas fa-trash fa-lg" />
                                        </button>
                                    </div>
                                ) : (
                                    <div className="grid w-full grid-cols-2 gap-2">
                                        {/* Bouton + (upload via DocumentCameraScanner) */}
                                        <UploadDocument
                                            docType="prescription"
                                            dispatch={dispatch}
                                            runId={series.id}
                                        />
                                    </div>
                                )}
                            </div>
                            {/*<div className={'col-span-1'}>*/}
                            {/*    <h3 className="mb-2 font-bold text-gray-600">*/}
                            {/*        Ticket*/}
                            {/*    </h3>*/}
                            {/*    {series.invoiceUrl ? (*/}
                            {/*        <div className="flex items-center justify-between lg:justify-normal lg:space-x-10">*/}
                            {/*            /!* Affiche le document + bouton poubelle *!/*/}
                            {/*            <img*/}
                            {/*                src={series.invoiceUrl}*/}
                            {/*                alt="Facturette"*/}
                            {/*                className="h-48 w-48 cursor-pointer rounded-lg border"*/}
                            {/*                onClick={() =>*/}
                            {/*                    setFullscreenImage(*/}
                            {/*                        series.invoiceUrl*/}
                            {/*                    )*/}
                            {/*                }*/}
                            {/*            />*/}
                            {/*            <button*/}
                            {/*                onClick={() =>*/}
                            {/*                    openValidationModal('invoice')*/}
                            {/*                }*/}
                            {/*                className="text-red-500"*/}
                            {/*            >*/}
                            {/*                <i className="fas fa-trash fa-lg" />*/}
                            {/*            </button>*/}
                            {/*        </div>*/}
                            {/*    ) : (*/}
                            {/*        <div className="grid w-full grid-cols-2 gap-2">*/}
                            {/*            <UploadDocument*/}
                            {/*                docType="invoice"*/}
                            {/*                dispatch={dispatch}*/}
                            {/*                runId={series.id}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    )}*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            )}
            {showValidationModal && (
                <ActionModal
                    action={() => handleDeleteDocument()}
                    labelAction="Confirmation"
                    label="Êtes-vous sûr de vouloir valider cette action ?"
                    actionButton="Supprimer"
                    secondaryAction={closeValidationModal}
                />
            )}
            {fullscreenImage && (
                <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80"
                    onClick={() => setFullscreenImage(null)}
                >
                    <img
                        src={fullscreenImage}
                        alt="Agrandissement"
                        className="max-h-full max-w-full"
                    />
                </div>
            )}
        </div>
    )
}

export default SettingsSeriesForm
