import React from 'react'
import { Camera } from 'iconoir-react'

const ScanDocument = ({ docType, openScanner }) => {
    return (
        <button
            onClick={() => openScanner(docType)}
            className="flex h-24 w-full flex-col items-center justify-center gap-2 rounded-lg border-2 border-dashed border-indigo-500 bg-indigo-50/50 transition-all hover:bg-indigo-50 active:bg-indigo-100"
        >
            <Camera className="h-6 w-6 text-indigo-600" />
            <span className="text-sm font-medium text-indigo-700">Scanner</span>
        </button>
    )
}

export default ScanDocument
