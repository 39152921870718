import React from 'react'

const Patient = ({ row, isComputer }) => {
    return (
        <div className={`${isComputer ? 'w-1/4' : 'w-1/2 pt-2'} truncate`}>
            <div className="truncate text-sm font-semibold ">
                {row.partner && !row.isGiven ? (
                    <i className="fas fa-hands-helping pr-2" />
                ) : null}
                {row.fullname}
            </div>
            <div className="truncate text-sm font-light">{row.address}</div>
        </div>
    )
}

export default Patient
