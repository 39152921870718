import { apiClient } from '../apiClient'
import { formatHydraData } from '../../api'
import { concat } from '../pathsAndFilters'

const PATH_KEY = '/api/establishment_lists'

const formatEstablishmentForSelect = (establishment, isEstablishment) => {
    let label = ''

    if (isEstablishment) {
        // Pour un établissement
        label = establishment.companyName || ''
    } else {
        // Pour un praticien
        label = `${establishment.exerciseName || ''} ${
            establishment.exerciseFirstname || ''
        }`.trim()
    }

    console.log('label', label, isEstablishment)

    // Ajouter le code postal et la commune si présents
    const location = []
    if (establishment.postalCode) location.push(establishment.postalCode)
    if (establishment.communeLabel) location.push(establishment.communeLabel)

    if (location.length > 0) {
        label += ` - ${location.join(' ')}`
    }

    return {
        value: establishment.id,
        label: label.trim(),
        object: establishment,
    }
}
export const EstablishmentList = {
    fetchAll: async (isEstablishment, search) => {
        let path = PATH_KEY
        if (typeof isEstablishment === 'boolean')
            path = concat(path, 'isEstablishment=' + isEstablishment)
        if (search) path = concat(path, 'search=' + search)
        return formatHydraData(await apiClient.get(path))
    },
    fetchAllForDebounceSearch: async (search, isEstablishment) => {
        const establishments = await EstablishmentList.fetchAll(
            isEstablishment,
            search
        )
        return establishments?.map((establishment) =>
            formatEstablishmentForSelect(establishment, isEstablishment)
        )
    },
}
