import { apiClient } from '../apiClient'

const PATH_KEY = '/prescriptions'
export const Prescriptions = {
    post: async (prescription) => {
        return await apiClient.post(PATH_KEY, prescription)
    },
}

export const InsertPrescription = async (prescription) => {
    if (prescription.id) {
        console.log(prescription)
        return await apiClient.put(prescription.id, prescription)
    } else return await apiClient.post(PATH_KEY, prescription)
}
